import { Button, InputNumber, message } from 'antd'
import { api } from 'api'
import { arrayMoveImmutable } from 'array-move'
import UITexts from 'export/UITexts'
import React, { useEffect, useState } from 'react'
import { Product } from 'typings/entities'
import { filterReorderProductsData } from './Products'

type PropsType = {
  value: number
  data?: { dataSource: Product[]; setDataSource: (arg: Product[]) => void }
}
type PostType = { [key: string]: number }

const Order: React.FC<PropsType> = ({ value, data }) => {
  const [order, setOrder] = useState(value)
  const [mutate, resp] = api.useReorderIssuerProductMutation()
  const reorderProducts = async (body: PostType, newData: Product[]) => {
    const resp = await mutate(body)
    if ('data' in resp) {
      message.success(UITexts.IssuerProduct.UPDATE_ORDER_SUCCESS)
      onOk(newData)
    } else {
      message.error(UITexts.IssuerProduct.UPDATE_ORDER_SUCCESS)
    }
  }
  const onOk = (newData: Product[]) => {
    if (!data) return
    const { setDataSource } = data
    setDataSource(newData)
  }
  const handleClick = () => {
    if (!data) return
    const { dataSource } = data
    const { newData, postData } = filterReorderProductsData(dataSource, value, order)
    reorderProducts(postData, newData)
  }
  return (
    <div style={{ width: 70 + 50 + 4, display: 'flex' }}>
      <InputNumber value={order} onChange={setOrder} size={'small'} style={{ width: 70 }} min={0} />
      {order !== value && (
        <Button size={'small'} onClick={handleClick} style={{ marginLeft: 4 }}>
          OK
        </Button>
      )}
    </div>
  )
}
export default Order
