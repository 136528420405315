import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Form, Input, InputNumber, message, Result, Row, Spin } from 'antd'
import useMySellerFirm from 'hooks/useMySellerFirm'
import { api } from 'api/index'
import UITexts from 'export/UITexts'
import FormValueString from 'ui/customFormInputs/FormValueString'
import { SubscribeStatus } from 'typings/entities'
const SubscribeStatusPage = () => {
  const p = useParams()
  const id = p.id === undefined ? undefined : parseInt(p.id)

  const [form] = Form.useForm<SubscribeStatus>()
  const sellerFirm = useMySellerFirm()
  const subscribeStatus = api.useGetSubscribeStatusQuery(id!, { skip: !id, refetchOnMountOrArgChange: true })
  const [mutate, resp] = api.useUpdateSubscribeStatusMutation()
  const navigate = useNavigate()
  const onOk = async () => {
    if (!id) return
    const values = await form.validateFields()
    console.log(values)

    if (!values) return
    const resp = await mutate({ id: id, data: values })
    console.log(resp)
    // return
    if (resp) {
      message.success(UITexts.SubscribeStatus.CREATE_SUCCESS)

      navigate('/subscribe_status/all')
    } else {
      message.error(UITexts.SubscribeStatus.CREATE_ERROR)
    }
  }
  if (subscribeStatus.isError) {
    return <Result title={<p>Subscribe status with id {id} not found</p>} status={'404'} />
  }
  if (!subscribeStatus.data) {
    return <Spin />
  }
  return (
    <div>
      <h1>SubscribeStatus</h1>
      <Form form={form} initialValues={{ ...subscribeStatus.data }}>
        <Form.Item name={'name'} label={'Name'}>
          <Input />
        </Form.Item>
        <Form.Item name={'name_for_user'} label={'Name for client'}>
          <Input />
        </Form.Item>
        <Form.Item name={'order'} label={'Order'}>
          <InputNumber />
        </Form.Item>
        <Form.Item name={'seller_firm'} label={'Seller firm'} style={{ display: 'none' }}>
          <FormValueString />
        </Form.Item>
        <Row justify={'end'}>
          <Button type={'primary'} htmlType={'submit'} onClick={onOk} loading={resp.isLoading}>
            Ok
          </Button>
        </Row>
      </Form>
    </div>
  )
}

export default SubscribeStatusPage
