import React from 'react'
import { Image, Row } from 'antd'
import { CHART_AXIS, CHART_TYPE, Product } from 'typings/entities'
import { imageFallback, isImageUrl } from 'utils'
import { ParamSchemaPreview } from 'pages/Campaigns/components/ParamPreview'
import BarChart from 'components/charts/BarChart'
import PieChart from 'components/charts/PieChart'
import ScatterChart from 'components/charts/ScatterChart'
import styles from './IssuerProductCard.module.css'

type Props = { product: Product }
const sortOrder = (a: { order: number }, b: { order: number }) => a.order - b.order
const IssuerProductCard: React.FC<Props> = ({ product }) => {
  console.log('product', product)
  return (
    <div>
      <p>
        Name: <b>{product.name}</b>
      </p>
      <p>
        Category: <b>{product.categories?.[0]?.name ?? '-'}</b>
      </p>
      <p>
        Creator: <b>{product.creator?.username ?? '-'}</b>
      </p>
      <p>
        Issuer firm: <b>{product.issuer_firm?.name}</b>
      </p>
      <p>
        Short description: <b>{product.short_description}</b>
      </p>
      <p>
        Description: <b>{product.description}</b>
      </p>
      <p>
        Price: <b>{product.price}</b>
      </p>
      <p>Params:</p>
      <ParamSchemaPreview params={product.params} />

      {Boolean(product.images.length) && (
        <>
          <h3 className={styles.sectionTitle}>Images:</h3>
          <Row style={{ marginTop: 20, gap: 10 }} wrap={true}>
            {product.images
              .map((i) => ({ ...i }))
              .sort(sortOrder)
              .map((img, i) => {
                if (isImageUrl(img.url)) {
                  return (
                    <Image
                      key={img.id}
                      src={img.url}
                      fallback={imageFallback}
                      height={(200 / 4) * 3}
                      width={200}
                      title={img.order.toString()}
                    />
                  )
                }
                return (
                  <video
                    key={img.id}
                    controls
                    src={img.url}
                    style={{ width: 200, height: (200 / 4) * 3, background: 'black' }}
                  />
                )
              })}
          </Row>
        </>
      )}

      {Boolean(product.videos.length) && (
        <>
          <h3 className={styles.sectionTitle}>Videos:</h3>
          <Row style={{ marginTop: 20, gap: 10 }} wrap={true}>
            {product.videos
              .map((v) => ({ ...v }))
              .sort(sortOrder)
              .map((v, i) => (
                <video
                  key={v.id}
                  poster={v.url_preview}
                  controls
                  src={v.url}
                  style={{ width: 200, height: (200 / 4) * 3, background: 'black' }}
                />
              ))}
          </Row>
        </>
      )}

      {Boolean(product.attachments.length) && (
        <>
          <h3 className={styles.sectionTitle}>Attachments:</h3>
          <div>
            {product.attachments
              .map((a) => ({ ...a }))
              .sort(sortOrder)
              .map((a) => (
                <a key={a.id} href={a.url} target={'_blank'} rel="noreferrer">
                  {a.name}
                </a>
              ))}
          </div>
        </>
      )}

      {Boolean(product.charts.length) && (
        <>
          <h3 className={styles.sectionTitle}>Charts:</h3>
          <div className={styles.charts}>
            {product.charts.map((c) => {
              const ChartOfType = getChartByChartType(c.chart_type)
              return (
                <div key={c.id}>
                  <p key={c.id}>
                    [{c.chart_type}] <b>{c.title}</b>
                  </p>
                  <div>
                    <ChartOfType
                      title={c.title}
                      y_label={c.y_label}
                      x_label={c.x_label}
                      y_type={c.y_type}
                      x_type={c.x_type}
                      data={c.chart.x.map((x, i) => [x, c.chart.y[i]])}
                    />
                  </div>
                </div>
              )
            })}
          </div>
        </>
      )}
    </div>
  )
}

function throwBadPet(p: never): never
// Implementation signature
function throwBadPet(p: any) {
  throw new Error('Unknown pet kind: ')
}

const getChartByChartType = (
  t: CHART_TYPE,
): React.FC<{
  title: string
  data: [string | number, number | null][]
  x_label: string
  y_label: string
  y_type: CHART_AXIS
  x_type: CHART_AXIS
}> => {
  switch (t) {
    case CHART_TYPE.bar:
      return BarChart
    case CHART_TYPE.pie:
      return PieChart
    case CHART_TYPE.scatter:
      return ScatterChart
  }
  // throwBadPet(1)
  // const txz: never = 0

  // eslint-disable-next-line react/display-name
  return (props: any) => <p>Chart preview not implemented</p>
}

export default IssuerProductCard
