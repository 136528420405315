import { ProductPicture } from 'typings/entities'
import { api, BuilderType } from 'api/index'

export const createIssuerProductPicture = (builder: BuilderType) =>
  builder.mutation<ProductPicture, { productId: number; order: number; background_color: string; body: FormData }>({
    query: ({ productId, order, background_color, body }) => ({
      url: 'issuer_product_image',
      method: 'POST',
      body,
      params: { product: productId, order, background_color },
    }),
    onQueryStarted(args, { dispatch, queryFulfilled }) {
      queryFulfilled.then((resp) => {
        const picture = resp.data
        dispatch(
          api.util.updateQueryData('getIssuerProduct', args.productId, (product) => {
            product.images.push(picture)
          }),
        )
      })
    },
  })
export const deleteIssuerProductPicture = (builder: BuilderType) =>
  builder.mutation<void, { productId: number; id: number }>({
    query: (args) => ({ url: '/issuer_product_image/' + args.id, method: 'DELETE' }),
    onQueryStarted(args, { dispatch, queryFulfilled }) {
      queryFulfilled.then(() => {
        dispatch(
          api.util.updateQueryData('getIssuerProduct', args.productId, (product) => {
            product.images = product.images.filter((a) => a?.id !== args.id)
          }),
        )
      })
    },
  })
export const patchIssuerProductPictureOrder = (builder: BuilderType) =>
  builder.mutation<void, { id: number; productId: number; order: number; background_color: string }>({
    query: ({ id, order, background_color }) => ({
      url: '/issuer_product_image/' + id,
      method: 'PATCH',
      body: { order, background_color },
    }),
    onQueryStarted(args, { dispatch, queryFulfilled }) {
      queryFulfilled.then((resp) => {
        dispatch(
          api.util.updateQueryData('getIssuerProduct', args.productId, (product) => {
            const oldPicture = product.images.find((c) => c.id === args.id)
            if (oldPicture !== undefined) Object.assign(oldPicture, resp.data)
          }),
        )
      })
    },
  })
