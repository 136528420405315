import React from 'react'
import { useForm } from 'antd/es/form/Form'
import { Button, Form, Input, message, Row, Switch } from 'antd'
import IssuerFirmSelect from 'ui/customFormInputs/IssuerFirmSelect'
import md5 from 'md5'
import { PASSWORD_SALT } from 'api/endpoints/profileEndpoints'
import { api } from 'api/index'
import { useNavigate } from 'react-router-dom'
import SellerFirmSelect from 'ui/customFormInputs/SellerFirmSelect'
import RoleSelect from '../../ui/customFormInputs/RoleSelect'

const CreateStaffUser = () => {
  const [form] = Form.useForm()
  const [mutate, resp] = api.useCreateUserMutation()
  const [createRoles, resp2] = api.useCreateAdvisorRoleMutation()
  const navigate = useNavigate()
  const onOk = async () => {
    const values = await form.validateFields()
    if (!values) return
    const { role, ...userValues } = values
    userValues.password = md5(values.password + PASSWORD_SALT)
    console.log('userValues', userValues)
    const resp = await mutate(userValues)
    console.log('resp', resp)
    //@ts-ignore
    if ('error' in resp.data) {
      message.error('Error occurred while creating user')
    } else {
      if (role !== undefined) {
        //@ts-ignore
        const resp2 = await createRoles({ role, advisor: resp.data.id })
        console.log(resp2)
        if ('error' in resp2) {
          message.error('Error occurred while assigning role to user')
        } else {
          message.success('User created')
          navigate('/')
        }
      }
    }
  }
  return (
    <div>
      <Form form={form} labelCol={{ flex: '120px' }}>
        {/*"username": "test_create",*/}
        {/*"first_name": "test_create",*/}
        {/*"last_name": "test_create",*/}
        {/*"is_staff": true,*/}
        {/*"email": "test_create@test.com",*/}
        {/*"issuer_firm": 36,*/}
        {/*"password": "test"*/}
        <Form.Item name={'username'} label={'Username'}>
          <Input />
        </Form.Item>
        <Form.Item name={'first_name'} label={'First name'}>
          <Input />
        </Form.Item>
        <Form.Item name={'last_name'} label={'Last name'}>
          <Input />
        </Form.Item>
        <Form.Item name={'is_staff'} label={'Is staff'}>
          <Switch />
        </Form.Item>
        <Form.Item name={'is_superuser'} label={'Superuser'}>
          <Switch />
        </Form.Item>
        <Form.Item name={'email'} label={'Email'}>
          <Input />
        </Form.Item>
        <Form.Item name={'issuer_firm'} label={'Issuer firm'}>
          <IssuerFirmSelect />
        </Form.Item>
        <Form.Item name={'seller_firm'} label={'Seller firm'}>
          <SellerFirmSelect />
        </Form.Item>
        <Form.Item name={'password'} label={'Password'}>
          <Input.Password />
        </Form.Item>
        <Form.Item name={'role'} label={'Role'}>
          <RoleSelect />
        </Form.Item>
        <Row justify={'end'}>
          <Button type={'primary'} onClick={onOk} loading={resp.isLoading}>
            Ok
          </Button>
        </Row>
        {resp.isError && (
          <p style={{ color: 'red', marginTop: 10, marginBottom: 10 }}>Error occurred while creating user</p>
        )}
      </Form>
    </div>
  )
}

export default CreateStaffUser
