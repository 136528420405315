import React, { useEffect, useState } from 'react'
import { Select } from 'antd'
import { api } from 'api/index'
import { Category } from 'typings/entities'

type Props = {
  onChange?: (arg: number[]) => void
  value?: number[]
  mode?: 'multiple'
  className?: string
  allowClear?: boolean
  placeholder?: string
}

const CategoriesSelect: React.FC<Props> = (props) => {
  const categories = api.useGetCategoriesQuery(undefined, { refetchOnMountOrArgChange: true })

  return (
    <Select
      allowClear={props.allowClear}
      style={{ display: 'block', width: 200 }}
      className={props.className}
      loading={categories.isFetching}
      placeholder={props.placeholder ?? 'Select categories'}
      mode={props.mode}
      value={props.value}
      onChange={props.onChange}
      options={categories.data?.map((c) => ({ label: `#${c.id} ${c.name}`, value: c.id }))}
      filterOption={(input, option) => option!.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    />
  )
}

export default CategoriesSelect
