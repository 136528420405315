import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button, message, Spin, Divider, Row } from 'antd'
import { ReloadOutlined } from '@ant-design/icons'
import { useForm } from 'antd/es/form/Form'
import { skipToken } from '@reduxjs/toolkit/dist/query'

import { api } from 'api/index'
import { PostProduct, Product } from 'typings/entities'
import IssuerProductCard from 'components/IssuerProductCard'
import ProductForm from './ProductForm'
import styles from './Products.module.scss'

const EditProduct = ({ product }: { product: Product }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [formKey, setFormKey] = useState(0)
  const [form] = useForm()
  const [updateProductRequest] = api.useUpdateIssuerProductMutation()
  //Picture
  const [addPictureRequest] = api.useCreateIssuerProductPictureMutation()
  const [deletePictureRequest] = api.useDeleteIssuerProductPictureMutation()
  const [patchPictureOrderRequest] = api.usePatchIssuerProductPictureOrderMutation()
  //Video
  const [addVideoRequest] = api.useCreateIssuerProductVideoMutation()
  const [deleteVideoRequest] = api.useDeleteIssuerProductVideoMutation()
  const [patchVideoOrderRequest] = api.usePatchIssuerProductVideoOrderMutation()
  //Attachment
  const [addAttachmentRequest] = api.useCreateIssuerProductAttachmentMutation()
  const [deleteAttachmentRequest] = api.useDeleteIssuerProductAttachmentMutation()
  const [patchAttachmentOrderRequest] = api.usePatchIssuerProductAttachmentOrderMutation()
  //Chart
  const [addChartRequest] = api.useCreateIssuerProductChartMutation()
  const [deleteChartRequest] = api.useDeleteIssuerProductChartMutation()
  const [patchChartOrderRequest] = api.usePatchIssuerProductChartOrderMutation()

  const initialValues = {
    ...product,
    categories: product.categories.map((c) => c.id)[0],
    pictures: product.images.map((i, ind) => ({ ...i, key: ind })).sort((a, b) => (a.order > b.order ? 1 : -1)),
    videos: product.videos.map((c) => c).sort((a, b) => (a.order > b.order ? 1 : -1)),
    charts: product.charts.map((c) => ({ ...c, data: { name: c.title + '.csv' } })),
    issuer_firm: product.issuer_firm.id,
  }

  const updPictures = async (pictures: any[]) => {
    const oldPictures = product.images
    const promises: Promise<any>[] = []

    // delete pictures
    for (let i = 0; i < oldPictures.length; i++) {
      const oldPicture = oldPictures[i]
      const picture = pictures.find((p) => p.id === oldPicture.id)
      if (picture === undefined) promises.push(deletePictureRequest({ id: oldPicture.id, productId: product.id }))
    }

    // add pictures
    if (pictures) {
      for (let i = 0; i < pictures.length; i++) {
        const picture = pictures[i]
        if ('url' in picture) {
          promises.push(
            patchPictureOrderRequest({
              id: picture.id,
              order: i,
              productId: product.id,
              background_color: picture.background_color,
            }),
          )
          continue
        }
        const formData = new FormData()
        formData.append('file', picture)
        promises.push(
          addPictureRequest({
            productId: product.id,
            order: i,
            background_color: picture.background_color,
            body: formData,
          }),
        )
      }
    }
    await Promise.all(promises)
  }

  const updVideos = async (videos: any[]) => {
    const oldVideos = product.videos
    const promises: Promise<any>[] = []

    // delete videos
    for (let i = 0; i < oldVideos.length; i++) {
      const oldVideo = oldVideos[i]
      const video = videos.find((v) => v.id === oldVideo.id)
      if (video === undefined) promises.push(deleteVideoRequest({ id: oldVideo.id, productId: product.id }))
    }

    // add videos
    if (videos) {
      for (let i = 0; i < videos.length; i++) {
        const video = videos[i]
        if ('id' in video) {
          promises.push(
            patchVideoOrderRequest({
              id: video.id,
              order: i,
              productId: product.id,
              background_color: video.background_color,
            }),
          )
          continue
        }
        const formData = new FormData()
        formData.append('file', video.file)
        formData.append('preview', video.preview)
        promises.push(
          addVideoRequest({
            productId: product.id,
            order: i,
            background_color: video.background_color,
            body: formData,
          }),
        )
      }
    }

    await Promise.all(promises)
  }

  const updAttachments = async (attachments: any[]) => {
    const oldAttachments = product.attachments

    // delete attachments
    for (let i = 0; i < oldAttachments.length; i++) {
      const oldAttachment = oldAttachments[i]
      const attachment = attachments.find((p) => p.url === oldAttachment.url)
      if (attachment === undefined) await deleteAttachmentRequest({ id: oldAttachment.id, productId: product.id })
    }

    // add pictures
    if (attachments) {
      for (let i = 0; i < attachments.length; i++) {
        const attachment = attachments[i]
        if ('url' in attachment) {
          // fix order
          if (attachment.order !== i) {
            await patchAttachmentOrderRequest({ id: attachment.id, order: i, productId: product.id })
          }
          continue
        }
        const formData = new FormData()
        formData.append('file', attachment)
        await addAttachmentRequest({ productId: product.id, order: i, body: formData })
      }
    }
  }

  const updCharts = async (charts: any[]) => {
    const errors: { title: string; error: string }[] = []
    const oldCharts = product.charts

    // delete charts
    for (let i = 0; i < oldCharts.length; i++) {
      const oldChart = oldCharts[i]
      const chart = charts.find((c) => c.id === oldChart.id)
      if (chart === undefined) {
        const resp = await deleteChartRequest({ id: oldChart.id, productId: product.id })
        if ('error' in resp) {
          const error = 'Failed to delete chart'
          message.error(
            <span>
              Error occurred while deleting chart <b>{oldChart.title}</b>
            </span>,
            15,
          )
          errors.push({ title: oldChart.title, error })
        }
      }
    }

    // add charts
    if (charts) {
      for (let i = 0; i < charts.length; i++) {
        const chart = charts[i]
        if ('id' in chart) {
          const body = {
            title: chart.title,
            x_label: chart.x_label,
            y_label: chart.y_label,
            order: i,
          }
          const oldChart = oldCharts.find((c) => c.id === chart.id)!
          const needUpdate = Object.entries(body).reduce((p: boolean, [key, value]) => {
            if (p) return p
            // @ts-ignore
            else return value !== oldChart[key]
          }, false)
          if (needUpdate) await patchChartOrderRequest({ id: chart.id, body, productId: product.id })
          continue
        }
        const formData = new FormData()
        const file = chart.data!
        delete chart.data
        formData.append('file', file)
        const resp = await addChartRequest({ ...chart, product: product.id, order: i, body: formData })
        if ('data' in resp) {
          if ('error' in resp.data) {
            //@ts-ignore
            const error = resp.data.error
            message.error(
              <span>
                Error occurred while adding chart <b>{chart.title}</b> <b>{error}</b>
              </span>,
              15,
            )
            errors.push({ title: chart.title, error })
          }
        }
      }
    }
    return errors
  }

  const onFinish = async ({ pictures, videos, attachments, charts, ...data }: any) => {
    console.log('onFinish', data)
    console.log('onFinish', pictures, videos, attachments, charts)
    // return
    setIsLoading(true)
    const needUpdate = Object.entries(data).reduce((p: boolean, [key, value]) => {
      if (p) return p
      // @ts-ignore
      else return JSON.stringify(initialValues[key]) !== JSON.stringify(value)
    }, false)
    if (needUpdate) {
      await updateProductRequest({ id: product.id, product: { ...data, categories: data.categories } as PostProduct })
    }
    await Promise.all([updPictures(pictures), updVideos(videos), updAttachments(attachments), updCharts(charts)]).catch(
      () => {
        console.warn('Error occurred while updating charts')
      },
    )
    setIsLoading(false)
    setFormKey((k) => k + 1)
    message.success('The product has been updated')
  }

  return (
    <div className={styles.page}>
      <h1 style={{ marginBottom: 30 }}>{product.name}</h1>
      <div className={styles.form}>
        <ProductForm
          key={formKey}
          form={form}
          onFinish={onFinish}
          initialValues={initialValues}
          isLoading={isLoading}
        />
      </div>
    </div>
  )
}

const EditProductContainer = () => {
  const id = parseInt(useParams().id!)
  const { data: product } = api.useGetIssuerProductQuery(id)

  if (product === undefined) return <Spin />
  else
    return (
      <>
        <EditProduct product={product} />
        <Divider />
        <h2>Issuer product preview</h2>
        <PreviewProductCollapse id={product.id} />
      </>
    )
}

const PreviewProductCollapse: React.FC<{ id?: number }> = (props) => {
  const [v, sv] = useState(false)
  const product = api.useGetIssuerProductQuery(props.id ?? skipToken, { refetchOnMountOrArgChange: true })
  if (!props.id) return null
  if (!v)
    return (
      <Button
        onClick={() => {
          product.refetch()
          sv(true)
        }}>
        Show Preview
      </Button>
    )
  return (
    <>
      {product.isLoading && <Spin />}

      {!product.isLoading && (
        <Row>
          <Button onClick={() => sv(false)}>Hide Preview</Button>
          <Button icon={<ReloadOutlined spin={product.isFetching} />} onClick={product.refetch} />
        </Row>
      )}
      {product.data && <IssuerProductCard product={product.data} />}
    </>
  )
}

export default EditProductContainer
