import { api } from 'api/index'
import { Button, Form, message } from 'antd'
import UITexts from 'export/UITexts'
import { useNavigate } from 'react-router-dom'
import React from 'react'
import FirmForm, { FirmFormType } from '../../components/FirmForm'

const CreateSellerFirm = () => {
  const navigate = useNavigate()
  const [mutate, resp] = api.useCreateSellerFirmMutation()
  const [postAvatar, { isLoading: avatarLoading }] = api.useCreateSellerFirmAvatarMutation()
  const [postBackground, { isLoading: backgroundLoading }] = api.useCreateSellerFirmBackgroundMutation()
  const [form] = Form.useForm<FirmFormType>()
  const isLoading = Boolean(resp.isLoading || avatarLoading || backgroundLoading)

  const onSubmit = async () => {
    const values = await form.validateFields().catch(() => null)
    if (values === null) return
    const avatar = values.avatar
    delete values.avatar
    const background = values.background
    delete values.background

    const resp = await mutate(values)
    if ('data' in resp) {
      if (avatar) {
        const formData = new FormData()
        formData.append('file', avatar)
        await postAvatar({ id: resp.data.id, body: formData })
      }
      if (background) {
        const formData = new FormData()
        formData.append('file', background)
        await postBackground({ id: resp.data.id, body: formData })
      }
      message.success(UITexts.SellerFirm.UPDATE_SUCCESS)
      navigate('/profile')
    } else {
      message.error(UITexts.SellerFirm.UPDATE_ERROR)
    }
  }

  return (
    <div>
      <h1>Create Seller firm</h1>
      <FirmForm form={form} />
      <Button type={'primary'} onClick={onSubmit} loading={isLoading}>
        Save
      </Button>
    </div>
  )
}

export default CreateSellerFirm
