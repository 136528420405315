import { Chart } from 'typings/entities'
import { BuilderType, api } from 'api/index'

export const createIssuerProductChart = (builder: BuilderType) =>
  builder.mutation<Error | Chart, { product: number; order: number; body: FormData } & Omit<Chart, 'id' | 'chart'>>({
    query: (args) => {
      {
        const params: Partial<typeof args> = { ...args }
        delete params.body
        return {
          url: 'issuer_product_chart',
          params,
          method: 'POST',
          body: args.body,
        }
      }
    },
    onQueryStarted(args, { dispatch, queryFulfilled }) {
      queryFulfilled.then((resp) => {
        const chart = resp.data
        if (!('error' in chart)) {
          dispatch(
            api.util.updateQueryData('getIssuerProduct', args.product, (product) => {
              // @ts-ignore
              product.charts.push(chart)
            }),
          )
        }
      })
    },
  })
export const deleteIssuerProductChart = (builder: BuilderType) =>
  builder.mutation<void, { productId: number; id: number }>({
    query: (args) => ({ url: '/issuer_product_chart/' + args.id, method: 'DELETE' }),
    onQueryStarted(args, { dispatch, queryFulfilled }) {
      queryFulfilled.then(() => {
        dispatch(
          api.util.updateQueryData('getIssuerProduct', args.productId, (product) => {
            product.charts = product.charts.filter((c) => c?.id !== args.id)
          }),
        )
      })
    },
  })
export const patchIssuerProductChartOrder = (builder: BuilderType) =>
  builder.mutation<
    void,
    { productId: number; id: number; body: Pick<Chart, 'title' | 'x_label' | 'y_label' | 'order'> }
  >({
    query: (args) => ({ url: '/issuer_product_chart/' + args.id, method: 'PATCH', body: args.body }),
    onQueryStarted(args, { dispatch, queryFulfilled }) {
      queryFulfilled.then((resp) => {
        dispatch(
          api.util.updateQueryData('getIssuerProduct', args.productId, (product) => {
            const oldChart = product.charts.find((c) => c.id === args.id)
            if (oldChart !== undefined) Object.assign(oldChart, resp.data)
          }),
        )
      })
    },
  })
