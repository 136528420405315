import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { api } from 'api'
import { prettyBool } from 'export/colors'
import useMySellerFirm from 'hooks/useMySellerFirm'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'store'
import { ImportedClient } from 'typings/entities'
const UsersTable = () => {
  const allColumns: ColumnsType<ImportedClient> = [
    {
      dataIndex: 'id',
      title: '#',
      render: (v, row) => <Link to={'/imported_client/' + row.id}>{v}</Link>,
      sorter: (a, b) => a.id - b.id,
      defaultSortOrder: 'descend',
    },
    {
      dataIndex: 'account_name',
      title: 'Account Name',
      render: (v, row) => <Link to={'/imported_client/' + row.id}>{v}</Link>,
    },
    { dataIndex: 'first_name', title: 'First Name' },
    { dataIndex: 'last_name', title: 'Last Name' },
    // {
    //   dataIndex: 'tags',
    //   title: 'Tags',
    //   render: (v) => {
    //     console.log('v', v)
    //     return <TagsPreview tags={v} />
    //   },
    // },
    { dataIndex: 'email', title: 'Email' },
    {
      dataIndex: 'mobile_phone',
      title: 'Phone',
      render: (v) => <span style={{ whiteSpace: 'nowrap', fontSize: '12px' }}>{v}</span>,
    },
    {
      dataIndex: 'household_value',
      // title: <span style={{ fontSize: '12px', whiteSpace: 'nowrap' }}>Household value</span>,
      title: 'Household value',
      render: (v) => (v ? v.toLocaleString() : '-'),
    },
    { dataIndex: 'household_cash_value', title: 'Household cash value', render: (v) => (v ? v.toLocaleString() : '-') },
    { dataIndex: 'billing_state', title: 'Billing state' },
    { dataIndex: 'zip_code', title: 'Zip code' },
    { dataIndex: 'accredited_investor', title: 'Accredited investor', render: prettyBool },
    { dataIndex: 'qualified_purchaser', title: 'Qualified purchaser', render: prettyBool },
    // {
    //   dataIndex: 'last_modified_date',
    //   title: 'Last update',
    //   render: (v) => <span style={{ fontSize: '12px', whiteSpace: 'nowrap' }}>{v}</span>,
    // },
  ]
  const [page, setPage] = useState({
    current: 1,
    pageSize: 10,
  })
  const sellerFirm = useMySellerFirm()
  const analytic = useSelector((state) => state.analytic)
  const [getUsersFilter, usersFilterData] = api.useLazyGetUsersFilterQuery()

  const getUsers = () => {
    const bodyUsersFilter = {
      seller_firm: sellerFirm?.id,
    }
    for (const i in analytic) {
      //@ts-ignore
      if (analytic[i] && analytic[i].length > 0) {
        //@ts-ignore
        bodyUsersFilter[i] = analytic[i]
      }
    }
    getUsersFilter({
      body: bodyUsersFilter,
      params: {
        limit: page.pageSize,
        page: page.current,
        // order_by: '-create_at',
      },
    })
  }

  useEffect(() => {
    getUsers()
    setPage((page) => ({ ...page, current: 1 }))
  }, [analytic])
  useEffect(() => {
    getUsers()
  }, [page])
  return (
    <Table
      pagination={{
        pageSize: page.pageSize,
        current: page.current,
        total: (usersFilterData.data?.pagination.pages_count ?? 0) * page.pageSize,
        onChange: (a, b) => {
          setPage({
            current: a,
            pageSize: b,
          })
        },
      }}
      columns={allColumns}
      dataSource={usersFilterData.data?.payload}
      rowKey={'id'}
      size={'small'}
      loading={usersFilterData.isFetching}
    />
  )
}

export default UsersTable
