import { SaveOutlined, DeleteOutlined, LoadingOutlined } from '@ant-design/icons'
import { message, Select } from 'antd'
import { api } from 'api/api'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { dateFormat } from './AnalyticFilter'
import * as _ from 'lodash'
import { defaultFields } from './AnalyticFilterForm'
const defaultIconStyle = { fontSize: 18, cursor: 'pointer', marginLeft: 10 }

type PropsType = {
  form: any
  formValue?: any
  newPreset?: number
  setNewPreset?: (id?: number) => void
}

const getNormDate = (date: [string, string]) => {
  return [moment(new Date(date[0])), moment(new Date(date[1]), dateFormat)]
}
const filtersToFormValue = (filters: any) => {
  console.log('filters', filters)

  return {
    ...filters,
    period: filters.period ? getNormDate(filters.period) : null,
  }
}
const AnalyticFilterPreset: React.FC<PropsType> = ({ form, formValue, newPreset, setNewPreset }) => {
  const [selectedPreset, setSelectedPreset] = useState<null | number>(null)
  const [changed, setChanged] = useState<boolean>(false)
  const { data, isFetching } = api.useGetFilterPresetQuery({})
  const [onDeletePreset, resultDelete] = api.useDeleteFilterPresetMutation()
  const [onUpdatePreset, resultUpdate] = api.useUpdateFilterPresetMutation()
  const onDelete = () => {
    if (resultDelete.isLoading) return
    if (selectedPreset)
      onDeletePreset(selectedPreset).then((r) => {
        if ('data' in r) {
          message.success('Filter configuration deleted')
          setSelectedPreset(null)
          setChanged(false)
        }
      })
  }
  const onUpdate = () => {
    if (resultUpdate.isLoading) return
    if (selectedPreset && data) {
      const currentPreset = data.find((e) => e.id === selectedPreset)
      const body = { ...currentPreset, filters: form.getFieldsValue() }
      //@ts-ignore
      onUpdatePreset(body).then((r) => {
        if ('data' in r) {
          message.success('Filter configuration updated')
          setChanged(false)
        }
      })
    }
  }
  const updateForm = (id: number) => {
    if (data) {
      const filters = data.find((e) => e.id === id)?.filters
      if (filters) {
        // const filtersToFormValue1 = filtersToFormValue(filters)
        // console.log('filtersToFormValue', filtersToFormValue1)

        form.setFieldsValue(filtersToFormValue(filters))
        setChanged(false)
      }
    }
  }
  useEffect(() => {
    if (selectedPreset) updateForm(selectedPreset)
  }, [selectedPreset])
  useEffect(() => {
    if (newPreset && !isFetching) {
      setSelectedPreset(newPreset)
      setNewPreset?.()
    }
  }, [newPreset, isFetching])

  useEffect(() => {
    if (formValue && data && selectedPreset) {
      const filters = data.find((e) => e.id === selectedPreset)?.filters
      if (!_.isEqual(formValue, filtersToFormValue(filters))) {
        !changed && setChanged(true)
      } else {
        changed && setChanged(false)
      }
    }
  }, [formValue])
  return (
    <div style={{ marginLeft: 20 }}>
      <Select
        value={selectedPreset}
        style={{ width: 200 }}
        placeholder="Select filter preset"
        options={data?.map((d) => ({ label: d.name, value: d.id }))}
        onChange={setSelectedPreset}
      />
      {selectedPreset && (
        <>
          {resultDelete.isLoading ? (
            <LoadingOutlined style={{ ...defaultIconStyle, color: '#ff4d4f' }} />
          ) : (
            <DeleteOutlined onClick={onDelete} style={{ ...defaultIconStyle, color: '#ff4d4f' }} />
          )}
        </>
      )}
      {selectedPreset && changed && (
        <>
          {resultUpdate.isLoading ? (
            <LoadingOutlined style={{ ...defaultIconStyle, color: '#1890ff' }} />
          ) : (
            <SaveOutlined onClick={onUpdate} style={{ ...defaultIconStyle, color: '#1890ff' }} />
          )}
        </>
      )}
    </div>
  )
}

export default AnalyticFilterPreset
