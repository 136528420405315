import { Select } from 'antd'
import useMySellerFirm from 'hooks/useMySellerFirm'
import { api } from 'api/index'
import React, { CSSProperties } from 'react'

type Props = {
  value?: number[]
  onChange?: (arg: number[]) => void
  style?: CSSProperties
}

const ImportedClientSelect: React.FC<Props> = (props) => {
  const sellerFirm = useMySellerFirm()
  const clients = api.useGetImportedClientsQuery(
    { seller_firm_id: sellerFirm?.id },
    {
      refetchOnMountOrArgChange: true,
    },
  )
  return (
    <Select
      allowClear
      showSearch
      options={clients.data?.map((c) => ({
        value: c.id,
        label: `${c.id} ${c.account_name ?? ''}${c.billing_state ? ' ' + c.billing_state : ''} ${
          c.email ? `[${c.email}]` : ''
        }`,
      }))}
      loading={clients.isLoading}
      value={props.value}
      onChange={props.onChange}
      mode={'multiple'}
      style={props.style}
    />
  )
}

export default ImportedClientSelect
