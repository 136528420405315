import React from 'react'
import { Category } from 'typings/entities'
type Props = {
  categories?: Category[]
}
const CategoriesPreview: React.FC<Props> = (props) => {
  return (
    <>
      {props.categories?.map((c) => (
        <p key={c.id} style={{ marginBottom: 0, fontSize: '12px', whiteSpace: 'nowrap' }}>
          {c.name}
        </p>
      ))}
    </>
  )
}

export default CategoriesPreview
