import { Firm } from 'typings/entities'
import { profileActions } from 'store/reducers/profileReducer'
import { BuilderType } from 'api/index'

export const getSellerFirmBackground = (builder: BuilderType) =>
  builder.query<Firm, number>({
    query: (id) => ({ url: '/seller_firm/background/' + id }),
  })
export const createSellerFirmBackground = (builder: BuilderType) =>
  builder.mutation<{ url: string }, { id: number; body: FormData }>({
    query: (data) => ({ url: '/seller_firm/background/' + data.id, method: 'POST', body: data.body }),
    onQueryStarted(args, { dispatch, queryFulfilled }) {
      queryFulfilled.then((resp) => {
        dispatch(profileActions.setSellerFirmBackground(resp.data.url))
      })
    },
  })
export const deleteSellerFirmBackground = (builder: BuilderType) =>
  builder.mutation<unknown, number>({
    query: (id) => ({ url: '/seller_firm/background/' + id, method: 'DELETE' }),
  })
