import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import React from 'react'

export enum ANTD_COLORS {
  BLUE = '#1890ff',
  RED = '#ff4d4f',
}

export enum TEXT_COLORS {
  blue = 'rgb(24, 144, 255)',
  black = '#172b4d',
  grey = '#d9d9d9',
}

export const prettyBool = (bool: boolean) =>
  bool ? <CheckOutlined style={{ color: 'green' }} /> : <CloseOutlined style={{ color: 'red' }} />
