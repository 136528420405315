import React from 'react'
import { Button, message, Table } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import { api } from 'api/index'
import { ColumnsType } from 'antd/es/table/Table'
import { Campaign } from 'typings/entities'
import { prettyDate } from 'utils'
import DeleteCampaignButton from './components/DeleteCampaignButton'

const columns: ColumnsType<Campaign> = [
  { title: '#', dataIndex: 'id' },
  { title: 'Name', dataIndex: 'name', render: (v, { id }) => <Link to={'/campaign/' + id}>{v}</Link> },
  { title: 'Date from', dataIndex: 'dt_from', render: (v) => prettyDate(new Date(v).getTime() / 1000).split(' ')[0] }, //, render: (v) => new Date(v).toLocaleDateString()
  { title: 'Date to', dataIndex: 'dt_to', render: (v) => prettyDate(new Date(v).getTime() / 1000).split(' ')[0] },
  { title: 'Products', dataIndex: 'products', render: (v) => v?.length ?? 0 },
  { title: 'Clients', dataIndex: 'clients', render: (v) => v?.length ?? 0 },
  {
    title: 'Last update',
    dataIndex: 'update_at',
    render: (v) => prettyDate(v),
    sorter: (a, b) => a.update_at - b.update_at,
    defaultSortOrder: 'descend',
  },
  {
    title: 'Actions',
    dataIndex: 'create_at',
    render: (v, row) => <DeleteCampaignButton id={row.id} />,
  },
]

const Campaigns = () => {
  const navigate = useNavigate()
  const campaigns = api.useGetCampaignsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  })

  const onNewCampaign = () => {
    navigate('/campaign/new')
  }

  return (
    <div>
      <h1 style={{ marginBottom: 30 }}>Advertising campaigns</h1>
      <div style={{ display: 'flex', marginBottom: 16 }}>
        <Button onClick={campaigns.refetch} type={'primary'}>
          Reload
        </Button>
        <Button type={'primary'} onClick={onNewCampaign} style={{ marginLeft: 'auto' }}>
          New campaign
        </Button>
      </div>
      <Table
        size={'small'}
        columns={columns}
        dataSource={campaigns.data}
        loading={campaigns.isFetching}
        rowKey={'id'}
        bordered
      />
    </div>
  )
}

export default Campaigns
