import React, { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Button, Divider, Form, Input, message, Result, Row, Spin } from 'antd'
import ImportedClientForm from 'pages/ImportedClient/ImportedClientForm'
import { api } from 'api/index'
import UITexts from 'export/UITexts'
import { ImportedClient } from 'typings/entities'
import useMySellerFirm from 'hooks/useMySellerFirm'
import UserInfoCard from 'components/UserInfoCard'
import ImportedClientAnalytic from './ImportedClientAnalytic'

const ImportedClientPage = () => {
  const p = useParams<{ id: string }>()
  const clientId = p.id !== undefined ? parseInt(p.id) : undefined
  const navigate = useNavigate()
  const sellerFirm = useMySellerFirm()
  const [update, updResp] = api.useUpdateImportedClientMutation()
  const c = api.useGetImportedClientQuery(clientId!, { skip: !clientId })
  const [form] = Form.useForm<Partial<ImportedClient>>()

  const onOk = async () => {
    if (!clientId) return
    const values = await form.validateFields()
    console.log(values)
    if (values.tags === undefined) {
      delete values['tags']
    }
    Object.keys(values).forEach((k) => {
      //@ts-ignore
      if (values[k] === undefined) {
        //@ts-ignore
        delete values[k]
      }
    })
    // return
    if (!values) return
    const updResp = await update({ ...values, id: clientId })
    if ('data' in updResp) {
      message.success(UITexts.ImportedClient.UPDATE_SUCCESS)
      navigate('/imported_client/all')
    } else {
      message.error(UITexts.ImportedClient.CREATE_ERROR)
    }
  }

  if (!clientId || c.isError) {
    return <Result title={UITexts.ImportedClient.NOT_FOUND} status={'404'} />
  }

  if (c.isLoading) return <Spin />

  return (
    <div>
      <Row align={'middle'} justify={'space-between'}>
        <h1>Edit imported client</h1>
        <Link to={'/imported_client/all'}>All clients</Link>
      </Row>
      <ImportedClientForm
        form={form}
        initialValues={{ ...c.data, seller_firm: c.data?.seller_firm.id, tags: c.data?.tags.map((t) => t.id) }}
        loading={updResp.isLoading}
        onOk={onOk}
      />
      {c.data?.user && (
        <>
          <UserInfoCard user={c.data.user} />
          <UpdateUserPassword id={c.data.user.id} disabled={!c.data.user.autogenerated} />
        </>
      )}
      <Divider />
      {c.data && c.data.user && sellerFirm && (
        <ImportedClientAnalytic user_id={c.data.user.id} seller_firm={sellerFirm.id} />
      )}
    </div>
  )
}

const UpdateUserPassword = (props: { id: number; disabled: boolean }) => {
  const [update, resp] = api.useUpdateSpecificUserMutation()
  const [p, sp] = useState<string>('')
  const onClick = async () => {
    const resp = await update({ id: props.id, password: p })
    if ('data' in resp) {
      message.success(UITexts.user.UPDATE_SUCCESS)
    } else {
      message.error(UITexts.user.UPDATE_ERROR)
    }
  }
  return (
    <div
      style={{ flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center', width: 'fit-Content', marginTop: 8 }}>
      <p style={{ width: '100%', marginBottom: 0 }}>
        <b>Set user password:</b>
      </p>
      <Input.Password style={{ width: 200 }} value={p} onChange={(e) => sp(e.target.value)} disabled={props.disabled} />
      <Button type={'primary'} onClick={onClick} disabled={p === '' || props.disabled} loading={resp.isLoading}>
        Ok
      </Button>
    </div>
  )
}

export default ImportedClientPage
