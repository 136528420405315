import { Button, Form, Input, InputNumber, message, Row } from 'antd'
import React from 'react'
import UITexts from 'export/UITexts'
import { Link, useNavigate } from 'react-router-dom'
import useMySellerFirm from 'hooks/useMySellerFirm'
import FormValueString from 'ui/customFormInputs/FormValueString'
import TagsSelect from 'ui/customFormInputs/TagsSelect'
import { ImportedClient } from 'typings/entities'
import { api } from 'api/index'
import ImportedClientForm from 'pages/ImportedClient/ImportedClientForm'

const CreateImportedClient = () => {
  const [form] = Form.useForm<Partial<ImportedClient>>()
  const [create, resp] = api.useCreateImportedClientMutation()
  const navigate = useNavigate()
  const sellerFirm = useMySellerFirm()
  const onOk = async () => {
    const values = await form.validateFields()
    console.log(values)
    if (values.tags === undefined) {
      delete values['tags']
    }
    // return
    if (!values) return
    const resp = await create(values)
    if ('data' in resp) {
      message.success(UITexts.ImportedClient.CREATE_SUCCESS)

      navigate('/imported_client/all')
    } else {
      message.error(UITexts.ImportedClient.CREATE_ERROR)
    }
  }
  return (
    <div>
      <Row align={'middle'} justify={'space-between'}>
        <h1>Create imported client</h1>
        <Link to={'/imported_client/all'}>All clients</Link>
      </Row>
      <ImportedClientForm
        form={form}
        initialValues={{ seller_firm: sellerFirm?.id }}
        loading={resp.isLoading}
        onOk={onOk}
      />
    </div>
  )
}

export default CreateImportedClient
