import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { api } from 'api/index'
import { Button, Form, Image, InputNumber, message, Row, Spin } from 'antd'
import CategoriesSelect from 'ui/customFormInputs/CategoriesSelect'
import SellerFirmSelect from 'ui/customFormInputs/SellerFirmSelect'
import UITexts from 'export/UITexts'
import IssuerProductCard from 'components/IssuerProductCard'
import { useSelector } from 'store'

const SellerProduct = () => {
  const p = useParams()
  const profile = useSelector((store) => store.profile)
  const productId = p.id === undefined ? undefined : parseInt(p.id)
  const product = api.useGetSellerProductQuery(productId!, { skip: !productId })

  const [mutate, mutateResp] = api.useUpdateSellerProductMutation()
  const [raw, setRaw] = useState(false)
  const [form] = Form.useForm()
  const onOk = async () => {
    if (!productId) return
    const values = await form.validateFields()
    console.log('values', values)
    if (!values) return
    const resp = await mutate({ id: productId, data: { ...values } })
    if ('data' in resp) {
      message.success(UITexts.SellerProduct.UPDATE_SUCCESS)
    } else {
      message.error(UITexts.SellerProduct.UPDATE_ERROR)
    }
  }
  return (
    <div>
      <Row align={'middle'} justify={'space-between'}>
        <h1>Product #{productId}</h1>
        <Link to={'/seller_product/all'}>Back</Link>
      </Row>
      {product.isFetching || !product.data ? (
        <Spin />
      ) : (
        <div>
          <Form
            form={form}
            initialValues={{
              seller_firm: product.data?.seller_firm?.id,
              categories: product.data?.categories?.map((c) => c.id),
              order: product.data?.order,
            }}>
            <Form.Item name={'seller_firm'} label={'Seller firm'}>
              <SellerFirmSelect disabled={profile?.user?.is_superuser === false} />
            </Form.Item>
            {/*<Form.Item name={'categories'} label={'Categories'}>*/}
            {/*  <CategoriesSelect />*/}
            {/*</Form.Item>*/}
            <Form.Item name={'order'} label={'Order'}>
              <InputNumber />
            </Form.Item>

            <Row justify={'end'}>
              <Button type={'primary'} onClick={onOk}>
                Ok
              </Button>
            </Row>
          </Form>
          <h2>Issuer product:</h2>
          <IssuerProductCard product={product.data} />
        </div>
      )}
    </div>
  )
}

// {raw ? (
//   <div>
//     <Button onClick={() => setRaw(false)}>Hide</Button>
//     <pre>{JSON.stringify(product.data, undefined, ' ')}</pre>
//   </div>
// ) : (
//   <Button onClick={() => setRaw(true)}>Show raw data</Button>
// )}

export default SellerProduct
