import { DatePicker, Form, Input, InputNumber, Select, Slider, TreeSelect } from 'antd'
import { isArray } from 'highcharts'
import { FieldData } from 'rc-field-form/lib/interface'
import React, { useEffect, useState } from 'react'
import moment, { Moment } from 'moment'

import SelectProducts from 'components/SelectProducts/SelectProducts'
import SelectState from 'components/SelectState/SelectState'
import CategoriesSelect from 'ui/customFormInputs/CategoriesSelect'
import styles from './AnalyticFilter.module.scss'
import { AnalyticState } from '../../store/reducers/analyticReducer'

export const ranges: Record<string, [Moment, Moment]> = {
  today: [moment().startOf('day'), moment().endOf('day')],
  '2 weeks': [moment().subtract(2, 'week'), moment()],
  '4 weeks': [moment().subtract(4, 'week'), moment()],
  'this month': [moment().startOf('month'), moment()],
  '3 months': [moment().subtract(3, 'months'), moment()],
  '6 months': [moment().subtract(6, 'months'), moment()],
  '2020 ': [moment().set('year', 2020).startOf('year'), moment().set('year', 2020).endOf('year')],
  'from 2017': [moment().set('year', 2017).startOf('year'), moment().endOf('day')],
  'from 2020': [moment().set('year', 2020).startOf('year'), moment().endOf('day')],
  '2020.12 - now': [moment().set({ year: 2020, month: 11, date: 1 }), moment().subtract(1, 'months')],
  '2019.6 - 2020': [moment().set({ year: 2019, month: 5, date: 1 }), moment().set('year', 2020).endOf('year')],
  YTD: [moment().startOf('year'), moment()],

  '1 year': [moment().subtract(1, 'year'), moment()],
  '3 years': [moment().subtract(3, 'years'), moment()],
  '6 years': [moment().subtract(6, 'years'), moment()],
}

type PropsType = {
  form?: any
  className?: string
  classNameItem?: string
  onFinish?: (values: AnalyticState & { period: [Moment, Moment] }) => void
  onFieldsChange?: (values: any) => void
  initialValues?: any
}
export const defaultFields = {
  activity_level: [0, 1],
  categories: [],
  date: null,
  email: null,
  favourite_category: null,
  products: [],
  billing_state: [],
  wealth: null,
  zip_code: null,
}
const AnalyticFilterForm: React.FC<PropsType> = ({
  form,
  className,
  classNameItem,
  onFinish,
  initialValues,
  onFieldsChange,
}) => {
  const [selectedCategories, setSelectedCategories] = useState<undefined | number[]>(undefined)
  const change = (data: FieldData[]) => {
    data.map((c) => {
      //@ts-ignore
      if (c.name[0] === 'categories' && c.value) {
        if (c.value.length === 0) setSelectedCategories(undefined)
        else {
          setSelectedCategories(c.value)
        }
      }
    })
    onFieldsChange?.(data)
  }
  const onCategoriesChange = (e: any) => {
    console.log('onCategoriesChange', e)
  }
  const handleKeyUp = (e: any) => {
    if (e.keyCode === 13) form.submit()
  }

  return (
    <Form
      onKeyUp={handleKeyUp}
      onFieldsChange={change}
      form={form}
      className={className}
      layout="vertical"
      onFinish={onFinish}
      initialValues={{ ...defaultFields, ...initialValues }}>
      <div className={styles.box}>
        <Form.Item className={classNameItem} name={'period'} label={'Date period'}>
          <DatePicker.RangePicker
            style={{ width: '100%' }}
            format={''}
            onChange={(e) => {
              //@ts-ignore
              if (e) console.log(new Date(e[0]).getTime())
            }}
            ranges={ranges}
          />
        </Form.Item>
        <Form.Item className={classNameItem} name={'state'} label={'State'}>
          <SelectState />
        </Form.Item>

        <Form.Item className={classNameItem} name={'activity_level'} label={'Degree of activity'}>
          <ActivitySlider />
        </Form.Item>
        <Form.Item className={classNameItem} name={'household_value'} label={'Household Value'}>
          <HouseholdValue />
        </Form.Item>
        <Form.Item className={classNameItem} name={'household_cash_value'} label={'Household Cash Value'}>
          <HouseholdValue />
        </Form.Item>
      </div>
      <div className={styles.box}>
        <Form.Item className={classNameItem} name={'email'} label={'Email'}>
          <Input placeholder={'email@address.com'} className={styles.input} allowClear />
        </Form.Item>
        <Form.Item className={classNameItem} name={'zip_code'} label={'Zip code'}>
          <Input placeholder={'33065'} className={styles.input} allowClear />
        </Form.Item>
        <div className={styles.categoriesAndProducts}>
          {/* <Form.Item
            className={classNameItem}
            name={'categories'}
            label={'Select Categories'}
            style={{ width: '100%' }}>
            <CategoriesSelect
              mode={'multiple'}
              className={styles.categoriesSelect}
              placeholder={'Fund'}
              onChange={onCategoriesChange}
            />
          </Form.Item> */}
          <Form.Item
            className={classNameItem}
            name={'products'}
            key={selectedCategories?.toString()}
            label={'Select Products'}
            style={{ width: '100%' }}>
            <SelectProducts selectedCategories={selectedCategories} />
          </Form.Item>
        </div>
        <Form.Item className={classNameItem} name={'favourite_category'} label={'Favorite Category'}>
          <CategoriesSelect className={styles.categoriesSelect} />
        </Form.Item>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            rowGap: 10,
            columnGap: 10,
            gridRowGap: 10,
            gridColumnGap: 10,
          }}></div>
      </div>
    </Form>
  )
}

type ActivitySliderType = {
  value?: [number, number]
  onChange?: (value?: [number, number]) => void
}

const ActivitySlider: React.FC<ActivitySliderType> = ({ value, onChange }) => {
  const [inputValue, setInputValue] = useState<[number, number] | undefined>(value)
  useEffect(() => {
    value && setInputValue(value)
  }, [value])
  return (
    <div style={{ display: 'flex' }}>
      <InputNumber
        min={0}
        max={1}
        step={0.01}
        value={inputValue?.[0]}
        onChange={(e) => {
          const newArr = JSON.parse(JSON.stringify(inputValue))
          newArr[0] = e
          if (inputValue) setInputValue(newArr)
        }}
      />
      <Slider
        range
        className={'analytic-slider'}
        style={{ width: '100%', margin: '10px' }}
        min={0}
        max={1}
        step={0.01}
        onAfterChange={(e) => {
          onChange?.(e)
        }}
        onChange={(e) => {
          setInputValue(e)
        }}
        value={isArray(inputValue) ? inputValue : [0, 1]}
      />
      <InputNumber
        min={0}
        max={1}
        step={0.01}
        value={inputValue?.[1]}
        onChange={(e) => {
          const newArr = JSON.parse(JSON.stringify(inputValue))
          newArr[1] = e
          if (inputValue) setInputValue(newArr)
        }}
      />
    </div>
  )
}

type HouseholdValueType = {
  value?: [number, number]
  onChange?: (value?: [number, number]) => void
  min?: number
  max?: number
  step?: number
}

const HouseholdValue: React.FC<HouseholdValueType> = ({ value, onChange, min = 0, max = 10000000000, step = 1000 }) => {
  const [v, sv] = useState<[number, number]>(value ?? [min, max])
  const change = (val: [number, number]) => {
    sv(val)
    onChange?.(val)
  }

  useEffect(() => {
    sv(value ?? [min, max])
  }, [value])

  return (
    <div style={{ display: 'flex' }}>
      <InputNumber
        min={min}
        max={max}
        step={step}
        value={v[0]}
        style={{ width: 'fit-content' }}
        onChange={(e) => {
          const newArr = JSON.parse(JSON.stringify(v))
          newArr[0] = e
          change(newArr)
        }}
        formatter={(f) => Number(f).toLocaleString()}
      />
      <Slider
        range
        className={'analytic-slider'}
        style={{ width: '100%', margin: '10px' }}
        min={min}
        max={max}
        step={step}
        onChange={sv}
        onAfterChange={onChange}
        value={v}
        tipFormatter={(f) => Number(f).toLocaleString()}
      />
      <InputNumber
        min={min}
        max={max}
        step={step}
        value={v[1]}
        style={{ width: 'fit-content' }}
        onChange={(e) => {
          const newArr = JSON.parse(JSON.stringify(v))
          newArr[1] = e
          change(newArr)
        }}
        formatter={(f) => Number(f).toLocaleString()}
      />
    </div>
  )
}
export default AnalyticFilterForm
