import { Row, Table } from 'antd'
import React from 'react'
import { api } from 'api/index'
import { subscribeColumns } from 'pages/Subscribe/Subscribes'
import { ReloadOutlined } from '@ant-design/icons'

type Props = {
  campaignId: number
}
const CampaignSubscribes: React.FC<Props> = (props) => {
  const subscribes = api.useGetSubscribesQuery({ campaign: props.campaignId })
  return (
    <div style={{ marginTop: 20, marginBottom: 20 }}>
      <Row align={'middle'}>
        <h3 style={{ marginBottom: 0 }}>Campaign subscribes</h3>
        <ReloadOutlined style={{ marginLeft: 4 }} onClick={subscribes.refetch} spin={subscribes.isFetching} />
      </Row>
      <Table columns={subscribeColumns} dataSource={subscribes.data} loading={subscribes.isFetching} rowKey={'id'} />
    </div>
  )
}
export default CampaignSubscribes
