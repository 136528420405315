import React, { useEffect, useState } from 'react'
import { Button, Row, Select, Space, Table } from 'antd'
import { Link } from 'react-router-dom'
import { ColumnsType } from 'antd/es/table'

import { AnalyticMetricType, api } from 'api'
import { ImportedClient, Statistic } from 'typings/entities'
import useMySellerFirm from 'hooks/useMySellerFirm'
import { prettyDate } from 'utils'
import useIsSuperuser from 'hooks/useIsSuperuser'

import AnalyticFilter from 'components/AnalyticFilter/AnalyticFilter'
import MapContainer from './components/MapContainer'
import GoogleCalendarChart from './components/CalendarChart'
import styles from './Analytic.module.scss'
import { useSelector } from '../../store'
import { useDispatch } from 'react-redux'
import { analyticActions } from '../../store/reducers/analyticReducer'
import UsersTable from './components/UsersTable'
import ViewsChart from './components/ViewsChart'

export const statisticColumns: ColumnsType = [
  { dataIndex: 'id', title: '#' },
  {
    dataIndex: 'name',
    title: 'Name',
    render: (v) =>
      Array.isArray(v) ? (
        <Link to={'/issuer_product/' + v[0]} target={'_blank'}>
          {v[1]}
        </Link>
      ) : (
        v
      ),
  },
  { dataIndex: 'type', title: 'Type' },
  {
    dataIndex: 'duration',
    title: 'Duration',
    render: (v: number) => (Math.round(v * 10) / 10).toLocaleString() + ' s.',
  },
  {
    dataIndex: 'create_at',
    title: 'Create at',
    render: (v: number) => prettyDate(v),
    sorter: (a: any, b: any) => a.create_at - b.create_at,
    defaultSortOrder: 'descend',
  },
]

const getCat = (s: Statistic) => {
  let cat = 'product'
  if (s.issuer_firm) {
    cat = 'Issuer firm'
  }
  if (s.category) {
    cat = 'Category'
  }
  if (s.issuer_product) {
    cat = 'Product'
  }
  if (s.video) {
    cat = 'Video'
  }
  return cat
}
const getName = (s: Statistic) => {
  if (s.issuer_product) {
    return s.issuer_product.name
  }
  if (s.issuer_firm) {
    return s.issuer_firm.name
  }
  if (s.category) {
    return s.category.name
  }
  if (s.video) {
    if (s.video.issuer_product) {
      return [s.video?.issuer_product.id, 'Video: ' + s.video?.issuer_product.name]
    }
    return s.video?.url.split('/').slice(-1)[0] ?? '-'
  }
  return '-'
}
export const prepareStatistic = (s?: Statistic[]) => {
  return s
    ?.map((s) => ({
      id: s.id,
      name: getName(s),
      duration: s.duration,
      create_at: s.create_at,
      user: s.user,
      type: getCat(s),
    }))
    ?.sort((a, b) => a.create_at - b.create_at)
}

const UserItem = ({ id }: { id: number }) => {
  const sellerFirm = useMySellerFirm()
  const users = api.useGetUsersQuery({ seller_firm: sellerFirm?.id })
  const getUser = (id: number) => {
    return users.data?.find((u) => u.id === id)
  }
  const u = getUser(id)
  return <>{u ? u.username : '-'}</>
}
const TargetMetricSelect = () => {
  const targetMetric = useSelector((store) => store.analytic.targetMetric)
  const dispatch = useDispatch()
  return (
    <Select
      style={{ width: 200 }}
      options={[
        { value: 'duration', label: 'Duration' },
        { value: 'mean_duration', label: 'Average duration' },
        { value: 'views', label: 'Views' },
      ]}
      value={targetMetric}
      onChange={(e) => dispatch(analyticActions.setTargetMetric(e))}
    />
  )
}
export const targetMetricMapper: Record<string, string> = {
  duration: 'Duration',
  mean_duration: 'Average duration',
  views: 'Views',
}
const Analytic = () => {
  const sellerFirm = useMySellerFirm()
  const importedClients = api.useGetImportedClientsQuery({ seller_firm_id: sellerFirm?.id })
  // const [targetMetric, setTargetMetric] = useState<AnalyticMetricType>('views')
  const [targetMetric, setTargetMetric] = useState<AnalyticMetricType>('views')
  const isSuperUser = useIsSuperuser()
  const [c, setC] = useState<number | undefined>(undefined)
  const [sf, setSf] = useState<number | undefined>(sellerFirm?.id)
  const [sp, setSp] = useState<number | undefined>(undefined)

  console.log('render')

  return (
    <div>
      {/* <Row style={{ rowGap: 10, columnGap: 10, gridRowGap: 10, gridColumnGap: 10 }}> */}
      <AnalyticFilter className={styles.analyticFilter} />
      {/* <CategoriesSelect value={c} onChange={setC} allowClear mode={undefined} />
        <SellerFirmSelect allowClear value={sf} onChange={setSf} style={{ width: 200 }} disabled={!isSuperUser} />
        <SellerProductSelect allowClear value={sp} onChange={setSp} style={{ width: 200 }} mode={undefined} /> */}
      {/* </Row> */}
      <UsersTable />

      {sellerFirm && (
        <>
          <TargetMetricSelect />
          <MapContainer key={sellerFirm.id} sellerFirm={25} />
          {/* <ViewsChart sellerFirm={25} /> */}
        </>
      )}
    </div>
  )
}

export default Analytic
