import { Button, Form, Input, message } from 'antd'
import styles from 'pages/Login/Login.module.css'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Link, useNavigate } from 'react-router-dom'
import React, { useState } from 'react'
import { api } from 'api/index'
import md5 from 'md5'
import { PASSWORD_SALT } from 'api/endpoints/profileEndpoints'

const ChangePasswordBlock: React.FC<{ token: string; email: string }> = (props) => {
  const [changePassword, passwordResp] = api.useChangePasswordMutation()
  const [p, setP] = useState('')
  const [p2, setP2] = useState('')
  const navigate = useNavigate()
  const canUpdate = () => {
    if (!p || !p2) return false
    if (p !== p2) return false
    if (p.length < 4 || p2.length < 4) return false
    return true
  }
  const updatePassword = async () => {
    const resp = await changePassword({ password: md5(p + PASSWORD_SALT), authToken: props.token })
    console.log(resp)
    if ('error' in resp) {
      message.error('Error occurred while updating password')
    } else {
      message.success('Password updated')
      navigate('/login')
    }
  }

  return (
    <div className={styles.container}>
      <div>
        <h2>Change password:</h2>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
          <Input.Password value={p} onChange={(e) => setP(e.target.value)} style={{ width: 200 }} />
          <Input.Password value={p2} onChange={(e) => setP2(e.target.value)} style={{ width: 200 }} />
          <Button
            disabled={!canUpdate()}
            style={{ width: 200 }}
            type={'primary'}
            onClick={updatePassword}
            loading={passwordResp.isLoading}>
            Save
          </Button>
        </div>
      </div>
    </div>
  )
}

const Restore = () => {
  const [mutate, resp] = api.useResetPasswordMutation()
  const [getTokenForCode, tokenResp] = api.useGetTokenForCodeMutation()
  const [form] = Form.useForm<{ email: string }>()
  const [form2] = Form.useForm<{ code: string }>()

  const [resetEmailSent, setEmailSent] = useState(false)

  const onSubmit = async (values: { email: string }) => {
    console.log(values)
    const resp = await mutate(values)
    console.log('get code resp', resp)
    if ('error' in resp) {
      message.error('Reset password error')
    } else {
      message.success('Check your Email for code')
      setEmailSent(true)
      setEmail(values.email)
    }
  }
  const [email, setEmail] = useState<string>()
  const [token, setToken] = useState<string>()
  const onCodeSubmit = async () => {
    const values = await form.validateFields()
    const values2 = await form2.validateFields()
    console.log('values', values)
    console.log('values2', values2)
    const req = { code: values2.code, email: email! }
    console.log('req', req)
    // return
    if (!values || !values2) return
    const resp = await getTokenForCode(req)
    console.log(resp)
    if ('error' in resp) {
      message.error('Code error')
    } else {
      if (resp.data?.token) {
        setToken(resp.data.token)
      }
    }
  }
  if (token && email) {
    return <ChangePasswordBlock token={token} email={email} />
  }
  return (
    <div className={styles.container}>
      {resetEmailSent ? (
        <div>
          <h2>Enter code from email</h2>
          <Form form={form2} onFinish={onCodeSubmit}>
            <Form.Item name={'code'} label={'Code'} rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Button className={styles.login} type="primary" htmlType="submit" loading={tokenResp.isLoading}>
              Reset password
            </Button>
          </Form>
        </div>
      ) : (
        <Form form={form} className={styles.form} name="basic" initialValues={{ remember: true }} onFinish={onSubmit}>
          <h2>Enter your Email:</h2>
          <Form.Item
            className={styles.form__item}
            name="email"
            rules={[{ required: true, message: 'Please input your Email!' }]}>
            <Input
              type={'email'}
              autoComplete="on"
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Email"
            />
          </Form.Item>
          <Button className={styles.login} type="primary" htmlType="submit" loading={resp.isLoading}>
            Submit
          </Button>
          <>
            Or <Link to={'/Login'}>Login</Link>
          </>
        </Form>
      )}
    </div>
  )
}
export default Restore
