import { SubscribeStatus } from 'typings/entities'
import { BuilderType } from 'api/index'

export const getSubscribeStatuses = (builder: BuilderType) =>
  builder.query<SubscribeStatus[], { seller_firm?: number }>({
    query: (params) => ({ url: '/subscribe_statuses', params }),
    providesTags: [{ id: '1', type: 'SubscribeStatus' }],
  })

export const getSubscribeStatusesAll = (builder: BuilderType) =>
  builder.query<SubscribeStatus[], { seller_firm?: number }>({
    query: (params) => ({ url: '/subscribe_statuses_all', params }),
    providesTags: [{ id: '1', type: 'SubscribeStatus' }],
  })

export const getSubscribeStatus = (builder: BuilderType) =>
  builder.query<SubscribeStatus, number>({
    query: (id) => ({ url: '/subscribe_status/' + id }),
    // providesTags: [{ id: '1', type: 'SubscribeStatus' }],
    providesTags: (result) => {
      if (result) {
        return [
          { id: '1', type: 'SubscribeStatus' },
          { id: result.id, type: 'SubscribeStatus' },
        ]
      }
      return []
    },
  })
export const deleteSubscribeStatus = (builder: BuilderType) =>
  builder.mutation<unknown, number>({
    query: (id) => ({ url: '/subscribe_status/' + id, method: 'DELETE' }),
    invalidatesTags: (result, _, args) => {
      if (result) {
        return [
          { id: '1', type: 'SubscribeStatus' },
          { id: args, type: 'SubscribeStatus' },
        ]
      }
      return []
    },
  })
export const createSubscribeStatus = (builder: BuilderType) =>
  builder.mutation<SubscribeStatus, Partial<SubscribeStatus>>({
    query: (data) => ({ url: '/subscribe_status', method: 'POST', body: data }),
    invalidatesTags: [{ id: '1', type: 'SubscribeStatus' }],
  })
export const updateSubscribeStatus = (builder: BuilderType) =>
  builder.mutation<SubscribeStatus, { id: number; data: Partial<SubscribeStatus> }>({
    query: ({ id, data }) => ({ url: '/subscribe_status/' + id, method: 'PATCH', body: data }),
    // invalidatesTags: [{ id: '1', type: 'SubscribeStatus' }],
    invalidatesTags: (result) => {
      if (result) {
        return [
          { id: '1', type: 'SubscribeStatus' },
          { id: result.id, type: 'SubscribeStatus' },
        ]
      }
      return []
    },
  })
