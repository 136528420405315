import { SellerProduct } from 'typings/entities'
import { BuilderType } from 'api/index'

export const getSellerProducts = (builder: BuilderType) =>
  builder.query<
    SellerProduct[],
    { seller_firm?: number; category?: number; with_expired?: 0 | 1; approved_only?: 0 | 1 }
  >({
    query: (params) => ({ url: '/seller_products', params }),
    providesTags: [{ id: '1', type: 'SellerProducts' }],
  })
export const createSellerProducts = (builder: BuilderType) =>
  builder.mutation<SellerProduct[], { products: number[]; seller_firm?: number }>({
    query: (body) => ({ url: '/seller_products', method: 'POST', body }),
    invalidatesTags: [
      { id: '1', type: 'SellerProducts' },
      { id: 'all', type: 'IssuerProduct' },
    ],
  })
export const deleteSellerProducts = (builder: BuilderType) =>
  builder.mutation<SellerProduct[], { ids: number[] }>({
    query: (body) => ({ url: '/seller_products', method: 'DELETE', body }),
    invalidatesTags: [
      { id: '1', type: 'SellerProducts' },
      { id: 'all', type: 'IssuerProduct' },
    ],
  })
export const getSellerProduct = (builder: BuilderType) =>
  builder.query<SellerProduct, number>({
    query: (id) => ({ url: '/seller_product/' + id }),
  })
export const updateSellerProduct = (builder: BuilderType) =>
  builder.mutation<SellerProduct, { id: number; data: { product: number } }>({
    query: ({ id, data }) => ({ url: '/seller_product/' + id, method: 'PATCH', body: data }),
    invalidatesTags: [{ id: '1', type: 'SellerProducts' }],
  })
export const deleteSellerProduct = (builder: BuilderType) =>
  builder.mutation<unknown, number>({
    query: (id) => ({ url: '/seller_product/' + id, method: 'DELETE' }),
    invalidatesTags: [
      { id: '1', type: 'SellerProducts' },
      { id: 'all', type: 'IssuerProduct' },
    ],
  })
