import React, { CSSProperties, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { api } from 'api/index'
import { Button, DatePicker, Form, Input, message, Result, Row, Spin } from 'antd'
import TagsSelect from 'ui/customFormInputs/TagsSelect'
import SellerProductSelect from 'ui/customFormInputs/SellerProductSelect'
import UITexts from 'export/UITexts'
import moment, { Moment } from 'moment'
import { SellerProduct } from 'typings/entities'
import { useDispatch } from 'react-redux'
import ImportedClientSelect from 'ui/customFormInputs/ImportedClientSelect'
import LetterTemplateInput from 'ui/customFormInputs/LetterTemplateInput'
import CampaignSubscribes from 'pages/Campaigns/components/CampaignSubscribes'
import { ReloadOutlined } from '@ant-design/icons'

const CampaignPushMobileButton: React.FC<{ id: number; style?: CSSProperties; disabled?: boolean }> = ({
  id,
  style,
  disabled,
}) => {
  const campaign = api.useGetCampaignQuery(id)
  const pushCount = api.useGetAnalyticCampaignPushCountQuery(id)
  const [pushMobile, pushMobileResp] = api.useLazyPushCampaignMobileQuery()
  const onPushClick = async () => {
    const resp = await pushMobile({ id })
    console.log('onPushClick resp', resp)
    if ('data' in resp && resp.data) {
      if (resp.data.status_code === 200) {
        message.success(`Push notification sent (${resp.data.count})`)
        campaign.refetch()
      } else {
        message.error(
          <div>
            <p>Error occurred while sending Push notification:</p>
            <p>{resp.data.reason}</p>
          </div>,
        )
      }
    } else {
      message.error('Error occurred while sending Push notification')
    }
    // const resp = await dispatch(api.endpoints.pushCampaignMobile.initiate({ id: parseInt(p.id!) }))
  }
  if (campaign.data?.is_push_sent) {
    return (
      <div style={{ marginBottom: 0, ...style }}>
        <span>
          {campaign.data?.push_send_count ?? 0} push notifications sent, {pushCount.data?.count ?? 0} viewed
        </span>
        <ReloadOutlined onClick={pushCount.refetch} spin={pushCount.isFetching} style={{ marginLeft: 4 }} />
      </div>
    )
  }
  return (
    <Button
      disabled={disabled}
      type={'primary'}
      size={'large'}
      onClick={onPushClick}
      loading={pushMobileResp.isFetching}>
      Send mobile push
    </Button>
  )
}

const CampaignSendEmailButton: React.FC<{ id: number; style?: CSSProperties; disabled?: boolean }> = ({
  id,
  style,
  disabled,
}) => {
  const campaign = api.useGetCampaignQuery(id)
  const emailCount = api.useGetAnalyticCampaignEmailCountQuery(id)
  const [pushEmail, pushEmailResp] = api.useLazyPushCampaignEmailQuery()
  const onMailClick = async () => {
    const resp = await pushEmail({ id })
    console.log('onMailClick resp', resp)
    if ('data' in resp && resp.data) {
      if (resp.data.status_code === 200) {
        message.success(`Email sent (${resp.data.count})`)
        campaign.refetch()
      } else {
        message.error(
          <div>
            <p>Error occurred while sending Email:</p>
            <p>{resp.data.reason}</p>
          </div>,
        )
      }
    } else {
      message.error('Error occurred while sending Email')
    }
  }
  if (campaign.data?.is_email_sent) {
    return (
      <div style={{ marginBottom: 0, ...style }}>
        <span>
          {campaign.data?.emails_send_count ?? 0} emails sent, {emailCount.data?.count ?? 0} viewed
        </span>
        <ReloadOutlined onClick={emailCount.refetch} spin={emailCount.isFetching} style={{ marginLeft: 4 }} />
      </div>
    )
  }
  return (
    <Button
      disabled={campaign.data?.is_email_sent || disabled}
      type={'primary'}
      size={'large'}
      onClick={onMailClick}
      loading={pushEmailResp.isFetching}
      style={style}>
      Send mail
    </Button>
  )
}

const CampaignPage = () => {
  const p = useParams<{ id: string }>()
  const navigate = useNavigate()
  const campaignId = p.id !== undefined ? parseInt(p.id) : undefined
  const campaign = api.useGetCampaignQuery(campaignId!)
  const [mutate] = api.useUpdateCampaignMutation()
  const [form] = Form.useForm()
  const [changed, setChanged] = useState(false)
  const onOk = async () => {
    if (!campaignId) return
    const values = await form.validateFields()
    if (!values) return
    if (values.advisor_group === undefined) {
      values.advisor_group = null
    }
    console.log('values', values)
    // return
    const resp = await mutate({ id: campaignId, data: values })
    console.log(resp)
    if ('data' in resp) {
      console.log(resp)
      message.success(UITexts.Campaign.UPDATE_SUCCESS)
      setChanged(false)
      // navigate('/campaigns')
      campaign.refetch()
    } else {
      message.error(UITexts.Campaign.UPDATE_ERROR)
    }
  }
  console.log('campaign.data', campaign.data)
  if (!campaignId) return <Result status={'404'} title={'Campaign not found'} />
  return (
    <div>
      <Row align={'middle'} justify={'space-between'}>
        <h1>CampaignPage</h1>
        <Link to={'/campaigns'}>Back</Link>
      </Row>
      {campaign.isLoading ? (
        <Spin />
      ) : (
        <>
          <Form form={form} initialValues={campaign.data} onValuesChange={() => setChanged(true)}>
            <Form.Item name={'name'} label={'Name'}>
              <Input />
            </Form.Item>
            <Form.Item name={'welcome_text'} label={'Welcome Text'}>
              <Input />
            </Form.Item>
            <Form.Item
              name={'dt_from'}
              label={'Date from'}
              getValueProps={(v) => ({ value: moment(v) })}
              getValueFromEvent={(e: Moment) => e.format('YYYY-MM-DD')}>
              <DatePicker />
            </Form.Item>
            <Form.Item
              name={'dt_to'}
              label={'Date from'}
              getValueProps={(v) => ({ value: moment(v) })}
              getValueFromEvent={(e: Moment) => e.format('YYYY-MM-DD')}>
              <DatePicker />
            </Form.Item>
            <Form.Item name={'template'} label={'Template'}>
              <LetterTemplateInput campaignId={campaignId!} />
            </Form.Item>
            <Form.Item name={'advisor'} label={'Advisor'}>
              <Input disabled />
            </Form.Item>
            <Form.Item name={'advisor_group'} label={'Advisor group'}>
              <Input disabled />
            </Form.Item>
            {/*<Form.Item name={'seller_firm'} label={'seller_firm'}>*/}
            {/*  <Input disabled />*/}
            {/*</Form.Item>*/}
            <Form.Item name={'client_tags'} label={'Client tags'}>
              <TagsSelect />
            </Form.Item>
            <Form.Item name={'products'} label={'Products'}>
              <SellerProductSelect mode={'multiple'} />
            </Form.Item>
            <Form.Item name={'clients'} label={'Clients'}>
              <ImportedClientSelect />
            </Form.Item>
            <Row justify={'end'}>
              <Button type={'primary'} onClick={onOk}>
                Ok
              </Button>
            </Row>
          </Form>
          <Row align={'middle'}>
            <CampaignPushMobileButton id={campaignId} disabled={changed} />
            <CampaignSendEmailButton id={campaignId} disabled={changed} style={{ marginLeft: 8 }} />
          </Row>
          <CampaignSubscribes campaignId={campaignId} />
        </>
      )}
    </div>
  )
}

export default CampaignPage
