import React, { useEffect, useState } from 'react'
import styles from './AnalyticFilter.module.scss'
import { Button, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select, Slider, TreeSelect } from 'antd'
import classNames from 'classnames'
import { store, useSelector } from 'store'
import moment, { Moment } from 'moment'
import AnalyticFilterForm from './AnalyticFilterForm'
import AnalyticFilterModal from './AnalyticFilterModal'
import AnalyticFilterPreset from './AnalyticFilterPreset'
import { useDispatch } from 'react-redux'
import { analyticActions, AnalyticState } from 'store/reducers/analyticReducer'

type PropsType = {
  className?: string
}
export const dateFormat = 'MMMM Do YYYY, h:mm:ss a'
const AnalyticFilter: React.FC<PropsType> = ({ className }) => {
  const [filterConfigurationModal, setFilterConfigurationModal] = useState<{
    visible: boolean
    initialValues?: any
  }>({ visible: false })
  const analyticData = useSelector((store) => store.analytic)
  const [formValue, setFormValue] = useState<AnalyticState | null>(null)
  const [newPreset, setNewPreset] = useState<number>()
  const analytic = useSelector((state) => state.analytic)
  const [form] = Form.useForm<AnalyticState>()
  const dispatch = useDispatch()
  const onFinish = (e: AnalyticState & { period: [Moment, Moment] }) => {
    console.log('onFinish', e)
    dispatch(analyticActions.setAnalyticFilterData({ ...e, period: [e.period[0].valueOf(), e.period[1].valueOf()] }))
  }
  const onFieldsChange = () => {
    setFormValue(form.getFieldsValue())
  }
  // console.log(
  //   'analyticData.date',
  //   analyticData.date.map((e) => {
  //     // return moment(e).format('MMMM Do YYYY, h:mm:ss a').valueOf()
  //     return e
  //   }),
  // )
  const onOpenModal = () => {
    setFilterConfigurationModal({
      visible: true,
      initialValues: form.getFieldsValue(),
    })
  }
  const onCloseModal = () => {
    setFilterConfigurationModal({ visible: false })
  }
  const onSuccess = (id: number) => {
    setNewPreset(id)
  }
  const period = store.getState().analytic.period
  const initialValues: { activity: [number, number]; period: [moment.Moment, moment.Moment] } = {
    activity: [0, 1],
    period: [moment(period[0]) as moment.Moment, moment(period[1])],
  }
  const onReset = () => {
    form.resetFields()
    dispatch(analyticActions.resetAnalyticFilterData())
    setFormValue(form.getFieldsValue())
  }
  return (
    <>
      <Row>
        <h1>Analytics</h1>
        <AnalyticFilterPreset newPreset={newPreset} setNewPreset={setNewPreset} formValue={formValue} form={form} />
        <Button style={{ marginLeft: 'auto' }} onClick={onOpenModal}>
          Add new filter configuration
        </Button>
      </Row>
      <div className={styles.form}>
        <AnalyticFilterForm
          form={form}
          onFieldsChange={onFieldsChange}
          onFinish={onFinish}
          className={className}
          initialValues={initialValues}
        />
        <Row style={{ gap: 5 }}>
          <Button style={{ minWidth: 120, marginLeft: 'auto' }} type={'primary'} onClick={() => form.submit()}>
            Apply
          </Button>
          <Button danger type={'text'} onClick={onReset}>
            Reset
          </Button>
        </Row>
      </div>
      {filterConfigurationModal.visible && (
        <AnalyticFilterModal
          onSuccess={onSuccess}
          className={classNames(className, styles.modalForm)}
          onCancel={onCloseModal}
          visible={filterConfigurationModal.visible}
          initialValues={filterConfigurationModal.initialValues}
        />
      )}
    </>
  )
}

export default AnalyticFilter
