import { PostProduct, PostProductOrder, Product } from 'typings/entities'
import { RootState } from 'store'
import { BuilderType, api } from 'api/index'

type IssuerProductsRequest = {
  creator?: number
  issuer_firm?: number
  not_in_seller_firm?: number
  order_by?: '-create_at' | 'create_at'
  with_expired?: 0 | 1
  approved_only?: 0 | 1
  category?: number
  name?: string
  categories?: string
}

export const getIssuerProducts = (builder: BuilderType) =>
  builder.query<Product[], IssuerProductsRequest>({
    query: (params) => ({ url: '/issuer_products', params: { ...params, only_for_me: 0 } }),
    providesTags: [{ id: 'all', type: 'IssuerProduct' }],
  })
export const getIssuerProductsByIds = (builder: BuilderType) =>
  builder.query<Product[], { ids: string }>({
    query: (params) => ({ url: '/issuer_products_by_ids', params }),
    // providesTags: [{ id: 'all', type: 'IssuerProduct' }],
  })

export const deleteIssuerProducts = (builder: BuilderType) =>
  builder.mutation<{}, { ids: number[] }>({
    query: (body) => ({ url: '/issuer_products', method: 'DELETE', body }),
    invalidatesTags: [{ id: 'all', type: 'IssuerProduct' }],
  })
export const getIssuerProduct = (builder: BuilderType) =>
  builder.query<Product, number>({
    query: (id) => '/issuer_product/' + id,
    providesTags: (resp) => (resp ? [{ id: resp.id, type: 'IssuerProduct' }] : []),
  })
export const createIssuerProduct = (builder: BuilderType) =>
  builder.mutation<Product, PostProduct>({
    query: (body) => ({ url: '/issuer_product', method: 'POST', body }),
    invalidatesTags: (resp) => (resp ? [{ id: 'all', type: 'IssuerProduct' }] : []),
  })
export const updateIssuerProduct = (builder: BuilderType) =>
  builder.mutation<Product, { id: number; product: Partial<PostProduct> & { getProductsArgs?: any } }>({
    query: ({ id, product }) => ({ url: '/issuer_product/' + id, method: 'PATCH', body: product }),
    onQueryStarted(args, { dispatch, queryFulfilled, getState }) {
      queryFulfilled.then((resp) => {
        for (const { endpointName, originalArgs } of api.util.selectInvalidatedBy(getState(), [
          { type: 'IssuerProduct', id: 'all' },
        ])) {
          if (endpointName !== 'getIssuerProducts') continue
          dispatch(
            api.util.updateQueryData(endpointName, originalArgs, (products) => {
              const product = products.find((p) => p.id === args.id)
              if (product) {
                Object.assign(product, resp.data)
              }
            }),
          )
        }
        dispatch(
          api.util.updateQueryData('getIssuerProduct', args.id, (product) => {
            Object.assign(product, resp.data)
          }),
        )
      })
    },
  })
export const deleteIssuerProduct = (builder: BuilderType) =>
  builder.mutation<void, number>({
    query: (id) => ({ url: '/issuer_product/' + id, method: 'DELETE' }),
    onQueryStarted(id, { getState, dispatch, queryFulfilled }) {
      queryFulfilled.then(() => {
        const issuer_firm = (getState() as RootState).profile.user?.issuer_firm?.id

        for (const { endpointName, originalArgs } of api.util.selectInvalidatedBy(getState(), [
          { type: 'IssuerProduct', id: 'all' },
        ])) {
          // we only want to update `getPosts` here
          if (endpointName !== 'getIssuerProducts') continue
          dispatch(
            api.util.updateQueryData(endpointName, originalArgs, (products) => {
              const index = products.findIndex((p) => p.id === id)
              if (index !== -1) {
                products.splice(index, 1)
              }
            }),
          )
        }

        dispatch(
          api.util.updateQueryData('getIssuerProducts', { issuer_firm }, (products) => {
            const index = products.findIndex((p) => p.id === id)
            if (index !== -1) {
              products.splice(index, 1)
            }
          }),
        )
      })
    },
  })
export const reorderIssuerProduct = (builder: BuilderType) => {
  return builder.mutation<Product, PostProductOrder>({
    query: (body) => ({ url: '/issuer_products/reorder', method: 'POST', body }),
  })
}
