import React, { useState } from 'react'
import { TEXT_COLORS } from 'export/colors'

type Props = {
  text?: string
}
const CollapseText: React.FC<Props> = ({ text }) => {
  const [expand, setExpand] = useState(false)
  if (text === undefined) return null
  let t = text
  const expandTresh = 100
  const showExpand = !expand && text.length > expandTresh
  if (!expand) {
    t = t.slice(0, expandTresh)
  }
  return (
    <p>
      {t}{' '}
      {showExpand && (
        <span onClick={() => setExpand(true)} style={{ color: TEXT_COLORS.blue, cursor: 'pointer' }}>
          more
        </span>
      )}
    </p>
  )
}

export default CollapseText
