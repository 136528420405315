import React, { useEffect } from 'react'
import { Button, Checkbox, Form, Input, message } from 'antd'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Link, useNavigate } from 'react-router-dom'
import UITexts from 'export/UITexts'
import { api } from 'api/index'
import styles from './Login.module.css'

type LoginForm = {
  username: string
  password: string
}

const Login = () => {
  const navigate = useNavigate()
  const [loginRequest, loginResponse] = api.useLoginMutation()
  const [form] = Form.useForm<LoginForm>()

  const onSubmit = (values: LoginForm) => {
    loginRequest({ username: values.username, password: values.password })
  }

  useEffect(() => {
    if (loginResponse.isError) {
      message.error(UITexts.error.NETWORK_ERROR, 8)
    }
    if (loginResponse.isSuccess) {
      if ('error' in loginResponse.data) {
        message.error(UITexts.login.LOGIN_ERROR, 8)
      } else navigate('/')
    }
  }, [loginResponse])

  return (
    <div className={styles.container}>
      <Form form={form} className={styles.form} name="basic" initialValues={{ remember: true }} onFinish={onSubmit}>
        <Form.Item
          className={styles.form__item}
          name="username"
          rules={[{ required: true, message: 'Please input your username!' }]}>
          <Input autoComplete="on" prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
          style={{ marginBottom: 2 }}>
          <Input.Password
            autoComplete="on"
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder="Password"
          />
        </Form.Item>
        <Link to={'/restore'}>Forgot your password?</Link>
        {/*<div style={{ marginBottom: 20 }}>*/}
        {/*  <Form.Item name="remember" valuePropName="checked" noStyle>*/}
        {/*    <Checkbox>Remember me</Checkbox>*/}
        {/*  </Form.Item>*/}
        {/*</div>*/}
        <Form.Item style={{ marginTop: 20 }}>
          <Button className={styles.login} type="primary" htmlType="submit">
            Log in
          </Button>
          <>
            Or <Link to={'/register'}>Register</Link>
          </>
        </Form.Item>
      </Form>
    </div>
  )
}

export default Login
