import { prettyBool, TEXT_COLORS } from 'export/colors'
import React from 'react'
import { User } from 'typings/entities'

const UserInfoCard = ({ user }: { user: User }) => {
  return (
    <div
      style={{
        borderRadius: 3,
        border: '1px solid rgb(221 221 221)',
        marginTop: 10,
        padding: 10,
        width: 'fit-content',
      }}>
      <h3 style={{ color: TEXT_COLORS.black }}>User info card</h3>
      <p>
        Id: <b>{user.id ?? '-'}</b>
      </p>
      <p>
        Username: <b>{user.username ?? '-'}</b>
      </p>
      <p>
        First name: <b>{user.first_name ?? '-'}</b>
      </p>
      <p>
        Fast name: <b>{user.last_name ?? '-'}</b>
      </p>
      <p style={{ marginBottom: 0 }}>
        Email: <b>{user.email ?? '-'}</b>
      </p>
      <p style={{ marginBottom: 0 }}>
        Qualified purchaser: <b>{prettyBool(user.qualified_purchaser)}</b>
      </p>
      <p style={{ marginBottom: 0 }}>
        Accredited investor: <b>{prettyBool(user.accredited_investor)}</b>
      </p>
    </div>
  )
}
export default UserInfoCard
