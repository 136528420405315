import React, { useState } from 'react'
import { Button, Select } from 'antd'
import { CampaignTemplatePresetFull } from 'typings/entities'
import { api } from 'api/index'
type Props = {
  onSelect: (arg: Promise<{ data: CampaignTemplatePresetFull }>) => void
}
const TemplatePresetSelect: React.FC<Props> = (props) => {
  const templates = api.useGetCampaignTemplatePresetsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  })
  const [getTemplate] = api.useLazyGetCampaignTemplatePresetFullQuery()
  const [selected, setSelected] = useState()
  const ok = () => {
    if (selected) {
      //@ts-ignore
      props.onSelect(getTemplate(selected))
    }
  }
  return (
    <div style={{ display: 'flex', columnGap: 4 }}>
      <Select
        placeholder={'Select template'}
        style={{ width: 200 }}
        value={selected}
        loading={templates.isFetching}
        options={templates.data?.map((p) => ({ label: p.name, value: p.id }))}
        onChange={setSelected}
        // size={'small'}
      />
      <Button
        type={'primary'}
        // size={'small'}
        onClick={ok}
        disabled={!selected}>
        Ok
      </Button>
    </div>
  )
}

export default TemplatePresetSelect
