import React, { useState } from 'react'
import { Button, Card, Checkbox, Form, FormInstance, Input, Select, Upload } from 'antd'
import { PlusOutlined } from '@ant-design/icons/lib'
import { FormListFieldData } from 'antd/es/form/FormList'
import { capitalizeFirstLetter } from '../../utils'
import { CHART_AXIS, CHART_TYPE } from '../../typings/entities'
import { UploadProps } from 'antd/lib/upload/interface'
import { UploadChangeParam } from 'antd/lib/upload'
import { UploadFile } from 'antd/es/upload/interface'
import { CheckboxChangeEvent } from 'antd/es/checkbox'

const FormItemCheckBox = (props: { value?: '0' | '1'; onChange?: (v: '0' | '1') => void }) => {
  const onChange = (v: CheckboxChangeEvent) => props.onChange?.(v.target.checked ? '1' : '0')
  return <Checkbox checked={props.value !== undefined && props.value === '1'} onChange={onChange} />
}

type UploadChartProps = Partial<Omit<UploadProps, 'accept'>> & {
  value?: any
  onChange?: (v: any) => void
}

const UploadChart = ({ value, ...props }: UploadChartProps) => {
  const onChange = (e: UploadChangeParam<UploadFile<unknown>>) => {
    if (e.file.status === 'removed') props.onChange?.(undefined)
    else {
      if ('url' in e.file || e.file.name?.endsWith('.csv')) {
        props.onChange?.(e.file)
      }
    }
  }

  return (
    <Upload accept={'.csv'} fileList={value ? [value] : []} beforeUpload={() => false} onChange={onChange}>
      {value === undefined && <Button icon={<PlusOutlined />}>upload</Button>}
    </Upload>
  )
}

const Chart = ({ field, form }: { field: Omit<FormListFieldData, 'key'>; form: FormInstance }) => {
  const isNew = !('id' in form.getFieldValue(['charts', field.name]))

  return (
    <>
      <Form.Item
        label="Title"
        name={[field.name, 'title']}
        rules={[{ required: true, message: 'Please input title!' }]}>
        <Input />
      </Form.Item>
      <Form.Item
        {...field}
        label="Type"
        name={[field.name, 'chart_type']}
        rules={[{ required: true, message: 'Missing type' }]}>
        <Select disabled={!isNew}>
          <Select.Option value={CHART_TYPE.bar}>{capitalizeFirstLetter(CHART_TYPE.bar)}</Select.Option>
          <Select.Option value={CHART_TYPE.scatter}>{capitalizeFirstLetter(CHART_TYPE.scatter)}</Select.Option>
          <Select.Option value={CHART_TYPE.pie}>{capitalizeFirstLetter(CHART_TYPE.pie)}</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item label="X label" name={[field.name, 'x_label']}>
        <Input />
      </Form.Item>
      <Form.Item
        {...field}
        label="X type"
        name={[field.name, 'x_type']}
        //TODO: dependencies doesn't work
        dependencies={['charts', field.name, 'chart_type']}
        rules={[
          { required: true, message: 'Missing x type' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (getFieldValue(['charts', field.name, 'chart_type']) === CHART_TYPE.pie) {
                if (value === CHART_AXIS.date) {
                  return Promise.reject(new Error('The pie axis of a chart cannot be of type date!'))
                } else return Promise.resolve()
              } else return Promise.resolve()
            },
          }),
        ]}>
        <Select disabled={!isNew}>
          <Select.Option value={CHART_AXIS.string}>{capitalizeFirstLetter(CHART_AXIS.string)}</Select.Option>
          <Select.Option value={CHART_AXIS.float}>Number</Select.Option>
          <Select.Option value={CHART_AXIS.date}>{capitalizeFirstLetter(CHART_AXIS.date)}</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item label="Y label" name={[field.name, 'y_label']}>
        <Input />
      </Form.Item>

      <Form.Item
        {...field}
        label="Y type"
        name={[field.name, 'y_type']}
        //TODO: dependencies doesn't work
        dependencies={['charts', field.name, 'chart_type']}
        rules={[
          { required: true, message: 'Missing y type' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (getFieldValue(['charts', field.name, 'chart_type']) === CHART_TYPE.pie) {
                if (value === CHART_AXIS.date) {
                  return Promise.reject(new Error('The pie axis of a chart cannot be of type date!'))
                } else return Promise.resolve()
              } else return Promise.resolve()
            },
          }),
        ]}>
        <Select disabled={!isNew}>
          <Select.Option value={CHART_AXIS.string}>{capitalizeFirstLetter(CHART_AXIS.string)}</Select.Option>
          <Select.Option value={CHART_AXIS.float}>Number</Select.Option>
          <Select.Option value={CHART_AXIS.date}>{capitalizeFirstLetter(CHART_AXIS.date)}</Select.Option>
        </Select>
      </Form.Item>

      {isNew && (
        <>
          <Form.Item {...field} label="Y Incremental" name={[field.name, 'y_incremental']}>
            <FormItemCheckBox />
          </Form.Item>

          <Form.Item
            label="Data (.csv)"
            extra={
              <div style={{ display: 'flex', columnGap: 4 }}>
                <span>Csv examples:</span>
                <a target={'_blank'} href={process.env.PUBLIC_URL + '/examples/bar_pie.csv'} rel="noreferrer">
                  Bar, Pie,
                </a>

                <a target={'_blank'} href={process.env.PUBLIC_URL + '/examples/scatter.csv'} rel="noreferrer">
                  Scatter
                </a>
              </div>
            }
            name={[field.name, 'data']}
            rules={[
              {
                validator(_, value) {
                  if (value) return Promise.resolve()
                  else return Promise.reject(new Error('Missing chart data'))
                },
              },
            ]}>
            <UploadChart />
          </Form.Item>
        </>
      )}
    </>
  )
}

const FormListCharts = ({ form }: { form: FormInstance }) => {
  return (
    <Form.List name="charts">
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, ...field }) => {
            return (
              <Card
                size={'small'}
                key={key}
                title="Chart"
                style={{ marginBottom: 16 }}
                extra={
                  <Button type={'text'} danger onClick={() => remove(field.name)}>
                    Remove
                  </Button>
                }>
                <Chart field={field} form={form} />
              </Card>
            )
          })}

          <Form.Item>
            <Button
              type="dashed"
              onClick={() => add({ x_type: CHART_AXIS.date, y_type: CHART_AXIS.float, chart_type: CHART_TYPE.scatter })}
              block
              icon={<PlusOutlined />}>
              Add chart
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  )
}

export default FormListCharts
