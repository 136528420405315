import React, { useState } from 'react'
import { api } from 'api/index'
import { Button, message, Spin } from 'antd'
// import AceEditor from 'react-ace'
// import AceEditor from "react-ace-builds";
// import "react-ace-builds/webpack-resolver-min";

import AceEditor from 'react-ace-builds'
import 'react-ace-builds/webpack-resolver-min'

import { ReloadOutlined } from '@ant-design/icons'
// import 'ace-builds/webpack-resolver'
// import 'ace-builds/src-noconflict/mode-html'
import TemplatePresetSelect from 'ui/customFormInputs/TemplatePresetSelect'
// import 'ace-builds/src-noconflict/theme-github'
// import 'ace-builds/src-noconflict/ext-beautify'
// import 'ace-builds/src-noconflict/ext-language_tools'
// import 'ace-builds/src-noconflict/ext-language_tools'
// import 'ace-builds/src-noconflict/ext-language_tools'

type Props = {
  value?: string
  onChange?: (arg: string) => void
  campaignId: number
}

const LetterTemplateInput: React.FC<Props> = (props) => {
  const [preview, setPreview] = useState(false)
  const [getPreview, resp] = api.useGetTemplatePreviewMutation()
  const [getPretty, respPretty] = api.useGetTemplatePrettifyMutation()
  const getHtml = () => {
    if (resp.data) {
      return resp.data!.text
    } else {
      return '<h1>preview error!</h1>'
    }
  }
  console.log('resp', resp)
  return (
    <>
      {preview ? (
        <div>
          <div>
            <Button onClick={() => setPreview(false)}>Edit</Button>
            <Button
              icon={<ReloadOutlined />}
              onClick={() => getPreview({ id: props.campaignId, text: props.value ?? '' })}
            />
          </div>
          <div style={{ height: 600, width: '100%', overflow: 'scroll', paddingTop: 20, position: 'relative' }}>
            {/*<Button onClick={() => getPreview(props.campaignId)}>Preview</Button>*/}
            {resp.isLoading ? <Spin /> : <div dangerouslySetInnerHTML={{ __html: getHtml() }} />}
          </div>
        </div>
      ) : (
        <div style={{ height: 600, width: '100%' }}>
          <div style={{ display: 'flex', columnGap: 8 }}>
            <Button
              loading={respPretty.isLoading}
              onClick={async () => {
                const resp = await getPretty({ text: props.value ?? '' })
                if ('data' in resp) {
                  props.onChange?.(resp.data.text)
                } else {
                  message.warn('Prettify error')
                }
              }}>
              Prettify
            </Button>
            <Button
              onClick={() => {
                setPreview(true)
                getPreview({ id: props.campaignId, text: props.value ?? '' })
              }}>
              Preview
            </Button>
            <TemplatePresetSelect
              onSelect={async (r) => {
                const resp = await r
                console.log(resp)
                if ('data' in resp) {
                  props.onChange?.(resp.data.template)
                }
              }}
            />
          </div>
          <AceEditor
            height={'500px'}
            width={'100%'}
            mode={'html'}
            theme="github"
            onChange={props.onChange}
            value={props.value ?? ''}
            name="UNIQUE_ID_OF_DIV"
            editorProps={{ $blockScrolling: true }}
            setOptions={{
              enableBasicAutocompletion: true,
              enableLiveAutocompletion: true,
              enableSnippets: true,
            }}
          />
        </div>
      )}
    </>
  )
}

export default LetterTemplateInput
