export enum CLIENT_ACTION {
  DELETE = 'DELETE',
  CREATE_ADV_CL = 'CREATE_ADV_CL',
  DELETE_ADV_CL = 'DELETE_ADV_CL',
}

export enum PARAM_TYPE {
  input = 'input',
  slider = 'slider',
  select = 'select',
}

export type WithId<T extends { id: any }> = Pick<T, 'id'> & Partial<T>
