import React, { useState } from 'react'
import ProductForm from './ProductForm'
import styles from './Products.module.scss'
import { api } from 'api/index'
import { useNavigate } from 'react-router-dom'
import { Form, message } from 'antd'
import { useSelector } from '../../store'

const NewProduct = () => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const firmId = useSelector((state) => state.profile.user?.issuer_firm?.id)
  const [postProductRequest] = api.useCreateIssuerProductMutation()
  const [addPictureRequest] = api.useCreateIssuerProductPictureMutation()
  const [addVideoRequest] = api.useCreateIssuerProductVideoMutation()
  const [addAttachmentRequest] = api.useCreateIssuerProductAttachmentMutation()
  const [addChartRequest] = api.useCreateIssuerProductChartMutation()
  const [form] = Form.useForm()

  const postPictures = async (pictures: any[], productId: number) => {
    if (!pictures) return
    const promises: Promise<any>[] = []
    for (let i = 0; i < pictures.length; i++) {
      const picture = pictures[i]
      const formData = new FormData()
      formData.append('file', picture)
      promises.push(
        addPictureRequest({ productId, order: i, body: formData, background_color: picture.background_color }),
      )
    }
    await Promise.all(promises)
  }

  const postVideos = async (videos: any[], productId: number) => {
    if (!videos) return
    const promises: Promise<any>[] = []
    for (let i = 0; i < videos.length; i++) {
      const video = videos[i]
      const formData = new FormData()
      formData.append('file', video.file)
      formData.append('preview', video.preview)
      promises.push(addVideoRequest({ productId, order: i, background_color: video.background_color, body: formData }))
    }
    await Promise.all(promises)
  }

  const postAttachments = async (attachments: any[], productId: number) => {
    if (!attachments) return
    for (let i = 0; i < attachments.length; i++) {
      const attachment = attachments[i]
      const formData = new FormData()
      const file = attachment.originFileObj!
      formData.append('file', file)
      await addAttachmentRequest({ productId, order: i, body: formData })
    }
  }

  const postCharts = async (charts: any[], product: number) => {
    if (!charts) return
    for (let i = 0; i < charts.length; i++) {
      const chart = charts[i]
      const file = chart.data
      delete chart.data
      const formData = new FormData()
      formData.append('file', file)
      const resp = await addChartRequest({ ...chart, product, order: i, body: formData })
      if ('data' in resp && 'error' in resp.data) {
        // @ts-ignore
        const error = resp.data.error
        message.error(
          <>
            <b>Error occurred while adding chart</b>
            <p>
              {chart.title}: {error}
            </p>
          </>,
          15,
        )
        return error
      }
    }
  }

  const onFinish = async ({ pictures, videos, attachments, charts, ...data }: any) => {
    console.log('onFinish', data)
    // return
    setIsLoading(true)
    const productResponse = await postProductRequest(data)
    if ('data' in productResponse) {
      const product = productResponse.data
      await Promise.all([
        postPictures(pictures, product.id),
        postVideos(videos, product.id),
        postAttachments(attachments, product.id),
      ])
      const chartsRequestError = await postCharts(charts, product.id)
      if (chartsRequestError === undefined) navigate('/issuer_products')
      else navigate('/issuer_product/' + product.id)
    }
    setIsLoading(false)
  }

  return (
    <div className={styles.page}>
      <h1 style={{ marginBottom: 30 }}>New Product</h1>
      <div className={styles.form}>
        <ProductForm onFinish={onFinish} form={form} initialValues={{ issuer_firm: firmId! }} isLoading={isLoading} />
      </div>
    </div>
  )
}

export default NewProduct
