import React from 'react'
import { Link } from 'react-router-dom'
import { Result } from 'antd'

const Page404 = () => {
  return (
    <main style={{ padding: '1rem' }}>
      <Result title={'Page not found'} status={'404'} extra={<Link to={'/'}>Return to homepage</Link>} />
    </main>
  )
}

export default Page404
