import { Row, Table } from 'antd'
import React from 'react'
import { ColumnsType } from 'antd/es/table'
import { number2currency, prettyDate } from 'utils'

const columns: ColumnsType<Contact> = [
  // { title: '#', dataIndex: 'pk' },
  { title: 'Account Name', dataIndex: 'account_name' },
  { title: 'First Name', dataIndex: 'first_name' },
  { title: 'Last Name', dataIndex: 'last_name' },
  { title: 'Email', dataIndex: 'email' },
  { title: 'Mobile Phone', dataIndex: 'mobile_phone' },
  { title: 'Household Value', dataIndex: 'household_value' },
  { title: 'Household Cash Value', dataIndex: 'household_cash_value' },
  { title: 'Billing State/Province', dataIndex: 'billing_state_province' },
  { title: 'Billing Zip/Postal Code', dataIndex: 'billing_zip_postal_code' },
  {
    title: 'Last Modified Date',
    dataIndex: 'last_modified_date',
    render: (v) => new Date(v * 1000).toLocaleDateString(),
  },
]

type Contact = {
  account_name: string
  first_name: string
  last_name: string
  email: string
  mobile_phone: string
  household_value: number
  household_cash_value: number
  billing_state_province: string
  billing_zip_postal_code: string
  last_modified_date: number
}

type Props = {
  selected: number[]
  setSelected: (arg: number[]) => void
  dataSource: any[] | undefined
}

const getRender = (k: string) => {
  if (k === 'household_cash_value' || k === 'household_value') {
    return (v: number) => number2currency(v)
  }
}

const ContactTable: React.FC<Props> = (props) => {
  return (
    <div>
      <Row>
        <h3>Contacts</h3>
      </Row>
      <Table
        pagination={false}
        size={'small'}
        dataSource={props.dataSource}
        columns={
          props.dataSource?.[0]
            ? Object.keys(props.dataSource[0]).map((k) => ({ title: k, dataIndex: k, render: getRender(k) }))
            : []
        }
        // rowKey={'account_name'}
        rowSelection={{
          selectedRowKeys: props.selected,
          onChange: (e) => {
            props.setSelected(e as number[])
          },
        }}
        rowKey={'id'}
        scroll={{ x: 'max-content' }}
      />
    </div>
  )
}

export default ContactTable
