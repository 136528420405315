import { api } from 'api/index'
import React, { useState } from 'react'
import { Button, Checkbox, Divider, Image, message, Row, Table } from 'antd'
import { Firm, Product } from 'typings/entities'
import useMySellerFirm from 'hooks/useMySellerFirm'
import { useNavigate } from 'react-router-dom'
import { prettyBool, TEXT_COLORS } from 'export/colors'
import { ColumnsType } from 'antd/es/table'
import IssuerFirmSelect from 'ui/customFormInputs/IssuerFirmSelect'
import { ReloadOutlined } from '@ant-design/icons'
import SellerFirmSelect from 'ui/customFormInputs/SellerFirmSelect'
import useIsSuperuser from 'hooks/useIsSuperuser'
import { imageFallback, thumbUrl } from 'utils'

const columns: ColumnsType<Product> = [
  { dataIndex: 'id', title: '#' },
  {
    dataIndex: 'images',
    title: 'Image',
    render: (v: Product['images']) => {
      return (
        <Image
          src={thumbUrl(v[0]?.url, 100)}
          fallback={imageFallback}
          style={{
            width: 100,
            height: 60,
            objectFit: 'contain',
            background: v[0]?.background_color ? `#${v[0]?.background_color}` : 'white',
          }}
        />
      )
    },
  },
  { dataIndex: 'name', title: 'Name' },
  { dataIndex: 'price', title: 'Price', render: (v: number) => v.toLocaleString() },
  {
    dataIndex: 'issuer_firm',
    title: 'Issuer firm',
    render: (v: Firm) => (
      <p>
        {v.id} {v.name}
      </p>
    ),
  },
  { dataIndex: 'approved', title: 'Approved', render: (v) => prettyBool(v) },
  {
    dataIndex: 'expire_date',
    title: 'Expires',
    render: (v, row) => (v ? new Date(v * 1000).toLocaleDateString() : '-'),
  },
]

const CreateSellerProduct = () => {
  const [approvedOnly, setApprovedOnly] = useState(false)
  const [withExpired, setWithExpired] = useState(false)
  const [issuerFirm, setIssuerFirm] = useState<number | undefined>(undefined)
  const sellerFirm = useMySellerFirm()
  const isSuperUser = useIsSuperuser()
  const [selectedSellerFirm, setSelectedSellerFirm] = useState(sellerFirm?.id)

  const navigate = useNavigate()
  const products = api.useGetIssuerProductsQuery({
    issuer_firm: issuerFirm,
    not_in_seller_firm: selectedSellerFirm,
    approved_only: Number(approvedOnly) as 0 | 1,
    with_expired: Number(withExpired) as 0 | 1,
  })
  const [createSellerProducts, createSellerProductsResp] = api.useCreateSellerProductsMutation()
  console.log(products.data)
  const [selectedProducts, setSelectedProducts] = useState<number[]>([])
  const create = async () => {
    const resp = await createSellerProducts({ products: selectedProducts, seller_firm: selectedSellerFirm })
    console.log('createSellerProducts', resp)
    if ('data' in resp) {
      message.success('Seller products successfully created')
      navigate('/seller_product/all')
    } else {
      message.error('Error occurred while creating seller products')
    }
  }

  return (
    <div>
      <h1>Select issuer products to create seller products</h1>
      <div style={{ display: 'flex', alignItems: 'center', marginLeft: 4, columnGap: 8, marginBottom: 10 }}>
        <span>Approved only:</span>
        <Checkbox
          checked={approvedOnly}
          onChange={(e) => setApprovedOnly(e.target.checked)}
          style={{ marginRight: 4 }}
        />
        <span>Show expired:</span>
        <Checkbox checked={withExpired} onChange={(e) => setWithExpired(e.target.checked)} style={{ marginRight: 4 }} />
        <div style={{ marginRight: 4 }}>
          <span style={{ marginRight: 8 }}>From issuer firm:</span>
          <IssuerFirmSelect
            value={issuerFirm}
            onChange={(e) => {
              setSelectedProducts([])
              setIssuerFirm(e)
            }}
            allowClear
            style={{ width: 160 }}
          />
        </div>
        <div>
          <span style={{ marginRight: 8 }}>For seller firm:</span>
          <SellerFirmSelect
            disabled={!isSuperUser}
            value={selectedSellerFirm}
            onChange={(pk) => {
              setSelectedProducts([])
              setSelectedSellerFirm(pk)
            }}
            style={{ width: 160, marginRight: 16 }}
          />
        </div>
        <ReloadOutlined onClick={() => products.refetch()} spin={products.isFetching} />
      </div>
      <Table
        columns={columns}
        size={'small'}
        dataSource={products.data}
        loading={products.isLoading}
        rowKey={'id'}
        rowSelection={{
          onChange: (e) => setSelectedProducts(e as number[]),
          selectedRowKeys: selectedProducts,
        }}
      />
      <Divider />
      <Row align={'middle'} justify={'end'}>
        <Button onClick={create} disabled={selectedProducts.length === 0} type={'primary'}>
          Create seller products ({selectedProducts.length})
        </Button>
      </Row>
    </div>
  )
}

export default CreateSellerProduct
