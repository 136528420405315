import React from 'react'
import { Routes, Route, Link, Navigate } from 'react-router-dom'
import { Result } from 'antd'

import Login from 'pages/Login/Login'
import { useSelector } from 'store'
import Campaigns from 'pages/Campaigns/Campaigns'
import AddCampaign from 'pages/Campaigns/AddCampaign'
import CampaignPage from 'pages/Campaigns/CampaignPage'
import Register from 'pages/Login/Register'
import ImportClients from 'pages/ImportClients/ImportClients'
import EditProduct from './pages/IssuerProducts/EditProduct'
import Products from './pages/IssuerProducts/Products'
import NewProduct from './pages/IssuerProducts/NewProduct'
import Profile from 'pages/Profile/Profile'
import CreateImportedClient from 'pages/ImportedClient/CreateImportedClient'
import CreateSellerProduct from 'pages/SellerProduct/CreateSellerProduct'
import SellerProducts from 'pages/SellerProduct/SellerProducts'
import Subscribes from 'pages/Subscribe/Subscribes'
import SellerProduct from 'pages/SellerProduct/SellerProduct'
import SubscribeStatus from 'pages/SubscribeStatus/SubscribeStatus'
import SubscribeStatuses from 'pages/SubscribeStatus/SubscribeStatuses'
import CreateSubscribeStatus from 'pages/SubscribeStatus/CreateSubscribeStatus'
import Settings from 'pages/Settings'
import ImportedClientTags from 'pages/ImportedClientTag/ImportedClientTags'
import CreateImportedClientTag from 'pages/ImportedClientTag/CreateImportedClientTag'
import ImportedClientTag from 'pages/ImportedClientTag/ImportedClientTag'
import CreateSellerFirm from 'pages/SellerFirm/CreateSellerFirm'
import SellerFirm from 'pages/SellerFirm/SellerFirm'
import CreateIssuerFirm from 'pages/IssuerFirm/CreateIssuerFirm'
import IssuerFirm from 'pages/IssuerFirm/IssuerFirm'
import ImportedClients from 'pages/ImportedClient/ImportedClients'
import { User } from 'typings/entities'
import Page404 from 'pages/Page404'
import ImportedClient from 'pages/ImportedClient/ImportedClient'
import Analytic from 'pages/Analytic/Analytic'
import CreateStaffUser from 'pages/CreateStaffUser/CreateStaffUser'
import Restore from 'pages/Login/Restore'
import Subscribe from 'pages/Subscribe/Subscribe'

const loginRoutes = [
  { path: '/login', Component: Login },
  { path: '/register', Component: Register },
  { path: '/restore', Component: Restore },
]

const baseRoutes = [
  { path: '/', Component: () => <Navigate to={'/profile'} /> },
  { path: '/profile', Component: Profile },
  { path: '/seller_firm/new', Component: CreateSellerFirm },
  { path: '/seller_firm', Component: SellerFirm },
  { path: '/issuer_firm/new', Component: CreateIssuerFirm },
  { path: '/issuer_firm', Component: IssuerFirm },
]

const sellerRoutes = [
  { path: '/', Component: () => <Navigate to={'/dashboard'} /> },
  { path: '/seller_firm/new', Component: CreateSellerFirm },
  { path: '/seller_firm', Component: SellerFirm },
  { path: '/issuer_firm/new', Component: CreateIssuerFirm },
  { path: '/issuer_firm', Component: IssuerFirm },
  { path: '/campaigns', Component: Campaigns },
  { path: '/campaign/new', Component: AddCampaign },
  { path: '/campaign/:id', Component: CampaignPage },
  { path: '/import_clients', Component: ImportClients },
  { path: '/imported_client/new', Component: CreateImportedClient },
  { path: '/imported_client/:id', Component: ImportedClient },
  { path: '/imported_client/all', Component: ImportedClients },
  { path: '/profile', Component: Profile },
  { path: '/seller_product/new', Component: CreateSellerProduct },
  { path: '/seller_product/all', Component: SellerProducts },
  { path: '/seller_product/:id', Component: SellerProduct },
  { path: '/subscribes/all', Component: Subscribes },
  { path: '/subscribe/:id', Component: Subscribe },
  { path: '/subscribe_status/all', Component: SubscribeStatuses },
  { path: '/subscribe_status/new', Component: CreateSubscribeStatus },
  { path: '/subscribe_status/:id', Component: SubscribeStatus },
  { path: '/imported_client_tag/all', Component: ImportedClientTags },
  { path: '/imported_client_tag/new', Component: CreateImportedClientTag },
  { path: '/imported_client_tag/:id', Component: ImportedClientTag },
  { path: '/settings', Component: Settings },
  { path: '/analytic', Component: Analytic },
]

const issuerRoutes = [
  { path: '/', Component: () => <Navigate to={'/issuer_products'} /> },
  { path: '/profile', Component: Profile },
  { path: '/issuer_firm', Component: IssuerFirm },
  { path: '/seller_firm/new', Component: CreateSellerFirm },
  { path: '/issuer_products', Component: Products },
  { path: '/issuer_product/:id', Component: EditProduct },
  { path: '/new_product', Component: NewProduct },
]

const getRoutes = (user?: User) => {
  console.log('user', user)
  if (user && user.email_confirmed === false) {
    return {
      currentRoutes: [],
      Fallback: () => (
        <main style={{ padding: '1rem' }}>
          <Result title={'Confirmation link was sent to your email address'} />
        </main>
      ),
    }
  }
  let currentRoutes = loginRoutes
  if (user !== undefined) {
    currentRoutes = baseRoutes
    const isIssuer = user.issuer_firm !== null
    const isSeller = user.seller_firm !== null
    if (isIssuer && isSeller) {
      currentRoutes = [...issuerRoutes, ...sellerRoutes]
    } else if (isIssuer) {
      currentRoutes = issuerRoutes
    } else if (isSeller) {
      currentRoutes = sellerRoutes
    }

    if (user.is_superuser) {
      currentRoutes.push({ path: '/create_user', Component: CreateStaffUser })
    }
  }

  return { currentRoutes, Fallback: () => (user?.token ? <Page404 /> : <Navigate to={'/login'} />) }
}

const Router = () => {
  const user = useSelector((store) => store.profile.user)
  // eslint-disable-next-line prefer-const
  let { currentRoutes, Fallback } = getRoutes(user)

  if (user && user.is_staff === false) {
    // currentRoutes = loginRoutes
    return (
      <main style={{ padding: '1rem' }}>
        <Result title={'Only staff accounts have access to admin interface'} />
      </main>
    )
  } else {
    return (
      <Routes>
        {currentRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
        <Route
          path="/confirmation_success"
          element={
            <main style={{ padding: '1rem' }}>
              <Result title={'Email confirmed'} extra={<Link to={'/'}>Return to homepage</Link>} />
            </main>
          }
        />
        <Route
          path="/confirmation_failed"
          element={
            <main style={{ padding: '1rem' }}>
              <Result title={'Email confirm error'} extra={<Link to={'/'}>Return to homepage</Link>} />
            </main>
          }
        />

        <Route path="*" element={<Fallback />} />
      </Routes>
    )
  }
}

export default Router
