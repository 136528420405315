import React, { CSSProperties } from 'react'
import { Select } from 'antd'
import { api } from 'api/index'

type Props = {
  onChange?: (id: number) => void
  value?: number
  style?: CSSProperties
  allowClear?: boolean
  disabled?: boolean
}

const SellerFirmSelect: React.FC<Props> = (props) => {
  const sellerFirms = api.useGetSellerFirmsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  })
  return (
    <Select
      disabled={props.disabled}
      options={sellerFirms.data?.map((sf) => ({ value: sf.id, label: `#${sf.id} ${sf.name}` }))}
      value={props.value}
      onChange={props.onChange}
      loading={sellerFirms.isFetching}
      style={props.style}
      allowClear={props.allowClear}
      placeholder={'Select seller firm'}
    />
  )
}

export default SellerFirmSelect
