import React from 'react'
import { Select, SelectProps } from 'antd'
import { api } from 'api/index'

const ClientSourceSelect: React.FC<SelectProps> = (props) => {
  const sources = api.useGetImportedClientSourcesQuery()
  return (
    <Select
      loading={sources.isLoading}
      options={sources.data?.map((s) => ({ value: s.id, label: s.name }))}
      {...props}
    />
  )
}

export default ClientSourceSelect
