import React from 'react'
import { Campaign } from '../typings/entities'

const Text = {
  error: {
    NETWORK_ERROR: 'Network error',
  },
  login: {
    LOGIN_SUCCESS: 'You were successfully logged in',
    LOGIN_ERROR: 'Invalid credentials',
  },
  register: {
    ERROR: 'Registration error',
    EMAIL_IS_ALREADY_REGISTERED: 'This email is already registered',
  },
  account: {
    SELLER_FIRM_EMPTY: 'Your account does not associated to any seller firm',
  },
  user: {
    UPDATE_SUCCESS: 'User updated',
    UPDATE_ERROR: 'Error occurred while updating User',
  },
  SubscribeStatus: {
    CREATE_SUCCESS: 'Subscribe Status successfully created',
    CREATE_ERROR: 'Error occurred while creating Subscribe Status',
    UPDATE_SUCCESS: 'Subscribe Status successfully updated',
    UPDATE_ERROR: 'Error occurred while updating Subscribe Status',
    DELETE_ERROR: 'Error occurred while deleting Subscribe Status',
    DELETE_SUCCESS: 'Subscribe Status successfully deleted',
  },
  Subscribe: {
    CREATE_SUCCESS: 'Subscribe successfully created',
    CREATE_ERROR: 'Error occurred while creating Subscribe',
    UPDATE_SUCCESS: 'Subscribe successfully updated',
    UPDATE_ERROR: 'Error occurred while updating Subscribe',
    DELETE_ERROR: 'Error occurred while deleting Subscribe',
    DELETE_SUCCESS: 'Subscribe successfully deleted',
    NOT_FOUND: 'Subscribe not found',
    UNARCHIVE_SUCCESS: 'Subscribe successfully unarchived',
    UNARCHIVE_ERROR: 'Error occurred while unarchived Subscribe',
  },
  ImportedClientTag: {
    CREATE_SUCCESS: 'Imported Client Tag successfully created',
    CREATE_ERROR: 'Error occurred while creating Imported Client Tag',
    UPDATE_SUCCESS: 'Imported Client Tag successfully updated',
    UPDATE_ERROR: 'Error occurred while updating Imported Client Tag',
    DELETE_ERROR: 'Error occurred while deleting Imported Client Tag',
    DELETE_SUCCESS: 'Imported Client Tag successfully deleted',
  },
  ImportedClient: {
    CREATE_SUCCESS: 'Imported client successfully created',
    UPDATE_SUCCESS: 'Imported client successfully updated',
    IMPORT_SUCCESS: (count) => `Clients are successfully imported (${count})`,
    CREATE_ERROR: 'Error occurred while creating imported client',
    DELETE_ERROR: 'Error occurred while deleting imported client',
    DELETE_MANY_SUCCESS: 'Imported clients deleted',
    NOT_FOUND: 'Imported client is not found',
  },
  Campaign: {
    CREATE_SUCCESS: 'Campaign successfully created',
    CREATE_ERROR: 'Error occurred while creating Campaign',
    UPDATE_SUCCESS: 'Campaign successfully updated',
    UPDATE_ERROR: 'Error occurred while updating Campaign',
    DELETE_ERROR: 'Error occurred while deleting Campaign',
    DELETE_SUCCESS: 'Campaign successfully deleted',
  },
  SellerFirm: {
    CREATE_SUCCESS: 'Seller Firm successfully created',
    CREATE_ERROR: 'Error occurred while creating Seller Firm',
    UPDATE_SUCCESS: 'Seller Firm successfully updated',
    UPDATE_ERROR: 'Error occurred while updating Seller Firm',
    DELETE_ERROR: 'Error occurred while deleting Seller Firm',
    DELETE_SUCCESS: 'Seller Firm successfully deleted',
    NOT_FOUND: 'Seller Firm is not found',
  },
  SellerProduct: {
    CREATE_SUCCESS: 'Seller Product successfully created',
    CREATE_ERROR: 'Error occurred while creating Seller Product',
    UPDATE_SUCCESS: 'Seller Product successfully updated',
    UPDATE_ERROR: 'Error occurred while updating Seller Product',
    DELETE_ERROR: 'Error occurred while deleting Seller Product',
    DELETE_SUCCESS: 'Seller Product successfully deleted',
    NOT_FOUND: 'Seller Product is not found',
  },
  IssuerProduct: {
    CREATE_SUCCESS: 'Issuer Product successfully created',
    CREATE_ERROR: 'Error occurred while creating Issuer Product',
    UPDATE_SUCCESS: 'Issuer Product successfully updated',
    UPDATE_ERROR: 'Error occurred while updating Issuer Product',
    DELETE_ERROR: 'Error occurred while deleting Issuer Product',
    DELETE_SUCCESS: 'Issuer Product successfully deleted',
    NOT_FOUND: 'Issuer Product is not found',
    UPDATE_ORDER_SUCCESS: 'Order updated',
    UPDATE_ORDER_ERROR: 'Error occurred while updating Order',
  },
  IssuerFirm: {
    CREATE_SUCCESS: 'Issuer Firm successfully created',
    CREATE_ERROR: 'Error occurred while creating Issuer Firm',
    UPDATE_SUCCESS: 'Issuer Firm successfully updated',
    UPDATE_ERROR: 'Error occurred while updating Issuer Firm',
    DELETE_ERROR: 'Error occurred while deleting Issuer Firm',
    DELETE_SUCCESS: 'Issuer Firm successfully deleted',
    NOT_FOUND: 'Issuer Firm is not found',
  },
}

export default Text
