import React from 'react'
import style from './Products.module.scss'
import {
  Button,
  Collapse,
  DatePicker,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Select,
  Spin,
  Switch,
  Tooltip,
} from 'antd'
import { api } from 'api/index'
import Params from './Params'
import { Category, Firm, Product } from 'typings/entities'
import FormListCharts from './FormListCharts'
import moment, { Moment } from 'moment'
import UploadPicture from '../../components/Upload/UploadPicture/UploadPicture'
import { useSelector } from 'store'
import UploadAttachments from '../../components/Upload/UploadAttachments/UploadAttachments'
import UploadVideos from 'components/Upload/UploadVideos/UploadVideos'
const { Option } = Select
const { TextArea } = Input

const FormItemDatePicker = (props: any) => {
  const onChange = (v: Moment | null) => props?.onChange(v?.unix())
  return (
    <DatePicker value={props.value && moment(props.value * 1000)} onChange={(v) => onChange(v)} format={'MM/DD/YYYY'} />
  )
}

type InitialValues = Partial<
  Omit<Product, 'categories' | 'pictures' | 'issuer_firm'> & {
    categories: number
    pictures: any[]
    attachments: any[]
    charts: any[]
    issuer_firm: number
  }
>

type ContainerProps = {
  onFinish: (values: any) => Promise<void>
  form: FormInstance
  initialValues?: InitialValues
  isLoading: boolean
}

type Props = ContainerProps & {
  categories: Category[]
  firms: Firm[]
}

const ProductForm = ({ form, initialValues = {}, categories, isLoading, ...props }: Props) => {
  const profile = useSelector((store) => store.profile)
  if (initialValues.price_name === undefined) initialValues.price_name = 'Min. Investment'

  const onFinish = (values: any) => {
    props.onFinish(values)
  }

  return (
    <Form
      form={form}
      initialValues={initialValues}
      labelCol={{ flex: '140px' }}
      labelAlign={'left'}
      onFinish={onFinish}
      autoComplete="off">
      <Form.Item label="Firm" name="issuer_firm">
        <Select disabled={profile?.user?.is_superuser === false}>
          {props.firms?.map((f) => (
            <Option key={f.id} value={f.id}>
              {f.name}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please input name!' }]}>
        <Input />
      </Form.Item>

      <Form.Item label="Approved" name="approved" valuePropName={'checked'}>
        <Switch />
      </Form.Item>
      <Tooltip placement="topLeft" title={'Apparently only for an accredited investor'}>
        <Form.Item label="Accredited investor" name="accredited_investor" valuePropName={'checked'}>
          <Switch />
        </Form.Item>
      </Tooltip>
      <Tooltip placement="topLeft" title={'Apparently only for an qualified purchaser'}>
        <Form.Item label="Qualified purchaser" name="qualified_purchaser" valuePropName={'checked'}>
          <Switch />
        </Form.Item>
      </Tooltip>

      <Form.Item label="Order" name="order">
        <InputNumber />
      </Form.Item>

      <Form.Item label="Price label" name="price_name">
        <Select>
          <Option value={'Price'}>Price</Option>
          <Option value={'Min. Investment'}>Min. Investment</Option>
          <Option value={'Daily Rent Price'}>Daily Rent Price</Option>
        </Select>
      </Form.Item>

      <Form.Item label="Price" name="price">
        <InputNumber addonBefore={'$'} style={{ width: '100%' }} controls={false} />
      </Form.Item>

      <Form.Item label="Categories" name="categories" rules={[{ required: true, message: 'Please select category!' }]}>
        <Select options={categories?.map((c) => ({ label: c.name, value: c.id }))} />
      </Form.Item>

      <Form.Item label="Pictures" name="pictures">
        <UploadPicture multiple={true} backColorInput />
      </Form.Item>

      <Form.Item label="Video" name="videos">
        <UploadVideos backColorInput={true} />
      </Form.Item>

      <Form.Item label="Attachments" name="attachments">
        <UploadAttachments />
      </Form.Item>

      <Form.Item label="Description" name="description">
        <TextArea rows={4} />
      </Form.Item>

      <Form.Item label="Short description" name="short_description">
        <TextArea rows={2} />
      </Form.Item>

      <Collapse style={{ fontWeight: 'bold', marginBottom: 24 }}>
        <Collapse.Panel key={1} header={'Charts'} className={style.collapsePanel} forceRender>
          <FormListCharts form={form} />
        </Collapse.Panel>
      </Collapse>

      <Collapse style={{ fontWeight: 'bold', marginBottom: 24 }}>
        <Collapse.Panel key={2} header={'Params'} className={style.collapsePanel} forceRender>
          <Params form={form} />
        </Collapse.Panel>
      </Collapse>

      <Form.Item label="Expire date" name="expire_date">
        <FormItemDatePicker />
      </Form.Item>

      <Form.Item label="Capacity available" name="capacity_available">
        <InputNumber style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={isLoading} style={{ paddingLeft: 34, paddingRight: 34 }}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  )
}

const ProductFormContainer = (props: ContainerProps) => {
  const { data: categories } = api.useGetCategoriesQuery(undefined, { refetchOnMountOrArgChange: true })
  const { data: firms } = api.useGetIssuerFirmsQuery()

  if (categories === undefined || firms === undefined) return <Spin />
  else return <ProductForm {...props} categories={categories} firms={firms} />
}

export default ProductFormContainer
