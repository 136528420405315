import { Button, Card, Popconfirm, Tooltip } from 'antd'
import React from 'react'
import { DeleteOutlined, EditOutlined, UserDeleteOutlined } from '@ant-design/icons/lib'
import { Firm } from '../../typings/entities'
import noImg from '../../assets/img/no_image.png'
import useIsSuperuser from 'hooks/useIsSuperuser'

type Props = {
  firm: Firm | null
  onCreate: () => void
  onEdit: () => void
  onLeave: () => void
  onDelete: (id: number) => void
}

const FirmCard = ({ firm, ...props }: Props) => {
  const superUser = useIsSuperuser()
  if (firm === null && !superUser) return <p>Your account dont have permissions to create firm</p>
  if (firm === null) return <Button onClick={props.onCreate}>Create firm</Button>
  else {
    return (
      <Card
        style={{ width: 300 }}
        cover={
          <img
            alt="Firm avatar"
            src={firm.avatar_url ?? noImg}
            style={{ height: 200, objectFit: 'contain', background: '#EEEEEE' }}
          />
        }
        actions={[
          <Tooltip placement="bottom" title={'Edit firm'} key="edit" mouseEnterDelay={1}>
            <EditOutlined onClick={props.onEdit} style={{ color: 'var(--color-primary)' }} />
          </Tooltip>,
          <Tooltip placement="bottom" title={'Leave'} key="leave" mouseEnterDelay={1}>
            <Popconfirm
              title={
                <div>
                  <p>Are you sure to leave firm?</p>
                </div>
              }
              onConfirm={props.onLeave}>
              <UserDeleteOutlined style={{ color: 'red' }} />
            </Popconfirm>
          </Tooltip>,
          <Tooltip placement="bottom" title={'Delete firm'} key="delete" mouseEnterDelay={1}>
            <Popconfirm
              title={
                <div>
                  <p>Are you sure to delete?</p>
                  <b>
                    <p>ALL FIRM PRODUCTS WILL BE DELETED</p>
                  </b>
                </div>
              }
              onConfirm={() => props.onDelete(firm.id!)}>
              <DeleteOutlined style={{ color: 'red' }} />
            </Popconfirm>
          </Tooltip>,
        ]}>
        <Card.Meta title={firm.name} description={firm.description} />
      </Card>
    )
  }
}

export default FirmCard
