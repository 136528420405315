import { TreeSelect } from 'antd'
import { api } from 'api'
import React, { useState } from 'react'
type PropsType = {
  selectedCategories: undefined | number[]
  value?: any
  onChange?: (arg: any) => void
}
const SelectProducts: React.FC<PropsType> = ({ selectedCategories, value, onChange }) => {
  const [name, setName] = useState('')
  const { data: products, isLoading } = api.useGetIssuerProductsQuery({
    name,
    categories: selectedCategories?.toString(),
  })
  const treeData = products
    ?.map((item) => ({
      title: item.categories[0].name,
      value: 'CATEGORY->' + item.categories[0].id,
      children: products
        ?.filter((p) => p.categories[0].id === item.categories[0].id)
        .map(({ id, name }) => ({ title: name, value: id })),
    }))
    .filter((a, i) => products.findIndex((s) => Number(a.value.replace('CATEGORY->', '')) === s.categories[0].id) === i)
  // console.log('treeData', treeData)
  const tProps = {
    treeData,
    treeCheckable: true,
    multiple: true,
    placeholder: 'Product',
    treeNodeFilterProp: 'title',
    // treeDefaultExpandAll: true,
  }
  return <TreeSelect {...tProps} value={value} onChange={onChange} onSearch={setName} loading={isLoading} />
  //disabled={!selectedCategories}
}
export default SelectProducts
