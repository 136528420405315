import React, { useState } from 'react'
import { useSelector } from 'store'
import { Button, Form, Input, message, Result, Row, Switch } from 'antd'
import { useNavigate } from 'react-router-dom'
import { api } from 'api/index'
import FirmCard from './FirmCard'
import { User } from 'typings/entities'
import UITexts from 'export/UITexts'
import md5 from 'md5'
import { PASSWORD_SALT } from 'api/endpoints/profileEndpoints'

const Profile = () => {
  const navigate = useNavigate()
  const user = useSelector((store) => store.profile.user)
  const [deleteSellerFirmRequest] = api.useDeleteSellerFirmMutation()
  const [leaveSellerFirmRequest] = api.useDeleteSellerFirmSellerMutation()
  const [deleteIssuerFirmRequest] = api.useDeleteIssuerFirmMutation()
  const [leaveIssuerFirmRequest] = api.useDeleteIssuerFirmCreatorMutation()
  const [updateUser, resp] = api.useUpdateUserMutation()
  const [changePassword, passwordResp] = api.useChangePasswordMutation()
  const [form] = Form.useForm<User & { password?: string }>()

  const onOk = async () => {
    const values = await form.validateFields()
    if (!values) return
    const resp = await updateUser({
      first_name: values.first_name,
      last_name: values.last_name,
      username: values.username,
      qualified_purchaser: values.qualified_purchaser,
      accredited_investor: values.accredited_investor,
    })
    if ('data' in resp) {
      message.success(UITexts.user.UPDATE_SUCCESS)
    } else {
      message.error(UITexts.user.UPDATE_SUCCESS)
    }
  }
  const [p, setP] = useState('')
  const [p2, setP2] = useState('')
  const canUpdate = () => {
    if (!p || !p2) return false
    if (p !== p2) return false
    if (p.length < 4 || p2.length < 4) return false
    return true
  }
  const updatePassword = async () => {
    const resp = await changePassword({ password: md5(p + PASSWORD_SALT) })
    if ('error' in resp) {
      message.error('Error occurred while updating password')
    } else {
      message.success('Password updated')
    }
  }

  if (!user) return <Result status={'404'} title={'User empty'} />
  else {
    return (
      <div>
        <h1>Profile</h1>
        <Form
          form={form}
          initialValues={user}
          labelCol={{ flex: '140px' }}
          labelAlign={'left'}
          style={{ marginBottom: 48 }}>
          <Form.Item name={'username'} label={'Username'}>
            <Input />
          </Form.Item>
          <Form.Item name={'first_name'} label={'First name'}>
            <Input />
          </Form.Item>
          <Form.Item name={'last_name'} label={'Last name'}>
            <Input />
          </Form.Item>
          <Form.Item name={'email'} label={'Email'} rules={[{ required: true }]}>
            <Input disabled />
          </Form.Item>
          <Form.Item name={'qualified_purchaser'} label={'Qualified purchaser'} valuePropName={'checked'}>
            <Switch />
          </Form.Item>
          <Form.Item name={'accredited_investor'} label={'Accredited investor'} valuePropName={'checked'}>
            <Switch />
          </Form.Item>
          <Row justify={'end'}>
            <Button type={'primary'} onClick={onOk} loading={resp.isLoading}>
              Ok
            </Button>
          </Row>
        </Form>
        <h2>Change password:</h2>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
          <Input.Password value={p} onChange={(e) => setP(e.target.value)} style={{ width: 200 }} />
          <Input.Password value={p2} onChange={(e) => setP2(e.target.value)} style={{ width: 200 }} />
          <Button
            disabled={!canUpdate()}
            style={{ width: 200 }}
            type={'primary'}
            onClick={updatePassword}
            loading={passwordResp.isLoading}>
            Save
          </Button>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <div style={{ flex: '1 0 0', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h3 style={{ textAlign: 'center' }}>Issuer firm</h3>
            <FirmCard
              firm={user.issuer_firm}
              onEdit={() => navigate('/issuer_firm')}
              onCreate={() => navigate('/issuer_firm/new')}
              onLeave={() => leaveIssuerFirmRequest(user.id!)}
              onDelete={(id: number) => deleteIssuerFirmRequest(id)}
            />
          </div>
          <div style={{ flex: '1 0 0', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h3 style={{ textAlign: 'center' }}>Seller firm</h3>
            <FirmCard
              firm={user.seller_firm}
              onEdit={() => navigate('/seller_firm')}
              onCreate={() => navigate('/seller_firm/new')}
              onLeave={() => leaveSellerFirmRequest(user.id!)}
              onDelete={(id: number) => deleteSellerFirmRequest(id)}
            />
          </div>
        </div>
        {/*<pre>{JSON.stringify(user, undefined, '\r\n')}</pre>*/}
      </div>
    )
  }
}

export default Profile
