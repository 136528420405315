import { Select } from 'antd'
import useMySellerFirm from 'hooks/useMySellerFirm'
import { api } from 'api/index'
import React, { CSSProperties } from 'react'

type Props = {
  value?: number[]
  onChange?: (arg: number[]) => void
  mode: 'multiple' | undefined
  style?: CSSProperties
}

const SellerProductSelect: React.FC<Props> = ({ mode, ...props }) => {
  const sellerFirm = useMySellerFirm()
  const products = api.useGetSellerProductsQuery(
    { seller_firm: sellerFirm?.id },
    {
      refetchOnMountOrArgChange: true,
    },
  )
  return (
    <Select
      allowClear
      showSearch
      options={products.data?.map((p) => ({ value: p.id, label: `${p.id} ${p.name}` }))}
      loading={products.isLoading}
      value={props.value}
      onChange={props.onChange}
      mode={mode}
      style={props.style}
      placeholder={'Select seller product'}
    />
  )
}

export default SellerProductSelect
