import { User } from 'typings/entities'
import { BuilderType } from 'api/index'

export const getSellerFirmSellers = (builder: BuilderType) =>
  builder.query<User[], void>({
    query: () => ({ url: '/seller_firm/advisors' }),
    providesTags: [{ id: 'all', type: 'SellerFirmSellers' }],
  })
export const createSellerFirmSeller = (builder: BuilderType) =>
  builder.mutation<{ status: string }, { email: string }>({
    query: (data) => ({ url: '/seller_firm/advisor', method: 'POST', body: data }),
    invalidatesTags: (resp) => (resp ? [{ id: 'all', type: 'SellerFirmSellers' }] : []),
  })
export const deleteSellerFirmSeller = (builder: BuilderType) =>
  builder.mutation<{ status: string }, number>({
    query: (id) => ({ url: '/seller_firm/advisor/' + id, method: 'DELETE' }),
    invalidatesTags: (resp) => (resp ? [{ id: 'all', type: 'SellerFirmSellers' }] : []),
  })
