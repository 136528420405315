import React, { CSSProperties, useState } from 'react'
import { useDispatch } from 'react-redux'
import { BorderOutlined, FolderOpenOutlined, MinusOutlined, ReloadOutlined } from '@ant-design/icons'
import { api } from 'api/index'
import useMySellerFirm from 'hooks/useMySellerFirm'
import KanbanBoard, { BoardColumn } from 'components/KanbanBoard/KanbanBoard'
import KanbanModal from 'pages/Kanban/components/KanbanModal'
import { kanbanActions } from 'store/reducers/kanbanReducer'
import { Subscribe } from 'typings/entities'
import { JSONCopy } from 'utils'
import styles from './Kanban.module.css'
import Circle from 'pages/Kanban/components/Circle'
import { useSelector } from '../../store'
import { Navigate } from 'react-router-dom'
import { DatePicker, Input, Radio, Row } from 'antd'
import useDebounce from '../../hooks/useDebounce'
import moment, { Moment } from 'moment'
import Archived from 'components/Archived/Archived'

const FiltersCollapse: React.FC<{ style?: CSSProperties }> = (props) => {
  const [show, setShow] = useState(false)
  return (
    <div style={props.style}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h3 style={{ marginBottom: 0 }}>Filters</h3>
        {show ? <MinusOutlined onClick={() => setShow(false)} /> : <BorderOutlined onClick={() => setShow(true)} />}
      </div>
      {show ? props.children : null}
    </div>
  )
}

const Kanban = () => {
  const user = useSelector((state) => state.profile.user)
  const [fetchDisabled, setFetchDisabled] = useState(false)
  const [isAllClients, setIsAllClients] = useState(false)
  const sellerFirm = useMySellerFirm()
  const subscribeStatuses = api.useGetSubscribeStatusesQuery(
    { seller_firm: sellerFirm?.id },
    { skip: !sellerFirm || fetchDisabled },
  )
  if (subscribeStatuses.fulfilledTimeStamp && subscribeStatuses.startedTimeStamp) {
    console.log('sub status req time: ', subscribeStatuses.fulfilledTimeStamp - subscribeStatuses.startedTimeStamp)
  }

  const skipSubscribes = !sellerFirm || fetchDisabled || subscribeStatuses.data === undefined
  const subscribes = api.useGetSubscribesQuery(
    { seller_firm: sellerFirm?.id, is_archived: 0, advisor: isAllClients ? undefined : user?.id },
    { skip: skipSubscribes, pollingInterval: 5000 },
  )

  const [mutate] = api.useUpdateSubscribeMutation()

  const [search, setSearch] = useState('')
  const filterSearch = useDebounce(search, 500)

  const [dtFilter, setDtFilter] = useState<[Moment, Moment] | null>(null)

  const lanes2: BoardColumn[] = JSONCopy(subscribeStatuses.data ?? [])
    .sort((a, b) => a.order - b.order)
    .map((ss) => ({
      id: ss.id,
      name: ss.name,
      cards: (subscribes.data ?? [])
        .filter((s) => s.status === ss.id && !s.is_archived)
        .filter(
          (s) =>
            s.user.first_name.toLowerCase().includes(filterSearch.toLowerCase()) ||
            s.user.last_name.toLowerCase().includes(filterSearch.toLowerCase()) ||
            s.user.email.toLowerCase().includes(filterSearch.toLowerCase()),
        )
        .filter(
          (s) =>
            !dtFilter ||
            (moment(s.update_at * 1000).date() >= dtFilter[0].date() &&
              moment(s.update_at * 1000).date() <= dtFilter[1].date()),
        )
        .map((s) => ({
          id: s.id,
          subscribe: s,
        })),
    }))

  const dispatch = useDispatch()
  const onCardClick = (subscribe: Subscribe) => {
    dispatch(kanbanActions.setModal({ cardModalData: { subscribe }, cardModalVisible: true }))
  }

  const onToggleAllClients = () => {
    setIsAllClients((c) => !c)
  }

  return (
    <div className={styles.main}>
      <KanbanBoard
        columns={lanes2}
        onCardClick={onCardClick}
        onCardMouseDown={() => setFetchDisabled(true)}
        onCardMouseUp={() => setFetchDisabled(false)}
        onCardMoved={(from, to, cardid) => {
          mutate({ id: cardid, data: { status: to }, seller_firm: sellerFirm?.id })
          setFetchDisabled(false)
        }}
      />
      <div className={styles.botBlock}>
        <Radio.Group
          size={'large'}
          value={isAllClients ? 'allClients' : 'myClients'}
          buttonStyle={'solid'}
          style={{ marginLeft: 16 }}>
          <Radio.Button value={'allClients'} onClick={onToggleAllClients}>
            All clients
          </Radio.Button>
          <Radio.Button value={'myClients'} onClick={onToggleAllClients}>
            My clients
          </Radio.Button>
        </Radio.Group>
        <FiltersCollapse
          style={{
            padding: 8,
            borderRadius: 4,
            background: 'white',
            border: '1px solid #b1afaf',
            width: 300,
          }}>
          <Row style={{ marginBottom: 8 }}>
            <span>User:</span>
            <Input value={search} onChange={(e) => setSearch(e.target.value)} allowClear size={'small'} />
          </Row>
          <Row>
            <span>Date:</span>
            <DatePicker.RangePicker
              size={'small'}
              value={dtFilter}
              onChange={(e) => {
                console.log(e)
                //@ts-ignore
                setDtFilter(e)
              }}
              allowClear
            />
          </Row>
        </FiltersCollapse>
        <Archived isAllClients={isAllClients} />
        <ReloadOutlined spin style={{ opacity: subscribes.isFetching ? 1 : 0 }} />
        <Circle color={fetchDisabled ? 'rgba(255,0,0,0.4)' : 'rgba(59,152,59,0.4)'} />
      </div>
    </div>
  )
}

const KanbanContainer = () => {
  const user = useSelector((store) => store.profile.user)
  if (!user) {
    return <Navigate to={'/login'} />
  }
  return (
    <>
      <style>
        {`
      .ant-layout-footer {
        display: none !important;
      }
      `}
      </style>
      <Kanban />
      <KanbanModal />
    </>
  )
}

export default KanbanContainer
