import { api } from 'api'
import { Button, message } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import UITexts from 'export/UITexts'
import React from 'react'

const DeleteCampaignButton = ({ id, onDelete }: { id: number; onDelete?: () => void }) => {
  const [d] = api.useDeleteCampaignMutation()
  return (
    <Button
      size={'small'}
      type={'primary'}
      danger
      icon={<DeleteOutlined />}
      onClick={() => {
        d(id).then(() => {
          message.success(UITexts.Campaign.DELETE_SUCCESS)
          onDelete?.()
        })
      }}
    />
  )
}

export default DeleteCampaignButton
