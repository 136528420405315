import { TreeSelect } from 'antd'
import { api } from 'api'
import React, { useEffect, useState } from 'react'
import styles from './SelectState.module.scss'

type PropsType = {
  value?: string[]
  onChange?: (arg: string[]) => void
}
const SelectState: React.FC<PropsType> = ({ value, onChange }) => {
  const [v, sv] = useState<string[] | undefined>(value)
  const countryCode = api.useGetCountryCodeQuery()
  useEffect(() => {
    sv(value)
  }, [value])
  // console.log('countryCode', countryCode)
  // console.log('countryCode.data', countryCode.data)

  const treeData = countryCode?.data
    ?.map((e) => {
      if (e.country === 'RU' || e.country === 'ZA') return null
      return {
        title: e.country,
        value: e.country,
        key: e.country,
        children: e.states.map((s) => {
          return { title: s, value: s, key: s }
        }),
      }
    })
    .filter((e) => e) as any

  const tProps = {
    treeData,
    treeCheckable: true,
    multiple: true,
    placeholder: 'US.NY',
    // treeDefaultExpandAll: true,
  }
  // return null
  return (
    <TreeSelect
      {...tProps}
      value={v}
      loading={countryCode.isLoading}
      onChange={(e) => {
        sv(e)
        onChange?.(e)
      }}
    />
  )
}

export default SelectState
