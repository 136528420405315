import React from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Button, Form, Input, message, Result, Row, Spin } from 'antd'
import { api } from 'api/index'
import UITexts from 'export/UITexts'
import { ImportedClientTag } from 'typings/entities'

const ImportedClientTagPage = () => {
  const p = useParams()
  const id = p.id === undefined ? undefined : parseInt(p.id)

  const [form] = Form.useForm<ImportedClientTag>()
  const subscribeStatus = api.useGetImportedClientTagQuery(id!, { skip: !id })
  const [mutate, resp] = api.useUpdateImportedClientTagMutation()
  const navigate = useNavigate()
  const onOk = async () => {
    if (!id) return
    const values = await form.validateFields()
    console.log(values)

    if (!values) return
    const resp = await mutate({ id: id, data: values })
    console.log(resp)
    return
    if (resp) {
      message.success(UITexts.ImportedClientTag.CREATE_SUCCESS)

      navigate('/imported_client_tag/all')
    } else {
      message.error(UITexts.ImportedClientTag.CREATE_ERROR)
    }
  }
  if (subscribeStatus.isError) {
    return <Result title={<p>Imported Client Tag with id {id} not found</p>} status={'404'} />
  }
  if (!subscribeStatus.data) {
    return <Spin />
  }
  return (
    <div>
      <Row justify={'space-between'} align={'middle'}>
        <h1>Imported Client Tag #{id}</h1>
        <Link to={'/imported_client_tag/all'}>All tags</Link>
      </Row>
      <Form form={form} initialValues={{ ...subscribeStatus.data }}>
        <Form.Item name={'name'} label={'Name'} rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Button htmlType={'submit'} onClick={onOk} loading={resp.isLoading}>
          Ok
        </Button>
      </Form>
    </div>
  )
}

export default ImportedClientTagPage
