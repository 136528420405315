import { BuilderType } from '../index'
import { AdvisorRole, Role } from 'typings/entities'

export const getRoles = (builder: BuilderType) => {
  return builder.query<Role[], void>({
    query: () => ({ url: '/roles' }),
    providesTags: [{ id: 'All', type: 'Role' }],
  })
}

export const createAdvisorRole = (builder: BuilderType) => {
  return builder.mutation<AdvisorRole, { advisor: number; role: number }>({
    query: (body) => ({ url: '/advisor_role', method: 'POST', body }),
  })
}
