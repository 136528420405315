import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type AppState = {
  themeColor: string
  selectedFundId: undefined | number
}

const initialState: AppState = {
  themeColor: '#1890ff',
  selectedFundId: undefined,
}

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setThemeColor: (state, action) => {
      state.themeColor = action.payload
    },
    setSelectedFundId: (state, action) => {
      state.selectedFundId = action.payload
    },
  },
})

export const { actions: appActions, reducer: appReducer } = slice
