import React from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { api } from 'api/index'
import { Button, DatePicker, Form, Input, message, Result, Row, Spin } from 'antd'
import TagsSelect from 'ui/customFormInputs/TagsSelect'
import SellerProductSelect from 'ui/customFormInputs/SellerProductSelect'
import UITexts from 'export/UITexts'
import moment, { Moment } from 'moment'
import { SellerProduct } from 'typings/entities'
import { useSelector } from 'store'
import useMySellerFirm from 'hooks/useMySellerFirm'
import ImportedClientSelect from 'ui/customFormInputs/ImportedClientSelect'

const AddCampaign = () => {
  const navigate = useNavigate()
  const [mutate] = api.useCreateCampaignMutation()
  const [form] = Form.useForm()
  const user = useSelector((store) => store.profile.user)
  const onOk = async () => {
    const values = await form.validateFields()
    if (!values) return
    console.log('values', values)
    // return
    if (values.advisor_group === undefined) {
      delete values.advisor_group
    }
    // return
    const resp = await mutate(values)
    console.log(resp)
    if ('data' in resp) {
      console.log(resp)
      message.success(UITexts.Campaign.CREATE_SUCCESS)
      navigate('/campaign/' + resp.data.id)
    } else {
      message.error(UITexts.Campaign.CREATE_ERROR)
    }
  }
  if (!user) return <Result title={'Your account is not allowed to create new campaign'} status={'403'} />
  if (!user.seller_firm)
    return (
      <Result
        title={
          <p>
            Your account is not connected to any <b>Seller firm</b>
          </p>
        }
        status={'403'}
      />
    )
  console.log('user', user)
  return (
    <div>
      <Row align={'middle'} justify={'space-between'}>
        <h1>Create new Campaign</h1>
        <Link to={'/campaigns'}>Back</Link>
      </Row>
      <Form
        form={form}
        initialValues={{
          advisor: user.id,
          seller_firm: user.seller_firm.id,
          dt_from: moment().format('YYYY-MM-DD'),
          dt_to: moment().add(1, 'day').format('YYYY-MM-DD'),
        }}>
        <Form.Item name={'name'} label={'Name'} rules={[{ message: 'Please enter name!', required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
          name={'dt_from'}
          label={'Date from'}
          getValueProps={(v) => ({ value: moment(v) })}
          getValueFromEvent={(e: Moment) => e.format('YYYY-MM-DD')}>
          <DatePicker />
        </Form.Item>
        <Form.Item
          name={'dt_to'}
          label={'Date to'}
          getValueProps={(v) => ({ value: moment(v) })}
          getValueFromEvent={(e: Moment) => e.format('YYYY-MM-DD')}>
          <DatePicker />
        </Form.Item>
        <Form.Item
          name={'template'}
          label={'Template'}
          help={<p style={{ color: 'grey', fontWeight: 'bold' }}>You have to create campaign to edit its template</p>}>
          <Input disabled />
        </Form.Item>

        {/*<Form.Item name={'template'} label={'Template'}>*/}
        {/*  <Input.TextArea />*/}
        {/*</Form.Item>*/}
        <Form.Item name={'advisor'} label={'Advisor'} style={{ display: 'none' }}>
          <Input disabled />
        </Form.Item>
        <Form.Item name={'advisor_group'} label={'Advisor group'}>
          <Input disabled />
        </Form.Item>
        {/*<Form.Item name={'seller_firm'} label={'seller_firm'}>*/}
        {/*  <Input disabled />*/}
        {/*</Form.Item>*/}
        <Form.Item name={'client_tags'} label={'Client tags'}>
          <TagsSelect />
        </Form.Item>
        <Form.Item name={'products'} label={'Products'}>
          <SellerProductSelect mode={'multiple'} />
        </Form.Item>
        <Form.Item name={'clients'} label={'Clients'}>
          <ImportedClientSelect />
        </Form.Item>

        <Row justify={'end'}>
          <Button type={'primary'} onClick={onOk}>
            Ok
          </Button>
        </Row>
      </Form>
    </div>
  )
}

export default AddCampaign
