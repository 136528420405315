import { Form, Result, message, Button } from 'antd'
import React from 'react'
import UITexts from 'export/UITexts'
import { api } from 'api/index'
import { Link, useNavigate } from 'react-router-dom'
import useMyIssuerFirm from 'hooks/useMyIssuerFirm'
import Creators from './Creators'
import FirmForm, { FirmFormType } from '../../components/FirmForm'

const IssuerFirmPage = () => {
  const navigate = useNavigate()
  const issuerFirm = useMyIssuerFirm()
  const [mutate, resp] = api.useUpdateIssuerFirmMutation()
  const [postAvatar, { isLoading: avatarLoading }] = api.useCreateIssuerFirmAvatarMutation()
  const [postBackground, { isLoading: backgroundLoading }] = api.useCreateIssuerFirmBackgroundMutation()
  const [deleteRequest, { isLoading: deleteLoading }] = api.useDeleteIssuerFirmMutation()
  const [form] = Form.useForm<FirmFormType>()
  const isLoading = Boolean(resp.isLoading || deleteLoading || avatarLoading || backgroundLoading)

  const onSubmit = async () => {
    if (!issuerFirm) return
    const values = await form.validateFields().catch(() => null)
    if (values === null) return
    const avatar = values.avatar
    delete values.avatar
    const background = values.background
    delete values.background

    const resp = await mutate({ ...values, id: issuerFirm.id })
    if ('data' in resp) {
      if (avatar && !('url' in avatar)) {
        const formData = new FormData()
        formData.append('file', avatar)
        await postAvatar({ id: resp.data.id, body: formData })
      }
      if (background && !('url' in background)) {
        const formData = new FormData()
        formData.append('file', background)
        await postBackground({ id: resp.data.id, body: formData })
      }
      message.success(UITexts.IssuerFirm.UPDATE_SUCCESS)
    } else {
      message.error(UITexts.IssuerFirm.UPDATE_ERROR)
    }
  }

  const onDelete = async () => {
    if (!issuerFirm) return
    await deleteRequest(issuerFirm.id)
    navigate('/profile')
  }

  if (!issuerFirm) {
    return (
      <Result
        status={'404'}
        title={UITexts.IssuerFirm.NOT_FOUND}
        extra={<Link to={'/issuer_firm/new'}>Create new issuer Firm</Link>}
      />
    )
  } else {
    return (
      <div>
        <h1>Edit Issuer firm</h1>
        <FirmForm
          form={form}
          initialValues={{
            ...issuerFirm,
            avatar: issuerFirm.avatar_url ? { url: issuerFirm.avatar_url } : undefined,
            background: issuerFirm.background_url ? { url: issuerFirm.background_url } : undefined,
          }}
        />
        <div style={{ display: 'flex', marginBottom: 48 }}>
          <Button type={'primary'} onClick={onSubmit} loading={isLoading}>
            Save
          </Button>
          <Button
            onClick={onDelete}
            danger
            loading={isLoading}
            block
            style={{ width: 'fit-content', marginLeft: 'auto' }}>
            Delete
          </Button>
        </div>
        <Creators />
      </div>
    )
  }
}

export default IssuerFirmPage
