import React, { useState } from 'react'
import { Button, Col, message, Row } from 'antd'
import ImportContactsButton from './components/ImportContactsButton'
import ContactTable from './components/ContactTable'
import { ImportedClient } from 'typings/entities'
import { useNavigate } from 'react-router-dom'
import TagsSelect from 'ui/customFormInputs/TagsSelect'
import { api } from 'api/index'
import UITexts from 'export/UITexts'
import SellerFirmSelect from 'ui/customFormInputs/SellerFirmSelect'
import useMySellerFirm from 'hooks/useMySellerFirm'
import useIsSuperuser from 'hooks/useIsSuperuser'

const ImportContacts = () => {
  const navigate = useNavigate()

  const isSuperUser = useIsSuperuser()
  const sellerFirm = useMySellerFirm()
  const [selectedSellerFirm, setSelectedSellerFirm] = useState(sellerFirm?.id)

  const [selected, setSelected] = useState<number[]>([])
  const [dataSource, setDataSource] = useState<ImportedClient[]>()
  const [selectedTags, setSelectedTags] = useState<number[]>([])
  const [mutate, resp] = api.useCreateImportedClientsMutation()
  const [errors, setErrors] = useState<(ImportedClient & { error: string })[]>([])

  const save = async () => {
    setErrors([])
    if (!selectedSellerFirm) {
      message.error(UITexts.account.SELLER_FIRM_EMPTY)
      return
    }
    const clients = dataSource!
      .filter((row) => selected.includes(row.id))
      .map(({ id, ...rest }) => ({ ...rest })) as ImportedClient[]
    const resp = await mutate({
      clients,
      seller_firm_id: selectedSellerFirm,
      tag_ids: selectedTags,
    })

    console.log('resp', resp)
    if ('data' in resp) {
      if (resp.data.errors.length === 0) {
        message.success(UITexts.ImportedClient.IMPORT_SUCCESS(resp.data.imported_count))
        navigate('/imported_client/all')
      } else {
        message.info(`Error occurred while importing some contacts (${resp.data.errors.length})`)
        setErrors(resp.data.error_rows.map((r, i) => ({ ...r, error: resp.data.errors[i] })))
        setDataSource(resp.data.error_rows.map((ee, i) => ({ ...ee, id: i + 1 })) as ImportedClient[])
        // let newSelected = selected.filter(
        //     (id) =>
        //       !dataSource?.filter((cl) => !resp.data.error_rows.map((cc) => cc.email).includes(cl.email)).includes(id),
        //   ),
        setSelected(resp.data.error_rows.map((ee, i) => i + 1))
      }
    } else {
      message.error(UITexts.ImportedClient.CREATE_ERROR)
    }
  }

  return (
    <div>
      <Row justify={'space-between'}>
        <h1>Import contacts</h1>
        <Button
          type={'primary'}
          onClick={() => {
            navigate('/imported_client/new')
          }}>
          New contact
        </Button>
        {/*<div style={{ display: 'grid', gridGap: 4, gridTemplateColumns: '1fr 1fr 1fr' }}>*/}
        {/* */}
        {/*</div>*/}
      </Row>
      <Row justify={'center'}>
        <Col span={8} style={{ height: 'fit-content' }}>
          {/*//@ts-ignore*/}
          <ImportContactsButton
            onUploadEnd={(e) => {
              setDataSource(e.map((ee, i) => ({ id: i + 1, ...ee })) as ImportedClient[])
              setSelected(e.map((_, i) => i + 1))
            }}
          />
        </Col>
      </Row>
      <ContactTable dataSource={dataSource} selected={selected} setSelected={setSelected} />
      {errors.length > 0 && <h3 style={{ color: 'red', marginTop: 20 }}>Some clients were not imported!</h3>}
      {errors.map((e, i) => (
        <div key={i}>
          <div style={{ width: '100%', display: 'flex', columnGap: 4 }}>
            <span>{e.account_name}</span>
            <span>{e.first_name}</span>
            <span>{e.last_name}</span>
            <span>{e.email}</span>
            <span>{e.mobile_phone}</span>
            <span>{e.household_value}</span>
            <span>{e.household_cash_value}</span>
            <span>{e.billing_state}</span>
            <span>{e.zip_code}</span>
            <span>{e.last_modified_date}</span>
          </div>
          <p style={{ color: 'red', fontSize: '12px' }}>{e.error}</p>
        </div>
      ))}
      <Row justify={'end'} style={{ columnGap: 8, marginTop: 10 }}>
        <div>
          <span style={{ marginRight: 8 }}>For seller firm:</span>
          <SellerFirmSelect
            disabled={!isSuperUser}
            value={selectedSellerFirm}
            onChange={setSelectedSellerFirm}
            style={{ width: 200, marginRight: 16 }}
          />
        </div>
        <TagsSelect value={selectedTags} onChange={setSelectedTags} />
        <Button
          type={'primary'}
          disabled={dataSource === undefined || selected.length === 0}
          onClick={save}
          loading={resp.isLoading}>
          Save
        </Button>
      </Row>
    </div>
  )
}

export default ImportContacts
