import React from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { api } from 'api'
import { Button, Form, message, Result, Row, Spin, Switch } from 'antd'
import { Subscribe as SubscribeType } from 'typings/entities'
import { skipToken } from '@reduxjs/toolkit/query'
import UITexts from 'export/UITexts'
import styles from './Subscribe.module.css'

const Subscribe = () => {
  const p = useParams<{ id: string }>()
  const navigate = useNavigate()
  const id = p.id !== undefined ? parseInt(p.id) : undefined

  const subscribe = api.useGetSubscribeQuery(id ?? skipToken)
  const [mutate, resp] = api.useUpdateSubscribeMutation()
  const [form] = Form.useForm<Partial<SubscribeType>>()

  if (subscribe.isLoading) {
    return <Spin />
  }

  if (!subscribe.data) {
    return <Result title={UITexts.Subscribe.NOT_FOUND} extra={<Link to={'/subscribes/all'}>All subscribes</Link>} />
  }

  const product = subscribe.data.product
  const u = subscribe.data.user
  const onFinish = async (values: Partial<SubscribeType>) => {
    console.log('values', values)
    // return
    if (!values) return
    const resp = await mutate({ id: id!, data: values })
    console.log('resp', resp)
    //@ts-ignore
    if ('error' in resp.data) {
      message.error(UITexts.Subscribe.UPDATE_ERROR)
    } else {
      message.success(UITexts.Subscribe.UPDATE_SUCCESS)
      navigate('/subscribes/all')
    }
  }
  return (
    <div>
      <Row align={'middle'} justify={'space-between'}>
        <h1>Edit subscribe</h1>
        <Link to={'/subscribes/all'}>All subscribes</Link>
      </Row>
      <div className={styles.formRow}>
        <span className={styles.formLabel}>Product:</span>
        <Link to={'/seller_product/' + product.id}>
          {product.id} {product.name}
        </Link>
      </div>
      <div className={styles.formRow}>
        <span className={styles.formLabel}>User:</span>
        <b>{u.username}</b>
      </div>
      <Form
        form={form}
        initialValues={subscribe.data}
        onFinish={onFinish}
        labelCol={{ flex: '100px' }}
        labelAlign={'left'}>
        <Form.Item name={'is_subscribed'} label={'Subscribed'} valuePropName={'checked'}>
          <Switch />
        </Form.Item>
        <Form.Item name={'is_archived'} label={'Archived'} valuePropName={'checked'}>
          <Switch />
        </Form.Item>
        <Row justify={'end'}>
          <Button htmlType={'submit'} type={'primary'} loading={resp.isLoading}>
            OK
          </Button>
        </Row>
      </Form>
    </div>
  )
}

export default Subscribe
