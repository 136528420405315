import React from 'react'
import { ColumnsType } from 'antd/es/table'
import { SubscribeStatus } from 'typings/entities'
import { Link } from 'react-router-dom'
import { Button, Row, Table } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { store } from 'store'
import { api } from 'api/index'
import useMySellerFirm from 'hooks/useMySellerFirm'
import UpdateSubscribeStatusOrder from 'pages/SubscribeStatus/components/UpdateSubscribeStatusOrder'

const columns: ColumnsType<SubscribeStatus> = [
  { dataIndex: 'id', title: '#' },
  { dataIndex: 'name', title: 'Name', render: (v, row) => <Link to={'/subscribe_status/' + row.id}>{v}</Link> },
  { dataIndex: 'name_for_user', title: 'Name for client' },
  // { dataIndex: 'color', title: 'Color' },
  {
    dataIndex: 'order',
    title: 'Order',
    sorter: (a, b) => a.order - b.order,
    defaultSortOrder: 'ascend',
    render: (order, row) => <UpdateSubscribeStatusOrder data={row} />,
  },
  {
    dataIndex: 'seller_firm',
    title: 'Actions',
    render: (v, row) => {
      return (
        <Row justify={'center'}>
          <Button
            size={'small'}
            type={'primary'}
            danger
            icon={<DeleteOutlined />}
            onClick={() => {
              //@ts-ignore
              store.dispatch(api.endpoints.deleteSubscribeStatus.initiate(row.id))
            }}
          />
        </Row>
      )
    },
  },
]

const SubscribeStatuses = () => {
  const subscribeStatuses = api.useGetSubscribeStatusesAllQuery({}, { refetchOnMountOrArgChange: true })
  return (
    <div>
      <h1 style={{ marginBottom: 30 }}>Subscribe statuses</h1>
      <div style={{ display: 'flex', marginBottom: 16 }}>
        <Button onClick={subscribeStatuses.refetch} type={'primary'}>
          Reload
        </Button>
        <Button type={'primary'} style={{ marginLeft: 'auto' }}>
          <Link to={'/subscribe_status/new'}>New subscribe status</Link>
        </Button>
      </div>
      <Table
        size={'small'}
        loading={subscribeStatuses.isFetching}
        columns={columns}
        dataSource={subscribeStatuses.data}
        rowKey={'id'}
      />
    </div>
  )
}

export default SubscribeStatuses
