import React from 'react'
import { Button, Form, Input, InputNumber, message, Result } from 'antd'
import { useNavigate } from 'react-router-dom'
import useMySellerFirm from 'hooks/useMySellerFirm'
import UITexts from 'export/UITexts'
import FormValueString from 'ui/customFormInputs/FormValueString'
import { SubscribeStatus } from 'typings/entities'
import { api } from 'api/index'

const CreateSubscribeStatus = () => {
  const [form] = Form.useForm<SubscribeStatus>()
  const sellerFirm = useMySellerFirm()
  const [mutate, resp] = api.useCreateSubscribeStatusMutation()
  const navigate = useNavigate()
  const onOk = async () => {
    const values = await form.validateFields()
    if (!values) return
    const resp = await mutate(values)
    if (resp) {
      message.success(UITexts.SubscribeStatus.CREATE_SUCCESS)

      navigate('/subscribe_status/all')
    } else {
      message.error(UITexts.SubscribeStatus.CREATE_ERROR)
    }
  }
  if (!sellerFirm)
    return (
      <Result
        title={
          <p>
            Your account is not connected to any <b>Seller firm</b>
          </p>
        }
        status={'403'}
      />
    )
  return (
    <Form
      form={form}
      initialValues={{
        seller_firm: sellerFirm?.id,
      }}>
      <Form.Item name={'name'} label={'Name'} rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name={'color'} label={'Color'} rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name={'order'} label={'Order'} rules={[{ required: true }]}>
        <InputNumber />
      </Form.Item>
      <Form.Item name={'seller_firm'} label={'Seller firm'} style={{ display: 'none' }}>
        <FormValueString />
      </Form.Item>
      <Button htmlType={'submit'} onClick={onOk} loading={resp.isLoading}>
        Ok
      </Button>
    </Form>
  )
}

export default CreateSubscribeStatus
