import React, { useCallback, useState } from 'react'
import { BoardCard } from 'components/KanbanBoard/KanbanBoard'
// import styles from 'pages/Kanban/components/CustomCard.module.css'
import { DeleteOutlined, FolderAddOutlined } from '@ant-design/icons'
import { store } from 'store'
import { api } from 'api/index'
import { Link } from 'react-router-dom'
import styles from './KanbanBoard.module.css'
import { Subscribe } from 'typings/entities'
import { prettyDate } from 'utils'
import classNames from 'classnames'
import { ReactComponent as Archive } from 'assets/img/archive.svg'
import archive from 'assets/img/archive.svg'
import { Dropdown, Menu, Popconfirm } from 'antd'
import { useStore } from 'react-redux'

const Card: React.FC<{
  card: BoardCard
  parentColumn: number
  onCardClick: (subscribe: Subscribe) => void
  onCardMouseDown: () => void
  onCardMouseUp: () => void
}> = (props) => {
  // console.log('props', props)
  const items = [
    {
      label: 'Archive',
      icon: <FolderAddOutlined className={styles.cardContextItemIcon} />,
      onClick: (id: number) => {
        store.dispatch(
          //@ts-ignore
          api.endpoints.updateSubscribe.initiate({ id: id, data: { is_archived: true } }),
        )
      },
    },
    {
      label: 'Delete',
      icon: <DeleteOutlined className={styles.cardContextItemIcon} />,
      onClick: (id: number) => {
        //@ts-ignore
        store.dispatch(api.endpoints.deleteSubscribe.initiate(id))
      },
    },
  ]
  const menu = ({ id }: { id: number }) => (
    <Menu className={styles.cardContextItemsContainer}>
      {items.map((e) => (
        <Menu.Item key={e.label} className={styles.cardContextItemContainer} onClick={() => e.onClick(id)}>
          <div className={styles.cardContextItem}>
            {e.icon}
            <p>{e.label}</p>
          </div>
        </Menu.Item>
      ))}
    </Menu>
  )

  return (
    <Dropdown overlay={menu({ id: props.card.subscribe.id })} trigger={['contextMenu']}>
      <div
        onMouseDown={props.onCardMouseDown}
        onMouseUp={props.onCardMouseUp}
        onClick={() => {
          console.log('card click')
          props.onCardClick(props.card.subscribe)
        }}
        onDragStart={(e) => {
          e.dataTransfer.setData('text/plain', props.card.id.toString() + '-' + props.parentColumn.toString())
        }}
        className={classNames(styles.card, !props.card.subscribe.is_subscribed ? styles.unsubscribedCard : null)}
        id={props.card.id.toString()}
        draggable={'true'}>
        <div className={styles.cardHead}>
          <Popconfirm
            title={'Are you sure to archive this subscription?'}
            onCancel={(e) => {
              e?.stopPropagation()
            }}
            onConfirm={(e) => {
              e?.stopPropagation()
              store.dispatch(
                //@ts-ignore
                api.endpoints.updateSubscribe.initiate({ id: props.card.subscribe.id, data: { is_archived: true } }),
              )
            }}>
            <FolderAddOutlined
              className={styles.delete}
              onClick={(e) => {
                e.stopPropagation()
              }}
            />
          </Popconfirm>
          {!props.card.subscribe.is_subscribed && (
            <div className={styles.unsubscribed}>
              <p>Unsubscribed</p>
            </div>
          )}
          <h2 className={styles.cardTitle}>
            {props.card.subscribe.user.first_name ?? '-'} {props.card.subscribe.user.last_name ?? '-'}
          </h2>
        </div>
        <div className={styles.cardBody}>
          {/*<h1>Custom card</h1>*/}
          {/*<p className={styles.cardText}>id: {props.card.id}</p>*/}
          <p className={styles.cardText}>
            User: <b>{props.card.subscribe.user.email}</b>
          </p>
          <p className={styles.cardText}>
            Product: <b>{props.card.subscribe.product.name}</b>
            {/*<Link to={'/seller_product/' + props.card.subscribe.product.id}>{}</Link>*/}
          </p>
          <p className={styles.cardText} style={{ color: 'grey' }}>
            {prettyDate(props.card.subscribe.update_at)}
          </p>

          {/*<p>status: {props.subscribe.status}</p>*/}
          {/*<pre>{JSON.stringify(props, undefined, ' ')}</pre>*/}
        </div>
      </div>
    </Dropdown>
  )
}

export default Card
