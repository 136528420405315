import { ImportedClient, ImportedClientSource } from 'typings/entities'
import { WithId } from 'typings/dataStructures'
import { BuilderType } from 'api/index'
import ImportedClients from '../../pages/ImportedClient/ImportedClients'

type CreateImportedClientsResp = {
  imported_count: number
  errors_count: number
  error_rows: ImportedClient[]
  errors: string[]
  matched_clients_existing: ImportedClient[]
  matched_clients_imported: ImportedClient[]
}
type CreateImportedClientsRequest = {
  clients: ImportedClient[]
  tag_ids: number[]
  seller_firm_id: number
}

export const getImportedClients = (builder: BuilderType) =>
  builder.query<ImportedClient[], { seller_firm_id?: number }>({
    query: (params) => ({ url: '/imported_clients', params }),
    providesTags: (result) => {
      if (result) {
        return [
          { id: 'all', type: 'ImportedClient' },
          ...result.map((c): { id: number; type: 'ImportedClient' } => ({ id: c.id, type: 'ImportedClient' })),
        ]
      }
      return []
    },
  })
export const createImportedClients = (builder: BuilderType) =>
  builder.mutation<CreateImportedClientsResp, CreateImportedClientsRequest>({
    query: (arg) => ({
      url: '/imported_clients',
      method: 'POST',
      body: arg,
    }),
    invalidatesTags: (result) => {
      if (result) {
        return [{ id: 'all', type: 'ImportedClient' }]
      }
      return []
    },
  })
export const deleteImportedClients = (builder: BuilderType) =>
  builder.mutation<{}, { ids: number[] }>({
    query: (body) => ({ url: '/imported_clients', method: 'DELETE', body }),
    invalidatesTags: (result, er, { ids }) => {
      if (result) {
        return [
          { id: 'all', type: 'ImportedClient' },
          ...ids.map((id): { id: number; type: 'ImportedClient' } => ({ id, type: 'ImportedClient' })),
        ]
      }
      return []
    },
  })

export const getImportedClient = (builder: BuilderType) =>
  builder.query<ImportedClient, number>({
    query: (id) => ({ url: '/imported_client/' + id }),
    providesTags: (result) => {
      console.log('result!!', result)
      if (!result) return []
      return [{ id: result.id, type: 'ImportedClient' }]
    },
  })
export const getImportedClient2 = (builder: BuilderType) =>
  builder.query<ImportedClient, { seller_firm: number; user: number }>({
    query: (params) => ({ url: '/imported_client', params }),
    providesTags: (result) => {
      if (!result) return []
      return [{ id: result.id, type: 'ImportedClient' }]
    },
  })

export const createImportedClient = (builder: BuilderType) =>
  builder.mutation<ImportedClient, Partial<ImportedClient>>({
    query: (body) => ({ url: '/imported_client', method: 'POST', body }),
    invalidatesTags: (result) => {
      if (result) {
        return [{ id: 'all', type: 'ImportedClient' }]
      }
      return []
    },
  })
export const updateImportedClient = (builder: BuilderType) =>
  builder.mutation<ImportedClient, WithId<ImportedClient>>({
    query: ({ id, ...body }) => ({ url: '/imported_client/' + id, method: 'PATCH', body }),
    invalidatesTags: (result) => {
      if (!result) return []
      return [
        { id: 'all', type: 'ImportedClient' },
        { id: result.id, type: 'ImportedClient' },
      ]
    },
  })
export const deleteImportedClient = (builder: BuilderType) =>
  builder.mutation<{}, number>({
    query: (id) => ({ url: '/imported_client/' + id, method: 'DELETE' }),
    invalidatesTags: (result, er, id) => {
      if (result) {
        return [
          { id: 'all', type: 'ImportedClient' },
          { id, type: 'ImportedClient' },
        ]
      }
      return []
    },
  })

export const getAdvisorClients = (builder: BuilderType) =>
  builder.query<ImportedClient[], { advisorId: number }>({
    query: (params) => ({ url: 'https://wealthback.splinex.com/api/advisors_clients?advisor=' + params.advisorId }),
    providesTags: (result) => {
      if (result) {
        return [
          { id: 'all', type: 'AdvisorClients' },
          ...result.map((c): { id: number; type: 'AdvisorClients' } => ({ id: c.id, type: 'AdvisorClients' })),
        ]
      }
      return []
    },
  })
export const deleteAdvisorClient = (builder: BuilderType) =>
  builder.mutation<void, { advisor: number; client: number }>({
    query: (params) => ({
      url: 'https://wealthback.splinex.com/api/advisors_clients',
      method: 'DELETE',
      params,
    }),
    invalidatesTags: (result) => {
      if (result) {
        return [{ id: 'all', type: 'AdvisorClients' }]
      }
      return []
    },
  })
export const createAdvisorClient = (builder: BuilderType) =>
  builder.mutation<{ advisor: number; client: number; id: number }, { advisor: number; client: number }>({
    query: (body) => ({ url: '/advisors_clients', method: 'POST', body }),
    invalidatesTags: (result) => {
      if (result) {
        return [{ id: 'all', type: 'AdvisorClients' }]
      }
      return []
    },
  })

export const getImportedClientSources = (builder: BuilderType) =>
  builder.query<ImportedClientSource[], void>({
    query: () => ({ url: '/imported_client/sources' }),
    providesTags: (result) => {
      if (result) {
        return [
          { id: 'all', type: 'ImportedClientSource' },
          ...result.map((c): { id: number; type: 'ImportedClientSource' } => ({
            id: c.id,
            type: 'ImportedClientSource',
          })),
        ]
      }
      return []
    },
  })
