import React from 'react'
import { ColumnsType } from 'antd/es/table'
import { ImportedClientTag } from 'typings/entities'
import { Link } from 'react-router-dom'
import { Button, Table } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { api } from 'api/index'
import useMySellerFirm from 'hooks/useMySellerFirm'
import { store } from 'store'

const columns: ColumnsType<ImportedClientTag> = [
  { dataIndex: 'id', title: '#' },
  { dataIndex: 'name', title: 'Name', render: (v, row) => <Link to={'/imported_client_tag/' + row.id}>{v}</Link> },
  {
    dataIndex: 'actions',
    title: 'Actions',
    render: (v, row) => {
      return (
        <Button
          size={'small'}
          type={'primary'}
          danger
          icon={<DeleteOutlined />}
          onClick={() => {
            //@ts-ignore
            store.dispatch(api.endpoints.deleteImportedClientTag.initiate(row.id))
          }}
        />
      )
    },
  },
]

const ImportedClientTags = () => {
  const sellerFirm = useMySellerFirm()
  const tags = api.useGetImportedClientTagsQuery(
    { seller_firm_id: sellerFirm!.id },
    { skip: !sellerFirm, refetchOnMountOrArgChange: true },
  )
  return (
    <div>
      <h1 style={{ marginBottom: 30 }}>Imported client tags</h1>
      <div style={{ display: 'flex', marginBottom: 16 }}>
        <Button onClick={tags.refetch} type={'primary'}>
          Reload
        </Button>
        <Button type={'primary'} style={{ marginLeft: 'auto' }}>
          <Link to={'/imported_client_tag/new'}>New Tag</Link>
        </Button>
      </div>
      <Table size={'small'} loading={tags.isFetching} columns={columns} dataSource={tags.data} rowKey={'id'} />
    </div>
  )
}

export default ImportedClientTags
