import { AnalyticPaginationType, AnalyticRequest, AnalyticResponse, BuilderType, PaginationResp } from 'api/index'
import { AnalyticFilterPresetType, Statistic, CountryCode, AnalyticUserWatch } from 'typings/entities'
import { Pagination } from 'antd'

export const getAnalyticUser = (builder: BuilderType) =>
  builder.query<
    { pagination: { page: number; limit: number; page_count: number }; statistic: Statistic[] },
    {
      userId?: number
      params: {
        limit?: number
        page?: number
        order_by?: '-create_at'
        seller_firm?: number
        category?: number
        seller_product?: number
      }
    }
  >({
    query: ({ userId, params }) => ({ url: '/analytic/user' + (userId ? `/${userId}` : ''), params }),
  })
type AnalyticUserWatchRequest = {
  user_id?: number
  limit?: number
  page?: number
}
type AnalyticUserWatchResponse = {
  payload: AnalyticUserWatch[]
  pagination: PaginationResp
}
export const getAnalyticUserWatch = (builder: BuilderType) =>
  builder.query<AnalyticUserWatchResponse, AnalyticUserWatchRequest>({
    query: (params) => ({ url: '/analytic/analytic_user_watch', params }),
  })

export const getAnalyticAnalytic = (builder: BuilderType) =>
  builder.query<AnalyticResponse, { body: AnalyticRequest; params: AnalyticPaginationType }>({
    query: ({ body, params }) => ({ url: '/analytic/analytic', method: 'POST', body, params }),
  })

export const getCountryCode = (builder: BuilderType) =>
  builder.query<CountryCode[], void>({
    query: () => 'https://wealthback.cecure.io/analytic/get_country_code',
  })
export const getFilterPreset = (builder: BuilderType) =>
  builder.query<AnalyticFilterPresetType[], { presetId?: number }>({
    query: ({ presetId }) => ({ url: '/analytic/filter_preset' + `${presetId ? '/' + presetId : ''}` }),
    providesTags: [{ id: 'All', type: 'FilterPresetAnalytics' }],
  })
export const createFilterPreset = (builder: BuilderType) =>
  builder.mutation<AnalyticFilterPresetType, { name: string; filters: any }>({
    query: (body) => ({ url: '/analytic/filter_preset', method: 'POST', body }),
    invalidatesTags: [{ id: 'All', type: 'FilterPresetAnalytics' }],
  })
export const updateFilterPreset = (builder: BuilderType) =>
  builder.mutation<AnalyticFilterPresetType, { id: number; filters: any }>({
    query: (body) => ({ url: '/analytic/filter_preset/' + body.id, method: 'PATCH', body }),
    invalidatesTags: [{ id: 'All', type: 'FilterPresetAnalytics' }],
  })
export const deleteFilterPreset = (builder: BuilderType) =>
  builder.mutation<any, number>({
    query: (id) => ({ url: '/analytic/filter_preset/' + id, method: 'DELETE' }),
    invalidatesTags: [{ id: 'All', type: 'FilterPresetAnalytics' }],
  })
export const getUsersFilter = (builder: BuilderType) =>
  builder.query<
    { pagination: { page: number; limit: number; pages_count: number }; payload: any[] },
    {
      body: any
      params: {
        limit?: number
        page?: number
        order_by?: '-create_at'
      }
    }
  >({
    query: ({ body, params }) => ({
      url: '/analytic/users_filter',
      method: 'POST',
      params,
      body,
    }),
  })
