import React, { useState } from 'react'
import { Subscribe, SubscribeParamInput, SubscribeParamSelect, SubscribeParamSlider } from 'typings/entities'
import { PARAM_TYPE } from 'typings/dataStructures'
import styles from './ParamPreview.module.css'

type Props = {
  params: Subscribe['params']
}
const isBadParams = (arg: unknown) => {
  if (typeof arg === 'string') {
    return true
  }
  if (arg === null) {
    return true
  }
  if (!Array.isArray(arg)) {
    return true
  }
  return false
}
const BadParamPreview: React.FC<{ params: Subscribe['params'] }> = (props) => {
  const [showBad, setShow] = useState(false)
  if (showBad) {
    return (
      <pre onClick={() => setShow(false)} style={{ fontSize: '12px', cursor: 'pointer' }}>
        {JSON.stringify(props.params)}
      </pre>
    )
  }
  if (props.params === null) return <p style={{ marginBottom: 0 }}>-</p>
  return (
    <p style={{ color: 'red', cursor: 'pointer', marginBottom: 0 }} onClick={() => setShow(true)}>
      Bad param
    </p>
  )
}
const InputParam: React.FC<SubscribeParamInput> = (props) => {
  console.log('imputparam', props.value)
  return (
    <p className={styles.param}>
      {props.name}: <b>{props.value}</b>
    </p>
  )
}
const SelectParam: React.FC<SubscribeParamSelect> = (props) => {
  console.log('SelectParam', props.value)
  return (
    <p className={styles.param}>
      {props.name}: <b>{props.value}</b>
    </p>
  )
}
const SliderParam: React.FC<SubscribeParamSlider> = (props) => (
  <p className={styles.param}>
    {props.name}: <b>{props.value.toLocaleString()}</b>
  </p>
)
const mapper: Record<PARAM_TYPE, React.FC<any>> = {
  [PARAM_TYPE.input]: InputParam,
  [PARAM_TYPE.select]: SelectParam,
  [PARAM_TYPE.slider]: SliderParam,
}
export const ParamSchemaPreview: React.FC<{ params: unknown }> = (props) => {
  return <pre>{JSON.stringify(props.params, null, ' ')}</pre>
}
const ParamPreview: React.FC<Props> = (props) => {
  console.log('ParamPreview', props.params, isBadParams(props.params))
  if (isBadParams(props.params)) {
    return <BadParamPreview params={props.params} />
  }
  return (
    <div style={{ fontSize: '12px' }} onClick={() => console.log(props.params, typeof props.params)}>
      {props.params!.map((p, i) => {
        const SpecificParamPreview = mapper[p.type]
        return <SpecificParamPreview key={i} {...p} />
      })}
    </div>
  )
}
export default ParamPreview
