import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from 'store'
import {
  ImportedClient,
  User,
  Campaign,
  Subscribe,
  SubscribeStatus,
  ImportedClientTag,
  ImportedClientSource,
  Chart,
} from 'typings/entities'

import {
  createSellerProducts,
  deleteSellerProduct,
  deleteSellerProducts,
  getSellerProduct,
  getSellerProducts,
  updateSellerProduct,
} from './endpoints/sellerProductEndpoints'
import {
  getAnalyticUser,
  getFilterPreset,
  createFilterPreset,
  getCountryCode,
  updateFilterPreset,
  deleteFilterPreset,
  getUsersFilter,
  getAnalyticAnalytic,
  getAnalyticUserWatch,
} from './endpoints/analyticEndpoints'
import {
  createAdvisorClient,
  createImportedClient,
  createImportedClients,
  deleteAdvisorClient,
  deleteImportedClient,
  deleteImportedClients,
  getAdvisorClients,
  getImportedClient,
  getImportedClient2,
  getImportedClients,
  getImportedClientSources,
  updateImportedClient,
} from './endpoints/importedClientEnpoints'
import {
  createImportedClientTag,
  deleteImportedClientTag,
  getImportedClientTag,
  getImportedClientTags,
  updateImportedClientTag,
} from './endpoints/importedClientTagEndpoints'
import {
  createCampaign,
  deleteCampaign,
  getAnalyticCampaignEmailCount,
  getAnalyticCampaignPushCount,
  getCampaign,
  getCampaigns,
  getCampaignTemplatePresetFull,
  getCampaignTemplatePresets,
  getTemplatePrettify,
  getTemplatePreview,
  pushCampaignEmail,
  pushCampaignMobile,
  updateCampaign,
} from './endpoints/campaignEndpoints'
import { deleteSubscribe, getSubscribe, getSubscribes, updateSubscribe } from './endpoints/subscribeEndpoints'
import {
  createSubscribeStatus,
  deleteSubscribeStatus,
  getSubscribeStatus,
  getSubscribeStatuses,
  getSubscribeStatusesAll,
  updateSubscribeStatus,
} from './endpoints/subscribeStatusEndpoints'
import {
  createSellerFirmSeller,
  deleteSellerFirmSeller,
  getSellerFirmSellers,
} from './endpoints/sellerFirmSellerEndpoints'
import {
  createSellerFirmBackground,
  deleteSellerFirmBackground,
  getSellerFirmBackground,
} from './endpoints/sellerFirmBackgroundEndpoints'
import {
  createSellerFirmAvatar,
  deleteSellerFirmAvatar,
  getSellerFirmAvatar,
} from './endpoints/sellerFirmAvatarEndpoints'
import {
  createSellerFirm,
  deleteSellerFirm,
  getSellerFirm,
  getSellerFirms,
  updateSellerFirm,
} from './endpoints/sellerFirmEndpoints'
import {
  createIssuerProductAttachment,
  deleteIssuerProductAttachment,
  patchIssuerProductAttachmentOrder,
} from './endpoints/issuerProductAttachmentEndpoints'
import { createIssuerProductPicture } from './endpoints/issuerProductPictureEndpoints'
import {
  createIssuerProduct,
  deleteIssuerProduct,
  deleteIssuerProducts,
  getIssuerProduct,
  getIssuerProducts,
  getIssuerProductsByIds,
  reorderIssuerProduct,
  updateIssuerProduct,
} from './endpoints/issuerProductEndpoints'
import { deleteIssuerProductPicture, patchIssuerProductPictureOrder } from './endpoints/issuerProductPictureEndpoints'
import {
  createIssuerFirmCreator,
  deleteIssuerFirmCreator,
  getIssuerFirmCreators,
} from './endpoints/issuerFirmCreatorEndpoints'
import {
  createIssuerFirmBackground,
  deleteIssuerFirmBackground,
  getIssuerFirmBackground,
} from './endpoints/issuerFirmBackgroundEndpoints'
import {
  createIssuerFirmAvatar,
  deleteIssuerFirmAvatar,
  getIssuerFirmAvatar,
} from './endpoints/issuerFirmAvatarEndpoints'
import {
  createIssuerFirm,
  deleteIssuerFirm,
  getIssuerFirm,
  getIssuerFirms,
  updateIssuerFirm,
} from './endpoints/issuerFirmEndpoints'
import {
  changePassword,
  createUser,
  getTokenForCode,
  getUser,
  getUserInfo,
  getUsers,
  login,
  logout,
  register,
  resetPassword,
  updateSpecificUser,
  updateUser,
} from './endpoints/profileEndpoints'
import { getCategories } from './endpoints/categoriesEndpoints'
import {
  createIssuerProductVideo,
  deleteIssuerProductVideo,
  patchIssuerProductVideoOrder,
} from './endpoints/issuerProductVideoEndpoints'
import {
  createIssuerProductChart,
  deleteIssuerProductChart,
  patchIssuerProductChartOrder,
} from './endpoints/issuerProductChartsEndpoints'
import { createAdvisorRole, getRoles } from './endpoints/roleEndpoints'

// export const API_URL = process.env.REACT_APP_API_URL
export const API_URL = 'https://wealthback.cecure.io/api'
console.log('API_URL', API_URL)
console.log(process.env)

export const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).profile.user?.token
    if (token) headers.set('authorization', token)
    return headers
  },
})
export const tagTypes = [
  'Role',
  'User',
  'IssuerFirm',
  'IssuerFirmCreators',
  'IssuerProduct',
  'SellerFirm',
  'SellerFirmSellers',
  'SubscribeStatus',
  'Subscribe',
  'Campaign',
  'SellerProducts',
  'ImportedClient',
  'ImportedClientTag',
  'ImportedClientTagSpecific',
  'ImportedClientSource',
  'AdvisorClients',
  'FilterPresetAnalytics',
] as const
export type TT = typeof tagTypes[number]
export const api = createApi({
  reducerPath: 'api',
  baseQuery,
  tagTypes: tagTypes,
  endpoints: (builder) => ({
    login: login(builder),
    resetPassword: resetPassword(builder),
    getTokenForCode: getTokenForCode(builder),
    logout: logout(builder),
    register: register(builder),
    createUser: createUser(builder),
    getUserInfo: getUserInfo(builder),
    updateUser: updateUser(builder),
    changePassword: changePassword(builder),
    updateSpecificUser: updateSpecificUser(builder),
    getUsers: getUsers(builder),
    getUser: getUser(builder),

    getCategories: getCategories(builder),

    //role
    getRoles: getRoles(builder),
    createAdvisorRole: createAdvisorRole(builder),

    //IssuerFirm
    getIssuerFirms: getIssuerFirms(builder),
    getIssuerFirm: getIssuerFirm(builder),
    createIssuerFirm: createIssuerFirm(builder),
    updateIssuerFirm: updateIssuerFirm(builder),
    deleteIssuerFirm: deleteIssuerFirm(builder),

    //IssuerFirmAvatar
    getIssuerFirmAvatar: getIssuerFirmAvatar(builder),
    createIssuerFirmAvatar: createIssuerFirmAvatar(builder),
    deleteIssuerFirmAvatar: deleteIssuerFirmAvatar(builder),

    //IssuerFirmBackground
    getIssuerFirmBackground: getIssuerFirmBackground(builder),
    createIssuerFirmBackground: createIssuerFirmBackground(builder),
    deleteIssuerFirmBackground: deleteIssuerFirmBackground(builder),

    //IssuerFirmCreator
    getIssuerFirmCreators: getIssuerFirmCreators(builder),
    createIssuerFirmCreator: createIssuerFirmCreator(builder),
    deleteIssuerFirmCreator: deleteIssuerFirmCreator(builder),

    //IssuerProduct
    getIssuerProducts: getIssuerProducts(builder),
    getIssuerProductsByIds: getIssuerProductsByIds(builder),
    deleteIssuerProducts: deleteIssuerProducts(builder),
    getIssuerProduct: getIssuerProduct(builder),
    createIssuerProduct: createIssuerProduct(builder),
    updateIssuerProduct: updateIssuerProduct(builder),
    deleteIssuerProduct: deleteIssuerProduct(builder),
    reorderIssuerProduct: reorderIssuerProduct(builder),

    //IssuerProductPicture
    createIssuerProductPicture: createIssuerProductPicture(builder),
    deleteIssuerProductPicture: deleteIssuerProductPicture(builder),
    patchIssuerProductPictureOrder: patchIssuerProductPictureOrder(builder),

    //IssuerProductAttachment
    createIssuerProductAttachment: createIssuerProductAttachment(builder),
    deleteIssuerProductAttachment: deleteIssuerProductAttachment(builder),
    patchIssuerProductAttachmentOrder: patchIssuerProductAttachmentOrder(builder),

    //IssuerProductChart
    createIssuerProductChart: createIssuerProductChart(builder),
    deleteIssuerProductChart: deleteIssuerProductChart(builder),
    patchIssuerProductChartOrder: patchIssuerProductChartOrder(builder),

    //IssuerProductVideo
    createIssuerProductVideo: createIssuerProductVideo(builder),
    deleteIssuerProductVideo: deleteIssuerProductVideo(builder),
    patchIssuerProductVideoOrder: patchIssuerProductVideoOrder(builder),

    //SellerFirm
    getSellerFirms: getSellerFirms(builder),
    getSellerFirm: getSellerFirm(builder),
    createSellerFirm: createSellerFirm(builder),
    updateSellerFirm: updateSellerFirm(builder),
    deleteSellerFirm: deleteSellerFirm(builder),

    //SellerFirmAvatar
    getSellerFirmAvatar: getSellerFirmAvatar(builder),
    createSellerFirmAvatar: createSellerFirmAvatar(builder),
    deleteSellerFirmAvatar: deleteSellerFirmAvatar(builder),

    //SellerFirmBackground
    getSellerFirmBackground: getSellerFirmBackground(builder),
    createSellerFirmBackground: createSellerFirmBackground(builder),
    deleteSellerFirmBackground: deleteSellerFirmBackground(builder),

    //SellerFirmSeller
    getSellerFirmSellers: getSellerFirmSellers(builder),
    createSellerFirmSeller: createSellerFirmSeller(builder),
    deleteSellerFirmSeller: deleteSellerFirmSeller(builder),

    //SubscribeStatus
    getSubscribeStatuses: getSubscribeStatuses(builder),
    getSubscribeStatusesAll: getSubscribeStatusesAll(builder),
    getSubscribeStatus: getSubscribeStatus(builder),
    deleteSubscribeStatus: deleteSubscribeStatus(builder),
    createSubscribeStatus: createSubscribeStatus(builder),
    updateSubscribeStatus: updateSubscribeStatus(builder),

    //Subscribe
    getSubscribes: getSubscribes(builder),
    getSubscribe: getSubscribe(builder),
    updateSubscribe: updateSubscribe(builder),
    deleteSubscribe: deleteSubscribe(builder),

    //Campaign
    getCampaignTemplatePresets: getCampaignTemplatePresets(builder),
    getCampaignTemplatePresetFull: getCampaignTemplatePresetFull(builder),
    getCampaigns: getCampaigns(builder),
    getCampaign: getCampaign(builder),
    pushCampaignMobile: pushCampaignMobile(builder),
    pushCampaignEmail: pushCampaignEmail(builder),
    updateCampaign: updateCampaign(builder),
    createCampaign: createCampaign(builder),
    deleteCampaign: deleteCampaign(builder),
    getTemplatePreview: getTemplatePreview(builder),
    getTemplatePrettify: getTemplatePrettify(builder),
    getAnalyticCampaignEmailCount: getAnalyticCampaignEmailCount(builder),
    getAnalyticCampaignPushCount: getAnalyticCampaignPushCount(builder),

    // SellerProduct
    getSellerProducts: getSellerProducts(builder),
    createSellerProducts: createSellerProducts(builder),
    deleteSellerProducts: deleteSellerProducts(builder),
    getSellerProduct: getSellerProduct(builder),
    updateSellerProduct: updateSellerProduct(builder),
    deleteSellerProduct: deleteSellerProduct(builder),

    // ImportedClientTag
    getImportedClientTags: getImportedClientTags(builder),
    getImportedClientTag: getImportedClientTag(builder),
    createImportedClientTag: createImportedClientTag(builder),
    updateImportedClientTag: updateImportedClientTag(builder),
    deleteImportedClientTag: deleteImportedClientTag(builder),

    // ImportedClient
    getImportedClients: getImportedClients(builder),
    createImportedClients: createImportedClients(builder),
    getImportedClient: getImportedClient(builder),
    getImportedClient2: getImportedClient2(builder),
    createImportedClient: createImportedClient(builder),
    updateImportedClient: updateImportedClient(builder),
    deleteImportedClient: deleteImportedClient(builder),
    deleteImportedClients: deleteImportedClients(builder),
    getAdvisorClients: getAdvisorClients(builder),
    createAdvisorClient: createAdvisorClient(builder),
    deleteAdvisorClient: deleteAdvisorClient(builder),
    getImportedClientSources: getImportedClientSources(builder),

    //Analytic
    getAnalyticUser: getAnalyticUser(builder),
    getAnalyticAnalytic: getAnalyticAnalytic(builder),
    getFilterPreset: getFilterPreset(builder),
    createFilterPreset: createFilterPreset(builder),
    updateFilterPreset: updateFilterPreset(builder),
    deleteFilterPreset: deleteFilterPreset(builder),
    getCountryCode: getCountryCode(builder),
    getUsersFilter: getUsersFilter(builder),
    getAnalyticUserWatch: getAnalyticUserWatch(builder),
  }),
})

export const contactsUploadCSV = (formData: FormData, source: number) => {
  return fetch(API_URL + `/imported_client/upload_csv?source=${source}`, {
    method: 'POST',
    body: formData,
  })
    .then((r) => r.json())
    .catch(() => null)
}
