import { applyMiddleware, combineReducers, createStore } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import persistStorage from 'redux-persist/lib/storage'
import { TypedUseSelectorHook, useSelector as useReduxSelector } from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import { appReducer } from './reducers/appReducer'
import { profileReducer } from './reducers/profileReducer'
import { kanbanReducer } from './reducers/kanbanReducer'
import sagas from './sagas'
import thunk from 'redux-thunk'
import { api } from 'api/index'
import { composeWithDevTools } from 'redux-devtools-extension'
import { analyticReducer } from './reducers/analyticReducer'

const persistConfig = {
  key: 'root',
  storage: persistStorage,
  whitelist: ['profile', 'app'],
  // whitelist: [],
  // blacklist: ['', 'app'],
  // debug: __DEV__,
  debug: process.env.NODE_ENV === 'development',
}

export const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  app: appReducer,
  kanban: kanbanReducer,
  profile: profileReducer,
  analytic: analyticReducer,
})

export type RootState = ReturnType<typeof rootReducer>

const persistedReducer = persistReducer<RootState>(persistConfig, rootReducer)

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector

const sagaMiddleware = createSagaMiddleware()

export const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware, thunk, api.middleware)),
)

sagaMiddleware.run(sagas)

export const persistor = persistStore(store)
