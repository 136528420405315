import React from 'react'
import { Form, FormInstance, Input } from 'antd'
import { Firm } from '../typings/entities'
import UploadPicture from './Upload/UploadPicture/UploadPicture'

export type FirmFormType = Omit<Firm, 'avatar_url' | 'background_url'> & { avatar?: File; background?: File }

type Props = {
  form: FormInstance
  initialValues?: any
}

const FirmForm = ({ form, ...props }: Props) => {
  return (
    <Form
      form={form}
      initialValues={props.initialValues}
      labelCol={{ flex: '120px' }}
      labelAlign={'left'}
      requiredMark={false}>
      <Form.Item name={'name'} label={'Name'} rules={[{ required: true, message: 'Please input name!' }]}>
        <Input />
      </Form.Item>
      <Form.Item name={'description'} label={'Description'}>
        <Input.TextArea />
      </Form.Item>
      <Form.Item name={'avatar'} label={'Avatar'}>
        <UploadPicture />
      </Form.Item>
      <Form.Item name={'background'} label={'Background'}>
        <UploadPicture />
      </Form.Item>
    </Form>
  )
}

export default FirmForm
