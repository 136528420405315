import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AnalyticMetricType } from '../../api'

export type AnalyticState = {
  targetMetric: AnalyticMetricType

  period: [number, number]
  activity_level: [number, number]

  favourite_category?: number
  email: string | null
  // country: string | null
  categories: number[] | null
  // state: string | null
  accredited_investor: boolean | null
  qualified_purchaser: boolean | null
  household_value: [number, number] | null
  household_cash_value: [number, number] | null

  products: number[] | null
  billing_state: string | null
  zip_code: string | null
}

const initialState: AnalyticState = {
  targetMetric: 'views',

  period: [Date.now() - 1000 * 60 * 60 * 24 * 7, Date.now()],
  activity_level: [0, 1],

  favourite_category: undefined,
  email: null,
  categories: null,
  household_value: null,
  household_cash_value: null,
  // country: null,
  // state: null,
  accredited_investor: null,
  qualified_purchaser: null,
  // household_value: null,
  // household_cash_value: null,
  products: null,
  billing_state: null,
  zip_code: null,
}

const slice = createSlice({
  name: 'analytic',
  initialState,
  reducers: {
    setAnalyticFilterData: (state, action: PayloadAction<AnalyticState>) => {
      for (const key in action.payload) {
        //@ts-ignore
        state[key] = action.payload[key]
      }
    },
    setTargetMetric: (state, action: PayloadAction<AnalyticMetricType>) => {
      state.targetMetric = action.payload
    },
    resetAnalyticFilterData: (state) => {
      for (const key in initialState) {
        //@ts-ignore
        state[key] = initialState[key]
      }
    },
  },
})

export const { actions: analyticActions, reducer: analyticReducer } = slice
