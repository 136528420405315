import React from 'react'
import styles from './FormValueString.module.css'

const FormValueString = ({ value, onChange }: { value?: number; onChange?: () => {} }) => {
  if (value === undefined) return <span className={styles.formValue} />
  return <span className={styles.formValue}>{value?.toFixed(2) ?? '-'}</span>
}

export const FormValueStringPct = ({ value, onChange }: { value?: number; onChange?: () => {} }) => {
  if (value === undefined) return <span className={styles.formValue} />
  return (
    <span className={styles.formValue}>{value !== undefined ? `${Math.round(value * 100 * 100) / 100}%` : '-'}</span>
  )
}

export default FormValueString
