import { Button, Checkbox, Row, Table } from 'antd'
import { Link } from 'react-router-dom'
import React, { useState } from 'react'
import { ColumnsType } from 'antd/es/table'
import { DeleteOutlined, ZoomInOutlined } from '@ant-design/icons'
import { api } from 'api/index'
import useMySellerFirm from 'hooks/useMySellerFirm'
import { Category, ProductPicture, SellerProduct } from 'typings/entities'
import { store, useSelector } from 'store'
import { isImageUrl, number2currency, prettyDate, thumbUrl } from 'utils'
import noImage from 'assets/img/no_image.png'
import CategoriesPreview from 'components/CategoriesPreview'
import { prettyBool } from 'export/colors'
import CollapseText from 'ui/CollapseText'
import SellerFirmSelect from 'ui/customFormInputs/SellerFirmSelect'

export const TableProductPicturePreview: React.FC<{ images: ProductPicture[] }> = ({ images }) => {
  console.log('images', images)

  let src = noImage
  const pictures = images?.filter((p) => isImageUrl(p.url))
  if (Array.isArray(pictures) && pictures.length > 0) {
    const firstPicture = pictures.find((p) => p.order === 0) ?? pictures[0]
    if (isImageUrl(firstPicture.url)) {
      src = thumbUrl(firstPicture.url, 128)
    }
  }
  return <img src={src} alt={''} style={{ width: 128, height: 96, objectFit: 'contain' }} />
}
export const tableTitle = (title: string, fontSize = 12) => {
  return <span style={{ fontSize: '12px' }}>{title}</span>
}
export const ProductParamsPreviewReadOnly: React.FC<{ params?: { name: string; type: string }[] }> = ({ params }) => {
  const [show, setShow] = useState(true)
  if (!params) return null
  if (!Array.isArray(params)) return null
  console.log('params', params)
  let p = params
  if (!show) {
    p = p.slice(0, 2)
  }
  return (
    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', paddingTop: 10, position: 'relative' }}>
      <ZoomInOutlined
        style={{ position: 'absolute', top: 4, right: 4, cursor: 'pointer', display: 'none' }}
        onClick={() => setShow((prev) => !prev)}
      />
      {p.map((param, i) => (
        <p
          key={i}
          style={{ marginBottom: 0, fontSize: '11px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: '100%' }}>
          {/*<b>{param.name}</b> {show && `[${param.type}]`}*/}
          <b>{param.name}</b>
        </p>
      ))}
      {params.length > 2 && !show && (
        <p style={{ marginBottom: 0, fontSize: '11px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: '100%' }}>
          ...
        </p>
      )}
    </div>
  )
}
const columns: ColumnsType<SellerProduct> = [
  { dataIndex: 'id', title: '#' },
  {
    dataIndex: 'images',
    title: 'Picture',
    width: 128,
    render: (images: ProductPicture[]) => <TableProductPicturePreview images={images} />,
  },
  {
    dataIndex: 'name',
    title: 'Name',
    width: 200,
    render: (v, row) => <Link to={'/seller_product/' + row.id}>{v}</Link>,
  },
  { dataIndex: 'price', title: 'Price', render: (v) => number2currency(v) },
  { dataIndex: 'short_description', title: 'Description', render: (v) => <CollapseText text={v} /> },
  {
    dataIndex: 'categories',
    title: 'Categories',
    render: (v: Category[]) => <CategoriesPreview categories={v} />,
  },

  { dataIndex: 'order', title: 'Order' },
  { dataIndex: 'approved', title: 'Approved', render: (v) => prettyBool(v) },
  {
    dataIndex: 'expire_date',
    title: 'Expires',
    render: (v) => (v ? new Date(v * 1000).toLocaleDateString() : '-'),
  },
  {
    dataIndex: 'params',
    title: 'Params',
    render: (v: { name: string; type: string }[]) => <ProductParamsPreviewReadOnly params={v} />,
  },
  {
    title: tableTitle('Update at'),
    dataIndex: 'seller_product_create_at',
    render: (v) => prettyDate(v),
    sorter: (a, b) => a.seller_product_create_at - b.seller_product_create_at,
    defaultSortOrder: 'descend',
  },
  {
    dataIndex: 'issuer_firm_product_id',
    title: 'Actions',
    render: (v, row) => {
      return (
        <Row justify="center">
          <Button
            size={'small'}
            type={'primary'}
            danger
            icon={<DeleteOutlined />}
            onClick={() => {
              //@ts-ignore
              store.dispatch(api.endpoints.deleteSellerProduct.initiate(row.id))
              // result.
              // console.log('api.endpoints.deleteSellerProduct', )
              // api.endpoints.deleteSellerProduct.  (row.id)
            }}
          />
        </Row>
      )
    },
  },
]

const SellerProducts = () => {
  const profile = useSelector((store) => store.profile)
  const [approvedOnly, setApprovedOnly] = useState(false)
  const [withExpired, setWithExpired] = useState(false)
  const sellerFirm = useMySellerFirm()
  const [sf, setSf] = useState<number | undefined>(sellerFirm?.id)
  const products = api.useGetSellerProductsQuery(
    {
      seller_firm: sf,
      approved_only: Number(approvedOnly) as 0 | 1,
      with_expired: Number(withExpired) as 0 | 1,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  )
  console.log(products.data)
  return (
    <div>
      <h1 style={{ marginBottom: 30 }}>Seller products</h1>
      <div style={{ display: 'flex', marginBottom: 16 }}>
        <Button onClick={products.refetch} type={'primary'}>
          Reload
        </Button>
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: 4, columnGap: 4 }}>
          <span>Approved only:</span>
          <Checkbox checked={approvedOnly} onChange={(e) => setApprovedOnly(e.target.checked)} />
          <span>Show expired:</span>
          <Checkbox checked={withExpired} onChange={(e) => setWithExpired(e.target.checked)} />
          <SellerFirmSelect
            value={sf}
            onChange={setSf}
            style={{ width: 200 }}
            disabled={profile?.user?.is_superuser === false}
          />
        </div>
        <Button type={'primary'} style={{ marginLeft: 'auto' }}>
          <Link to={'/seller_product/new'}>New seller product</Link>
        </Button>
      </div>
      <Table
        size={'small'}
        loading={products.isFetching}
        columns={columns}
        dataSource={products.data}
        rowKey={'id'}
        bordered
      />
    </div>
  )
}

export default SellerProducts
