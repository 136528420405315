import React, { useState } from 'react'
import { ImportedClient } from 'typings/entities'
import { Button, message, Radio, Select, Switch, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { api } from 'api/index'
import { Link } from 'react-router-dom'
import { CLIENT_ACTION } from 'typings/dataStructures'
import UITexts from 'export/UITexts'
import TagsPreview from 'components/TagsPreview'
import { prettyBool } from 'export/colors'
import useIsSuperuser from 'hooks/useIsSuperuser'
import useMySellerFirm from 'hooks/useMySellerFirm'
import SellerFirmSelect from 'ui/customFormInputs/SellerFirmSelect'
import { useSelector } from '../../store'

const columns: ColumnsType<ImportedClient> = [
  {
    dataIndex: 'id',
    title: '#',
    render: (v, row) => <Link to={'/imported_client/' + row.id}>{v}</Link>,
    sorter: (a, b) => a.id - b.id,
    defaultSortOrder: 'descend',
  },
  {
    dataIndex: 'account_name',
    title: 'Account Name',
    render: (v, row) => <Link to={'/imported_client/' + row.id}>{v}</Link>,
  },
  { dataIndex: 'first_name', title: 'First Name' },
  { dataIndex: 'last_name', title: 'Last Name' },
  {
    dataIndex: 'tags',
    title: 'Tags',
    render: (v) => {
      console.log('v', v)
      return <TagsPreview tags={v} />
    },
  },
  { dataIndex: 'email', title: 'Email' },
  {
    dataIndex: 'mobile_phone',
    title: 'Phone',
    render: (v) => <span style={{ whiteSpace: 'nowrap', fontSize: '12px' }}>{v}</span>,
  },
  {
    dataIndex: 'household_value',
    // title: <span style={{ fontSize: '12px', whiteSpace: 'nowrap' }}>Household value</span>,
    title: 'Household value',
    render: (v) => (v ? v.toLocaleString() : '-'),
  },
  { dataIndex: 'household_cash_value', title: 'Household cash value', render: (v) => (v ? v.toLocaleString() : '-') },
  { dataIndex: 'billing_state', title: 'Billing state' },
  { dataIndex: 'zip_code', title: 'Zip code' },
  { dataIndex: 'accredited_investor', title: 'Accredited investor', render: prettyBool },
  { dataIndex: 'qualified_purchaser', title: 'Qualified purchaser', render: prettyBool },
  // {
  //   dataIndex: 'last_modified_date',
  //   title: 'Last update',
  //   render: (v) => <span style={{ fontSize: '12px', whiteSpace: 'nowrap' }}>{v}</span>,
  // },
]

const ImportedClients = () => {
  const isSuperUser = useIsSuperuser()
  const sellerFirm = useMySellerFirm()
  const user = useSelector((state) => state.profile.user)

  const [selectedSellerFirm, setSelectedSellerFirm] = useState(sellerFirm?.id)
  const [action, setAction] = useState<CLIENT_ACTION>()
  const [selectedIds, setSelected] = useState<number[]>([])
  const [isAllClients, setIsAllClients] = useState<boolean>(false)

  const importedClients = api.useGetImportedClientsQuery(
    { seller_firm_id: selectedSellerFirm },
    { skip: !selectedSellerFirm, refetchOnMountOrArgChange: true },
  )
  const [del, delResp] = api.useDeleteImportedClientsMutation()
  const advisorClients = api.useGetAdvisorClientsQuery({ advisorId: user!.id })
  const [createAdvisorClient, createAdvisorClientResp] = api.useCreateAdvisorClientMutation()
  const [delAdvisorClient, delAdvisorClientResp] = api.useDeleteAdvisorClientMutation()

  const onActionClick = async () => {
    const promises: Promise<any>[] = []
    switch (action) {
      case CLIENT_ACTION.DELETE:
        const resp = await del({ ids: selectedIds })
        if ('data' in resp) {
          message.success(UITexts.ImportedClient.DELETE_MANY_SUCCESS)
        } else {
          message.error(UITexts.ImportedClient.DELETE_ERROR)
        }
        break
      case CLIENT_ACTION.CREATE_ADV_CL:
        selectedIds.forEach((id) => {
          promises.push(createAdvisorClient({ advisor: user!.id, client: id }).finally(() => setSelected([])))
        })
        break
      case CLIENT_ACTION.DELETE_ADV_CL:
        selectedIds.forEach(async (id) => {
          promises.push(delAdvisorClient({ advisor: user!.id, client: id }).finally(() => setSelected([])))
        })
        break
      default:
        break
    }
    Promise.all(promises).finally(() => setSelected([]))
    setAction(undefined)
  }

  const onToggleAllClients = () => {
    setIsAllClients((c) => !c)
    setAction(undefined)
    setSelected([])
  }

  return (
    <div>
      <h1 style={{ marginBottom: 30 }}>Imported clients</h1>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}>
        <Button
          type={'primary'}
          onClick={() => {
            importedClients.refetch()
            advisorClients.refetch()
          }}>
          Reload
        </Button>
        <SellerFirmSelect
          disabled={!isSuperUser}
          value={selectedSellerFirm}
          onChange={setSelectedSellerFirm}
          style={{ width: 200, marginLeft: 16 }}
        />
        <Radio.Group value={isAllClients ? 'allClients' : 'myClients'} buttonStyle={'solid'} style={{ marginLeft: 16 }}>
          <Radio.Button value={'allClients'} onClick={onToggleAllClients}>
            All clients
          </Radio.Button>
          <Radio.Button value={'myClients'} onClick={onToggleAllClients}>
            My clients
          </Radio.Button>
        </Radio.Group>
        <Button type={'primary'} style={{ marginLeft: 'auto', marginRight: 16 }}>
          <Link to={'/import_clients'}>Import clients</Link>
        </Button>
        <Button type={'primary'} style={{ marginRight: 16 }}>
          <Link to={'/imported_client/new'}>New client</Link>
        </Button>
        <div style={{ display: 'flex', alignItems: 'center', columnGap: 8 }}>
          <p style={{ marginBottom: 0 }}>Action</p>
          <Select
            // size={'small'}
            disabled={selectedIds.length === 0}
            placeholder={'---'}
            value={action}
            onChange={setAction}
            options={[
              { value: CLIENT_ACTION.DELETE, label: 'Delete' },
              isAllClients
                ? { value: CLIENT_ACTION.CREATE_ADV_CL, label: 'Add to my clients' }
                : { value: CLIENT_ACTION.DELETE_ADV_CL, label: 'Remove from my clients' },
            ]}
            style={{ width: 190 }}
          />
          <Button
            size={'small'}
            disabled={selectedIds.length === 0}
            onClick={onActionClick}
            loading={delResp.isLoading}>
            Go
          </Button>
        </div>
      </div>
      <Table
        loading={advisorClients.isFetching || importedClients.isFetching}
        size={'small'}
        dataSource={isAllClients ? importedClients.data : advisorClients.data}
        columns={columns}
        rowKey={'id'}
        rowSelection={{
          type: 'checkbox',
          selectedRowKeys: selectedIds,
          // onSelect: (e) => setSelected((prev) => [...prev, e.id]),
          onChange: (e) => {
            console.log(e)
            setSelected(e as number[])
          },
        }}
      />
    </div>
  )
}

export default ImportedClients
