import { BarChartOutlined, TableOutlined } from '@ant-design/icons'
import { skipToken } from '@reduxjs/toolkit/query'
import { ColumnsType } from 'antd/es/table/Table'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Row, Select, Space, Switch, Table, Typography } from 'antd'

import { AnalyticUserWatch, Category, CHART_AXIS, Product } from 'typings/entities'
import { AnalyticAggregationType, AnalyticMetricType, api } from 'api'
import BarChart from 'components/charts/BarChart'
import { prettyDate } from 'utils'

const nameW = 300
const defaultTable = true
const blockSpacing = 40
const fmtNumber = (v: number | undefined) => v?.toLocaleString()

type Props = {
  user_id: number
  seller_firm: number
}
const DAY = 1000 * 60 * 60 * 24
const WEEK = DAY * 7
const YEAR = DAY * 365
const SelectPeriod: React.FC<{ onChange: (e: number) => void }> = ({ onChange }) => {
  const [v, sv] = useState(WEEK)
  useEffect(() => {
    onChange(v)
  }, [v])
  return (
    <Select
      style={{ width: 120 }}
      value={v}
      options={[
        { label: 'Last day', value: DAY },
        { label: 'Last week', value: WEEK },
        { label: 'All time', value: YEAR },
      ]}
      onChange={sv}
    />
  )
}
const getSeriesType = (time: number) => {
  if (time === DAY) return 'day'
  if (time === WEEK) return 'week'
  if (time === YEAR) return 'all time'
}
const FavouriteProducts: React.FC<Props> = (props) => {
  const metric: AnalyticMetricType = 'views'
  const aggregation: AnalyticAggregationType = 'issuer_product'
  const [t, st] = useState(defaultTable)
  const [dt_start, setDt_start] = useState(WEEK)
  const [duration_dt_start, setDurationDt_start] = useState(DAY)

  const analytic = api.useGetAnalyticAnalyticQuery({
    body: {
      filters: {
        seller_firm: props.seller_firm,
        dt_start: Math.round((new Date().setHours(0, 0, 0, 0) - dt_start) / 1000),
        dt_end: Math.round(new Date().setHours(23, 59, 59, 999) / 1000),
        user: [props.user_id],
      },
      aggregations: [aggregation],
      metrics: [metric, 'duration', 'mean_duration'],
      //@ts-ignore
      order_by: ['-' + metric],
    },
    params: {
      page: 1,
      limit: 25,
    },
  })

  const analyticDuration = api.useGetAnalyticAnalyticQuery({
    body: {
      filters: {
        seller_firm: props.seller_firm,
        dt_start: Math.round((new Date().setHours(0, 0, 0, 0) - duration_dt_start) / 1000),
        dt_end: Math.round(new Date().setHours(23, 59, 59, 999) / 1000),
        user: [props.user_id],
      },
      aggregations: [aggregation],
      metrics: [metric, 'duration', 'mean_duration'],
      //@ts-ignore
      order_by: ['-' + metric],
    },
    params: {
      page: 1,
      limit: 25,
    },
  })
  console.log('analytic', analytic)

  const issuerProducts = api.useGetIssuerProductsByIdsQuery(
    analytic.data
      ? {
          ids: analytic.data?.payload.rows.map((e) => e.issuer_product).join(','),
        }
      : skipToken,
    { skip: !analytic.data || analytic.data?.payload.rows.length === 0 },
  )

  const getName = (id: number) => issuerProducts.data?.find((p) => p.id === id)?.name ?? id
  const columns = [
    {
      title: 'Product',
      dataIndex: 'issuer_product',
      render: (v: number) => <Link to={'/issuer_product/' + v}>{getName(v)}</Link>,
      width: nameW,
    },
    { title: 'Views count', dataIndex: 'views', render: fmtNumber },
    { title: 'Total watch duration', dataIndex: 'duration', render: (v: number) => fmtNumber(v) + ' s.' },
    { title: 'Watch average duration', dataIndex: 'mean_duration', render: (v: number) => fmtNumber(v) + ' s.' },
    {
      title: 'Watch duration per ' + getSeriesType(duration_dt_start),
      dataIndex: 'compare',
      render: (v: number) => {
        return {
          props: {
            style: { background: 'rgba(145, 213, 255,0.5)' },
          },
          children: v ? fmtNumber(v) + ' s.' : '-',
        }
      },
    },
  ]
  // const dataSource = analytic.data?.payload.rows.map((e) => ({ ...e, duration: Math.round(Number(e.duration)) }))
  // console.log('analytic.data?.payload.rows', analytic.data?.payload.rows)

  const data =
    analytic.data?.payload.rows
      .map((e) => [getName(e[aggregation] as number), e[metric]] as [string, number])
      .map((e, i) => ({
        ...e,
        compare: analyticDuration.data?.payload.rows.map((r) => r.views)[i],
      })) ?? []
  return (
    <div style={{ marginBottom: blockSpacing, marginTop: blockSpacing }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Row justify="space-between" align="middle" style={{ width: '100%', margin: '0 10px ' }}>
          <Space>
            <h2>Most viewed products</h2>
            <SelectPeriod onChange={setDt_start} />
          </Space>
          <Space align="center">
            <Typography.Text>Compare with</Typography.Text>
            <SelectPeriod onChange={setDurationDt_start} />
          </Space>
        </Row>
        <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
          <BarChartOutlined />
          <Switch checked={t} onChange={st} size={'small'} />
          <TableOutlined />
        </div>
      </div>
      {t ? (
        <Table
          loading={analytic.isLoading}
          size={'small'}
          columns={columns}
          rowKey={'issuer_product'}
          dataSource={analytic.data?.payload.rows.map((e, i) => ({
            ...e,
            compare: analyticDuration.data?.payload.rows.map((r) => r.duration)[i],
          }))}
          pagination={false}
        />
      ) : (
        <BarChart
          title={'User watched products'}
          series_compare_type={getSeriesType(duration_dt_start)}
          series_type={getSeriesType(dt_start)}
          data={data}
          x_label={''}
          y_label={metric}
          y_type={CHART_AXIS.float}
          x_type={CHART_AXIS.string}
        />
      )}
    </div>
  )
}

const FavouriteCategories: React.FC<Props> = (props) => {
  const metric: AnalyticMetricType = 'views'
  const aggregation: AnalyticAggregationType = 'product_category'
  const [dt_start, setDt_start] = useState(WEEK)
  const [t, st] = useState(defaultTable)
  const [duration_dt_start, setDurationDt_start] = useState(DAY)

  const analytic = api.useGetAnalyticAnalyticQuery({
    body: {
      filters: {
        seller_firm: props.seller_firm,
        dt_start: Math.round((new Date().setHours(0, 0, 0, 0) - dt_start) / 1000),
        dt_end: Math.round(new Date().setHours(23, 59, 59, 999) / 1000),
        user: [props.user_id],
      },
      aggregations: [aggregation],
      metrics: ['views', 'duration', 'mean_duration'],
      //@ts-ignore
      order_by: ['-' + metric],
    },
    params: {
      page: 1,
      limit: 25,
    },
  })

  const analyticDuration = api.useGetAnalyticAnalyticQuery({
    body: {
      filters: {
        seller_firm: props.seller_firm,
        dt_start: Math.round((new Date().setHours(0, 0, 0, 0) - duration_dt_start) / 1000),
        dt_end: Math.round(new Date().setHours(23, 59, 59, 999) / 1000),
        user: [props.user_id],
      },
      aggregations: [aggregation],
      metrics: ['views', 'duration', 'mean_duration'],
      //@ts-ignore
      order_by: ['-' + metric],
    },
    params: {
      page: 1,
      limit: 25,
    },
  })

  const categories = api.useGetCategoriesQuery()

  const getName = (id: number) => categories.data?.find((p) => p.id === id)?.name ?? id

  const data = analytic.data?.payload.rows
    .map((e) => [getName(e[aggregation] as number), e[metric]] as [string, number])
    .map((e, i) => ({
      ...e,
      compare: analyticDuration.data?.payload.rows.map((r) => r.views)[i],
    }))
  const columns = [
    {
      title: 'Product',
      dataIndex: aggregation,
      render: (v: number) => <Link to={'/issuer_product/' + v}>{getName(v)}</Link>,
      width: nameW,
    },
    { title: 'Views count', dataIndex: 'views', render: fmtNumber },
    { title: 'Total watch duration', dataIndex: 'duration', render: (v: number) => fmtNumber(v) + ' s.' },
    { title: 'Watch average duration', dataIndex: 'mean_duration', render: (v: number) => fmtNumber(v) + ' s.' },
    {
      title: 'Duration per ' + getSeriesType(duration_dt_start),
      dataIndex: 'compare',
      render: (v: number) => {
        return {
          props: {
            style: { background: 'rgba(145, 213, 255,0.5)' },
          },
          children: v ? fmtNumber(v) + ' s.' : '-',
        }
      },
    },
  ]
  return (
    <div style={{ marginBottom: blockSpacing, marginTop: blockSpacing }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Row justify="space-between" align="middle" style={{ width: '100%', margin: '0 10px ' }}>
          <Space>
            <h2>Most viewed categories</h2>
            <SelectPeriod onChange={setDt_start} />
          </Space>
          <Space align="center">
            <Typography.Text>Compare with</Typography.Text>
            <SelectPeriod onChange={setDurationDt_start} />
          </Space>
        </Row>
        <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
          <BarChartOutlined />
          <Switch checked={t} onChange={st} size={'small'} />
          <TableOutlined />
        </div>
      </div>
      {t ? (
        <Table
          loading={analytic.isLoading}
          size={'small'}
          columns={columns}
          rowKey={'product_category'}
          dataSource={analytic.data?.payload.rows.map((e, i) => ({
            ...e,
            compare: analyticDuration.data?.payload.rows.map((r) => r.duration)[i],
          }))}
          pagination={false}
        />
      ) : (
        <BarChart
          title={'User watched products'}
          series_compare_type={getSeriesType(duration_dt_start)}
          series_type={getSeriesType(dt_start)}
          data={data ?? []}
          x_label={''}
          y_label={metric}
          y_type={CHART_AXIS.float}
          x_type={CHART_AXIS.string}
        />
      )}
    </div>
  )
}

const AnalyticsTable: React.FC<Props> = (props) => {
  const { data: categories } = api.useGetCategoriesQuery()

  const getCategoryById = (id: number) => {
    return categories?.find((c) => c.id === id)
  }
  const columns: ColumnsType<AnalyticUserWatch> = [
    { dataIndex: 'id', title: '#' },
    { dataIndex: 'category', title: 'Category', render: (v: Category) => v?.name ?? '-' },
    { dataIndex: 'issuer_product', title: 'Product', render: (v: Product) => v?.name ?? '-' },
    { dataIndex: 'duration', title: 'Duration', render: (v: number) => v.toLocaleString() + ' s.' },
    { dataIndex: 'create_at', title: 'Date', render: (v: number) => prettyDate(v) },
  ]
  const [pagination, setPagination] = useState({
    pageSize: 10,
    page: 1,
  })
  const analytic = api.useGetAnalyticUserWatchQuery({
    user_id: props.user_id,
    limit: pagination.pageSize,
    page: pagination.page,
  })
  const dataSource = analytic.data?.payload.map((anal) => {
    if (anal.issuer_product) {
      return {
        ...anal,
        //@ts-ignore
        category: getCategoryById(anal.issuer_product.categories[0]),
      }
    }
    return anal
  })

  return (
    <div style={{ marginBottom: blockSpacing, marginTop: blockSpacing }}>
      <h2>Client watch history</h2>
      <Table
        loading={analytic.isLoading}
        columns={columns}
        //@ts-ignore
        dataSource={dataSource}
        rowKey={'id'}
        pagination={{
          pageSize: pagination.pageSize,
          current: pagination.page,
          total: analytic.data?.pagination.pages_count,
          onChange: (page, pageSize) => {
            setPagination({ page, pageSize })
          },
        }}
      />
    </div>
  )
}

const ImportedClientAnalytic: React.FC<Props> = (props) => {
  return (
    <div>
      <FavouriteProducts seller_firm={props.seller_firm} user_id={props.user_id} />
      <FavouriteCategories seller_firm={props.seller_firm} user_id={props.user_id} />
      <AnalyticsTable seller_firm={props.seller_firm} user_id={props.user_id} />
    </div>
  )
}

export default ImportedClientAnalytic
