import React from 'react'
import Column from 'components/KanbanBoard/Column'
import { Subscribe } from 'typings/entities'
import styles from './KanbanBoard.module.css'
import { Link } from 'react-router-dom'

export type BoardCard = {
  id: number
  subscribe: Subscribe
  [key: string]: any
}

export type BoardColumn = {
  id: number
  name: string
  cards: BoardCard[]
}

export type CardMovedHandler = (fromColumn: number, toColumn: number, cardId: number) => void
type Props = {
  columns: BoardColumn[]
  onCardMoved: CardMovedHandler
  onCardClick: (subscribe: Subscribe) => void
  onCardMouseDown: () => void
  onCardMouseUp: () => void
}

const KanbanBoard: React.FC<Props> = (props) => {
  return (
    <div className={styles.main}>
      {props.columns.map((c) => (
        <Column
          key={c.id}
          column={c}
          onCardMoved={props.onCardMoved}
          onCardClick={props.onCardClick}
          onCardMouseUp={props.onCardMouseUp}
          onCardMouseDown={props.onCardMouseDown}
        />
      ))}
      {/*<div className={styles.column} style={{ minHeight: 'unset' }}>*/}
      {/*  <div className={styles.columnHead}>*/}
      {/*    <h2 className={styles.columnTitle}>*/}
      {/*      <Link to={'/subscribe_status/all'}>Add column</Link>*/}
      {/*    </h2>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  )
}

export default KanbanBoard
