import React from 'react'
import { api } from 'api/index'
import { Skeleton } from 'antd'
import useMySellerFirm from 'hooks/useMySellerFirm'

const TagsPreview: React.FC<{ tags: number[] }> = (props) => {
  const sellerFirm = useMySellerFirm()
  const tags = api.useGetImportedClientTagsQuery({ seller_firm_id: sellerFirm?.id }, { skip: !sellerFirm })
  const getTag = (id: number) => {
    return tags.data?.find((t) => t.id === id)
  }
  if (tags.isLoading) return <Skeleton.Button />
  return (
    <>
      {props.tags?.map((t) => (
        <p key={t} style={{ marginBottom: 0, fontSize: '12px', whiteSpace: 'nowrap' }}>
          #{t} {getTag(t)?.name ?? ''}
        </p>
      ))}
    </>
  )
}

export default TagsPreview
