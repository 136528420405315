import React, { useState } from 'react'
import { Button, Input, Table, Tooltip } from 'antd'
import { User } from '../typings/entities'
import { DeleteTwoTone } from '@ant-design/icons/lib'

type Props = {
  workers: User[]
  onRefetch: () => void
  onAddNewWorker: (email: string) => void
  onRemoveWorker: (id: number) => void
  isFetching: boolean
}

const WorkersTable = (props: Props) => {
  const [email, setEmail] = useState<string>('')
  const columns = [
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'First name',
      dataIndex: 'first_name',
      key: 'first_name',
    },
    {
      title: 'Last name',
      dataIndex: 'last_name',
      key: 'last_name',
    },
    {
      title: 'Actions',
      dataIndex: '',
      key: 'x',
      render: (seller: User) => {
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Tooltip title="Remove creator" mouseEnterDelay={1} color={'#DDD'} overlayInnerStyle={{ color: '#444' }}>
              <DeleteTwoTone
                twoToneColor="#FF0000"
                onClick={() => props.onRemoveWorker(seller.id)}
                style={{ fontSize: '20px' }}
              />
            </Tooltip>
          </div>
        )
      },
    },
  ]

  const onAddNewWorker = () => {
    if (email.length) {
      props.onAddNewWorker(email)
      setEmail('')
    }
  }

  return (
    <div>
      <div style={{ display: 'flex', marginBottom: 16 }}>
        <Button onClick={props.onRefetch} style={{ display: 'block' }}>
          Reload
        </Button>
        <div style={{ display: 'flex', marginLeft: 'auto' }}>
          <Input placeholder={'email'} type={'email'} value={email} onChange={(e) => setEmail(e.target.value)} />
          <Button onClick={onAddNewWorker}>New advisors</Button>
        </div>
      </div>
      <Table
        dataSource={props.workers.map((s) => ({ ...s, key: s.id }))}
        loading={props.isFetching}
        columns={columns}
        bordered
      />
    </div>
  )
}

export default WorkersTable
