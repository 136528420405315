import React, { useState } from 'react'
import { BoardColumn, CardMovedHandler } from 'components/KanbanBoard/KanbanBoard'
import Card from 'components/KanbanBoard/Card'
import styles from './KanbanBoard.module.css'
import classNames from 'classnames'
import { Subscribe } from 'typings/entities'

const Column: React.FC<{
  column: BoardColumn
  onCardMoved: CardMovedHandler
  onCardClick: (subscribe: Subscribe) => void
  onCardMouseDown: () => void
  onCardMouseUp: () => void
}> = (props) => {
  const onDrop: React.DragEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault()
    const data = e.dataTransfer.getData('text/plain')
    if (data !== '') {
      const fromColumn = parseInt(data.split('-')[1])
      const cardId = parseInt(data.split('-')[0])
      props.onCardMoved(fromColumn, props.column.id, cardId)
    }
    setHovered(false)
  }
  const [hovered, setHovered] = useState(false)
  return (
    <div
      className={classNames(styles.column, { [styles.columnHover]: hovered })}
      onDrop={onDrop}
      onDragOver={(e) => e.preventDefault()}
      onDragEnter={() => setHovered(true)}
      onDragLeave={() => setHovered(false)}>
      <div className={styles.columnHead}>
        <h2 className={styles.columnTitle}>{props.column.name}</h2>
      </div>
      {props.column.cards.map((c) => (
        <Card
          key={c.id}
          card={c}
          parentColumn={props.column.id}
          onCardClick={props.onCardClick}
          onCardMouseDown={props.onCardMouseDown}
          onCardMouseUp={props.onCardMouseUp}
        />
      ))}
    </div>
  )
}

export default Column
