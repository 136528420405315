import { ProductVideo } from 'typings/entities'
import { api, BuilderType } from 'api/index'

export const createIssuerProductVideo = (builder: BuilderType) =>
  builder.mutation<ProductVideo, { productId: number; order: number; background_color: string; body: FormData }>({
    query: ({ productId, order, background_color, body }) => ({
      url: '/issuer_product_video',
      params: { product: productId, order, background_color },
      method: 'POST',
      body,
    }),
    onQueryStarted(args, { dispatch, queryFulfilled }) {
      queryFulfilled.then((resp) => {
        const video = resp.data
        dispatch(
          api.util.updateQueryData('getIssuerProduct', args.productId, (product) => {
            product.videos.push(video)
          }),
        )
      })
    },
  })
export const deleteIssuerProductVideo = (builder: BuilderType) =>
  builder.mutation<void, { productId: number; id: number }>({
    query: (args) => ({ url: '/issuer_product_video/' + args.id, method: 'DELETE' }),
    onQueryStarted(args, { dispatch, queryFulfilled }) {
      queryFulfilled.then(() => {
        dispatch(
          api.util.updateQueryData('getIssuerProduct', args.productId, (product) => {
            product.videos = product.videos.filter((a) => a?.id !== args.id)
          }),
        )
      })
    },
  })
export const patchIssuerProductVideoOrder = (builder: BuilderType) =>
  builder.mutation<void, { productId: number; id: number; order: number; background_color: string }>({
    query: ({ id, order, background_color }) => {
      return {
        url: '/issuer_product_video/' + id,
        method: 'PATCH',
        body: {
          order,
          background_color,
        },
      }
    },
    onQueryStarted(args, { dispatch, queryFulfilled }) {
      queryFulfilled.then((resp) => {
        dispatch(
          api.util.updateQueryData('getIssuerProduct', args.productId, (product) => {
            const oldVideo = product.videos.find((c) => c.id === args.id)
            if (oldVideo !== undefined) Object.assign(oldVideo, resp.data)
          }),
        )
      })
    },
  })
