import { useSelector } from 'store'
import { Button, Form, Input, Modal, Skeleton, Spin, Typography } from 'antd'
import { Link } from 'react-router-dom'
import ParamPreview from 'pages/Campaigns/components/ParamPreview'
import React, { useState } from 'react'
import SubscribeAlanytics from 'pages/Kanban/components/SubscribeAlanytics'
import { useDispatch } from 'react-redux'
import { kanbanActions } from 'store/reducers/kanbanReducer'
import { TEXT_COLORS } from 'export/colors'
import { MailOutlined, PhoneOutlined } from '@ant-design/icons'
import { skipToken } from '@reduxjs/toolkit/query'
import { api } from 'api'
import { getImportedClient2 } from 'api/endpoints/importedClientEnpoints'
import useMySellerFirm from 'hooks/useMySellerFirm'
import { useForm } from 'antd/es/form/Form'

const clearPhoneNumber = (s: string) => {
  return s.replace(/-/g, '').replace(/\(/g, '').replace(/\)/g, '').replace(/ /g, '')
}

const UserPhone = ({ userId }: { userId?: number }) => {
  const sellerFirm = useMySellerFirm()
  const importedClient = api.useGetImportedClient2Query(
    {
      //@ts-ignore
      user: userId,
      //@ts-ignore
      seller_firm: sellerFirm?.id,
    },
    { skip: userId === undefined || sellerFirm === undefined },
  )
  if (!userId) return <Skeleton.Button />
  const phone = importedClient.data?.mobile_phone
  return (
    <div style={{ display: 'flex' }}>
      <span style={{ marginRight: 4, color: TEXT_COLORS.black, width: 80 }}>Phone:</span>
      <Typography.Paragraph copyable={!!phone} style={{ marginBottom: 0 }}>
        {phone ?? '-'}
      </Typography.Paragraph>
      {phone && (
        <a href={'tel:' + clearPhoneNumber(phone)} style={{ marginLeft: 4 }}>
          <PhoneOutlined />
        </a>
      )}
    </div>
  )
}

const Comment = ({ userId }: { userId?: number }) => {
  const [isEditing, setIsEditing] = useState(false)
  const sellerFirm = useMySellerFirm()
  const [update, updResp] = api.useUpdateImportedClientMutation()
  const client = api.useGetImportedClient2Query(
    {
      //@ts-ignore
      user: userId,
      //@ts-ignore
      seller_firm: sellerFirm?.id,
    },
    { skip: userId === undefined || sellerFirm === undefined },
  )

  if (!userId) return <Skeleton.Button />
  const comment = client.data?.comment
  const [form] = Form.useForm<{ comment: string }>()

  const onSubmit = async (e: { comment: string }) => {
    update({ comment: e.comment, id: client.data!.id }).finally(() => setIsEditing(false))
  }

  const onCancel = () => {
    form.setFieldsValue({ comment: comment ?? undefined })
    setIsEditing(false)
  }

  return (
    <div style={{ display: 'flex', marginBottom: 8 }}>
      <span style={{ marginRight: 4, color: TEXT_COLORS.black, width: 80, flexShrink: 0 }}>Comment:</span>
      {isEditing ? (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
          <Form form={form} name="form" onFinish={onSubmit} style={{ width: '100%' }}>
            <Form.Item name={'comment'} style={{ width: '100%', marginBottom: 6 }}>
              <Input.TextArea autoSize defaultValue={comment ?? undefined} />
            </Form.Item>
            <div style={{ marginRight: 'auto' }}>
              <Button type={'primary'} htmlType="submit" loading={updResp.isLoading} style={{ marginRight: 6 }}>
                Save
              </Button>
              <Button loading={updResp.isLoading} onClick={onCancel}>
                Cancel
              </Button>
            </div>
          </Form>
        </div>
      ) : (
        <p onClick={() => setIsEditing(true)} style={{ cursor: 'pointer', background: '#eee', padding: 6 }}>
          {comment ? comment : <i>No Comment</i>}
        </p>
      )}
    </div>
  )
}

const KanbanModal = () => {
  const modalState = useSelector((store) => store.kanban)
  const subscribe = modalState.cardModalData?.subscribe
  const user = subscribe?.user
  const product = subscribe?.product
  const dispatch = useDispatch()

  const closeModal = () => {
    dispatch(kanbanActions.setModal({ cardModalVisible: false, cardModalData: undefined }))
  }

  return (
    <Modal
      width={800}
      title={'Subscribe ' + subscribe?.id}
      visible={modalState.cardModalVisible}
      onCancel={closeModal}
      onOk={closeModal}
      destroyOnClose>
      {modalState.cardModalData && (
        <>
          <div style={{ borderBottom: '1px solid #d9d9d9', marginBottom: 10 }}>
            <p style={{ fontWeight: 'bold', color: TEXT_COLORS.black }}>Client:</p>
            <div style={{ display: 'flex' }}>
              <span style={{ marginRight: 4, color: TEXT_COLORS.black, width: 80 }}>Name:</span>
              <Typography.Paragraph style={{ marginBottom: 4 }}>
                {user?.first_name ?? '-'} {user?.last_name ?? '-'}
              </Typography.Paragraph>
            </div>
            <div style={{ display: 'flex' }}>
              <span style={{ marginRight: 4, color: TEXT_COLORS.black, width: 80 }}>Email:</span>
              <Typography.Paragraph copyable style={{ marginBottom: 0 }}>
                {user?.email}
              </Typography.Paragraph>
              {user?.email && (
                <a href={'mailto:' + user.email} style={{ marginLeft: 4 }}>
                  <MailOutlined />
                </a>
              )}
            </div>
            <UserPhone userId={user?.id} />
            <Comment userId={user?.id} />

            {/*<div style={{ display: 'flex' }}>*/}
            {/*  <span style={{ marginRight: 4, color: TEXT_COLORS.black, width: 80 }}>Phone:</span>*/}
            {/*  <Typography.Paragraph copyable style={{ marginBottom: 0 }}>*/}
            {/*    {user?.mobile_phone ?? '-'}*/}
            {/*  </Typography.Paragraph>*/}
            {/*  {user?.phone && <a href={'tel:'+user.mobile_phone} <PhoneOutlined}*/}
            {/*</div>*/}

            {/*<Link to={`tel:${user?.}`}*/}
            {/*<p><Link to={} {modalState.cardModalData.user.email}</p>*/}
          </div>
          {product && (
            <div style={{ borderBottom: '1px solid #d9d9d9', marginBottom: 10 }}>
              <p style={{ fontWeight: 'bold', color: TEXT_COLORS.black }}>Product:</p>
              <p>
                <Link to={'/seller_product/' + product.id}>
                  #{product.id} {product.name}
                </Link>{' '}
              </p>
              {subscribe.params && (
                <div style={{ padding: 8, background: '#ebecf0', borderRadius: 3 }}>
                  <p style={{ fontWeight: 'bold', marginBottom: 0, color: '#172b4d' }}>Additional params:</p>
                  <ParamPreview params={subscribe.params} />
                </div>
              )}
            </div>
          )}
          {user && <SubscribeAlanytics userId={user.id} />}
        </>
      )}
    </Modal>
  )
}

export default KanbanModal
