import React from 'react'
import { api } from 'api/index'
import { Button, Result, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { ProductPicture, SellerProduct, Subscribe, User } from 'typings/entities'
import useMySellerFirm from 'hooks/useMySellerFirm'
import { Link } from 'react-router-dom'
import { DeleteOutlined } from '@ant-design/icons'
import ParamPreview from 'pages/Campaigns/components/ParamPreview'
import { store } from 'store'
import { prettyDate } from 'utils'
import { prettyBool } from 'export/colors'
import { TableProductPicturePreview } from 'pages/SellerProduct/SellerProducts'

export const subscribeColumns: ColumnsType<Subscribe> = [
  {
    dataIndex: 'id',
    title: '#',
    render: (id) => <Link to={'/subscribe/' + id}>{id}</Link>,
  },
  {
    dataIndex: 'product',
    title: 'Picture',
    width: 128,
    render: (product: SellerProduct) => <TableProductPicturePreview images={product.images} />,
  },
  {
    dataIndex: 'product',
    title: 'Product',
    render: (v: SellerProduct) => (
      <Link to={'/seller_product/' + v.id}>
        {v.id} {v.name}
      </Link>
    ),
  },
  { dataIndex: 'user', title: 'User', render: (v: User) => v.username },
  { dataIndex: 'create_at', title: 'User mail', render: (v, row) => row.user.email },
  { dataIndex: 'params', title: 'Params', render: (e) => <ParamPreview params={e} /> },
  {
    dataIndex: 'update_at',
    title: 'Update at',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.update_at - b.update_at,
    render: (v: number) => prettyDate(v),
  },
  { dataIndex: 'is_subscribed', title: 'Subscribed', render: (v) => prettyBool(v) },
  {
    dataIndex: 'is_archived',
    title: 'Archived',
    sorter: (a, b) => (a.is_archived === b.is_archived ? 0 : a.is_archived ? -1 : 1),

    render: (v) => prettyBool(v),
  },
  {
    dataIndex: 'actions',
    title: 'Actions',
    render: (v, row) => {
      return (
        <Button
          size={'small'}
          type={'primary'}
          danger
          icon={<DeleteOutlined />}
          onClick={() => {
            //@ts-ignore
            store.dispatch(api.endpoints.deleteSubscribe.initiate(row.id))
          }}
        />
      )
    },
  },
]

const Subscribes = () => {
  const sellerFirm = useMySellerFirm()
  const subscribes = api.useGetSubscribesQuery(
    { seller_firm: sellerFirm?.id },
    {
      refetchOnMountOrArgChange: true,
    },
  )

  if (!sellerFirm)
    return (
      <Result
        title={
          <p>
            Your account is not connected to any <b>Seller firm</b>
          </p>
        }
        status={'403'}
      />
    )

  return (
    <div>
      <h1 style={{ marginBottom: 30 }}>Client responses</h1>
      <div style={{ display: 'flex', marginBottom: 16 }}>
        <Button onClick={subscribes.refetch} type={'primary'}>
          Reload
        </Button>
      </div>
      <Table columns={subscribeColumns} dataSource={subscribes.data} loading={subscribes.isFetching} rowKey={'id'} />
    </div>
  )
}

export default Subscribes
