import { Button, Form, FormInstance, Input, InputNumber, Row, Switch } from 'antd'
import FormValueString from 'ui/customFormInputs/FormValueString'
import TagsSelect from 'ui/customFormInputs/TagsSelect'
import React from 'react'
import { ImportedClient } from 'typings/entities'
import { prettyBool } from 'export/colors'
import { PARAM_NUMBER_INPUT_STYLE } from 'pages/IssuerProducts/Params'
import SellerFirmSelect from 'ui/customFormInputs/SellerFirmSelect'
import useIsSuperuser from 'hooks/useIsSuperuser'
import TextArea from 'antd/lib/input/TextArea'

type Props = {
  form: FormInstance<Partial<ImportedClient>>
  initialValues: Omit<Partial<ImportedClient>, 'seller_firm' | 'tags'> & { seller_firm?: number; tags?: number[] }
  loading: boolean
  onOk: () => void
}

const ImportedClientForm: React.FC<Props> = (props) => {
  const isSuperUser = useIsSuperuser()

  return (
    <Form form={props.form} initialValues={props.initialValues} labelCol={{ flex: '148px' }} labelAlign={'left'}>
      <Form.Item name={'seller_firm'} label={'Seller firm'}>
        <SellerFirmSelect disabled={!isSuperUser} />
      </Form.Item>
      <Form.Item name={'tags'} label={'Tags'}>
        <TagsSelect />
      </Form.Item>
      <Form.Item name={'account_name'} label={'Account Name'} rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name={'first_name'} label={'First Name'}>
        <Input />
      </Form.Item>
      <Form.Item name={'last_name'} label={'Last Name'}>
        <Input />
      </Form.Item>
      <Form.Item name={'email'} label={'Email'} rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item
        name={'mobile_phone'}
        label={'Mobile Phone'}
        rules={[{ required: true }, { min: 4, message: 'Mobile Phone must be minimum 4 characters.' }]}>
        <Input />
      </Form.Item>
      <Form.Item name={'billing_state'} label={'Billing State'}>
        <Input />
      </Form.Item>
      <Form.Item name={'zip_code'} label={'Zip Code'}>
        <Input />
      </Form.Item>

      <Form.Item name={'household_value'} label={'Household value'}>
        <InputNumber style={PARAM_NUMBER_INPUT_STYLE} />
      </Form.Item>
      <Form.Item name={'household_cash_value'} label={'Household cash value'}>
        <InputNumber style={PARAM_NUMBER_INPUT_STYLE} />
      </Form.Item>
      <Form.Item name={'comment'} label={'Comment'}>
        <TextArea rows={4} />
      </Form.Item>

      <Form.Item name={'accredited_investor'} label={'Accredited investor'} valuePropName={'checked'}>
        <Switch />
      </Form.Item>
      <Form.Item name={'qualified_purchaser'} label={'Qualified purchaser'} valuePropName={'checked'}>
        <Switch />
      </Form.Item>
      <Row justify={'end'}>
        <Button type={'primary'} htmlType={'submit'} onClick={props.onOk} loading={props.loading}>
          Ok
        </Button>
      </Row>
    </Form>
  )
}

export default ImportedClientForm
