import React, { CSSProperties, useState } from 'react'
import { Button, Card, Form, FormInstance, Input, InputNumber, Select } from 'antd'
import { capitalizeFirstLetter } from 'utils'
import { CloseOutlined, PlusOutlined } from '@ant-design/icons/lib'
import { FormListFieldData } from 'antd/es/form/FormList'
import { PARAM_TYPE } from 'typings/dataStructures'
const { Option } = Select

const Options = ({ value = [], onChange }: { value?: string[]; onChange?: (v: string[]) => void }) => {
  const [inputValue, setInputValue] = useState<string>('')

  const addOption = () => {
    const newOptions = [...value, inputValue]
    setInputValue('')
    onChange?.(newOptions)
  }

  const removeOption = (index: number) => {
    const filteredOptions = value.filter((o, ind) => ind !== index)
    onChange?.(filteredOptions)
  }

  return (
    <>
      <div style={{ display: 'flex' }}>
        <Input
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          style={{ width: '100%', marginBottom: 12 }}
        />
        <Button onClick={addOption} style={{ height: '100%', fontSize: 16 }} disabled={inputValue.length < 1}>
          Add
        </Button>
      </div>
      <div style={{ display: 'flex' }}>
        {value.map((option, ind) => (
          <div
            key={option + ind}
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '2px 8px',
              marginRight: 10,
              border: '1px solid #bbbbbb',
            }}>
            <span style={{ marginRight: 6 }}>{option}</span>
            <CloseOutlined onClick={() => removeOption(ind)} />
          </div>
        ))}
      </div>
    </>
  )
}
export const PARAM_NUMBER_INPUT_STYLE: CSSProperties = { width: 200 }
const Param = ({ field, form }: { field: Omit<FormListFieldData, 'key'>; form: FormInstance }) => {
  const [type, setType] = useState<PARAM_TYPE>(form.getFieldValue(['params', field.name, 'type']))

  return (
    <>
      <Form.Item label="Name" name={[field.name, 'name']} rules={[{ required: true, message: 'Please input name!' }]}>
        <Input />
      </Form.Item>
      <Form.Item
        {...field}
        label="Type"
        name={[field.name, 'type']}
        rules={[{ required: true, message: 'Missing type' }]}>
        <Select onChange={(type) => setType(type)}>
          <Option value={PARAM_TYPE.select}>{capitalizeFirstLetter(PARAM_TYPE.select)}</Option>
          <Option value={PARAM_TYPE.slider}>{capitalizeFirstLetter(PARAM_TYPE.slider)}</Option>
          <Option value={PARAM_TYPE.input}>{capitalizeFirstLetter(PARAM_TYPE.input)}</Option>
        </Select>
      </Form.Item>
      {type === PARAM_TYPE.select && (
        <Form.Item
          {...field}
          name={[field.name, 'options']}
          label={'Options'}
          required
          rules={[
            {
              required: true,
              message: 'You must specify at least two options!',
            },
          ]}>
          <Options />
        </Form.Item>
      )}
      {type === PARAM_TYPE.slider && (
        <>
          <Form.Item
            {...field}
            name={[field.name, 'min']}
            label={'Min'}
            rules={[{ required: true, message: 'Please input min value' }]}>
            <InputNumber controls={false} placeholder={'0'} style={PARAM_NUMBER_INPUT_STYLE} />
          </Form.Item>
          <Form.Item
            {...field}
            name={[field.name, 'max']}
            label={'Max'}
            rules={[{ required: true, message: 'Please input max value' }]}>
            <InputNumber controls={false} placeholder={'1000'} style={PARAM_NUMBER_INPUT_STYLE} />
          </Form.Item>
        </>
      )}
    </>
  )
}

const Params = ({ form }: { form: FormInstance }) => {
  return (
    <Form.List name="params">
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, ...field }) => (
            <Card
              key={key}
              title="Parameter"
              style={{ marginBottom: 16 }}
              extra={
                <Button type={'text'} danger onClick={() => remove(field.name)}>
                  Remove
                </Button>
              }>
              <Param field={field} form={form} />
            </Card>
          ))}

          <Form.Item>
            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
              Add new parameter
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  )
}

export default Params
