import { ImportedClientTag, SubscribeStatus } from 'typings/entities'
import { BuilderType } from 'api/index'

export const getImportedClientTags = (builder: BuilderType) =>
  builder.query<ImportedClientTag[], { seller_firm_id?: number }>({
    query: (params) => ({ url: '/imported_client_tags', params }),
    providesTags: [{ id: '1', type: 'ImportedClientTag' }],
  })
export const getImportedClientTag = (builder: BuilderType) =>
  builder.query<ImportedClientTag, number>({
    query: (id) => ({ url: '/imported_client_tag/' + id }),
    providesTags: (result) => {
      console.log('result!!', result)
      if (!result) return []
      return [{ id: result.id, type: 'ImportedClientTagSpecific' }]
    },
  })
export const createImportedClientTag = (builder: BuilderType) =>
  builder.mutation<ImportedClientTag, Partial<SubscribeStatus>>({
    query: (data) => ({ url: '/imported_client_tag', method: 'POST', body: data }),
    invalidatesTags: [{ id: '1', type: 'ImportedClientTag' }],
  })
export const updateImportedClientTag = (builder: BuilderType) =>
  builder.mutation<ImportedClientTag, { id: number; data: Partial<SubscribeStatus> }>({
    query: ({ id, data }) => ({ url: '/imported_client_tag/' + id, method: 'PATCH', body: data }),
    invalidatesTags: (result) => {
      if (!result) return []
      return [
        { id: '1', type: 'ImportedClientTag' },
        { id: result.id, type: 'ImportedClientTagSpecific' },
      ]
    },
  })
export const deleteImportedClientTag = (builder: BuilderType) =>
  builder.mutation<ImportedClientTag, number>({
    query: (id) => ({ url: '/imported_client_tag/' + id, method: 'DELETE' }),
    invalidatesTags: [{ id: '1', type: 'ImportedClientTag' }],
  })
