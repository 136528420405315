import React from 'react'
import styles from './UploadPicture.module.scss'
import { message, Upload } from 'antd'
import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import { UploadFile } from 'antd/es/upload/interface'
import { UploadChangeParam } from 'antd/lib/upload'
import deleteIcon from 'assets/img/delete.svg'
import classNames from 'classnames'

interface IFile {
  id: number
  url: string
  background_color?: string
}

type MultipleUploadProps = {
  value?: ((File & { background_color?: string }) | IFile)[]
  onChange?: (value: (File | IFile)[]) => void
  backColorInput?: boolean
}

const UploadMultiplePictures = ({ value = [], ...props }: MultipleUploadProps) => {
  const onChange = (e: UploadChangeParam<UploadFile>) => {
    const newValue: (File | IFile)[] = []
    e.fileList.forEach((f: any) => {
      if ('url' in f) newValue.push(f as IFile)
      else if (/.*\.(jpg|jpeg|png)/.test(f.name)) {
        if ('originFileObj' in f) newValue.push(f.originFileObj as File)
        else newValue.push(f as unknown as File)
      } else message.error('Invalid file extension')
    })
    props.onChange?.(newValue)
  }

  const onDelete = (index: number) => {
    props.onChange?.(value.filter((_, i) => i !== index))
  }

  const onBackColorChange = (i: number, color?: string) => {
    if (color !== undefined) value[i].background_color = color
    else value[i].background_color = 'eeeeee'
    props.onChange?.([...value])
  }

  function allowDrop(ev: any) {
    ev.preventDefault()
  }

  function drag(e: React.DragEvent, index: number) {
    e.dataTransfer.setData('text', index.toString())
  }

  function drop(e: React.DragEvent, index: number) {
    e.preventDefault()
    const old_index = Number(e.dataTransfer.getData('text'))
    value.splice(index, 0, value.splice(old_index, 1)[0])
    props.onChange?.([...value])
  }

  return (
    <div className={styles.container}>
      <div className={styles.images}>
        {value.map((f, i) => {
          const src = 'url' in f ? f.url : URL.createObjectURL(f)
          return (
            <div key={i} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 8 }}>
              <div
                className={styles.imageContainer}
                //@ts-ignore
                style={{
                  padding: '4px 0',
                  marginBottom: 6,
                  backgroundColor: f.background_color ? '#' + f.background_color : '#eeeeee',
                }}>
                <img src={src} alt={'img'} className={styles.image} onDrop={(e) => drop(e, i)} onDragOver={allowDrop} />
                <div
                  className={classNames([styles.btnsContainer, styles.draggable])}
                  draggable={'true'}
                  onDragStart={(e) => drag(e, i)}>
                  <button className={styles.deleteBtn} onClick={() => onDelete(i)} type={'button'} title={'Delete'}>
                    <img src={deleteIcon} alt={'Delete'} className={styles.deleteIcon} />
                  </button>
                </div>
              </div>
              {props.backColorInput && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    type={'color'}
                    value={'background_color' in f ? '#' + f.background_color : '#eeeeee'}
                    onChange={(e) => onBackColorChange(i, e.target.value.replace('#', ''))}
                    style={{ cursor: 'pointer' }}
                  />
                  {f.background_color && f.background_color !== 'eeeeee' && (
                    <CloseOutlined style={{ marginLeft: 4, cursor: 'pointer' }} onClick={() => onBackColorChange(i)} />
                  )}
                </div>
              )}
            </div>
          )
        })}
      </div>
      <Upload
        accept="image/png, image/jpeg"
        showUploadList={false}
        multiple={true}
        //@ts-ignore
        fileList={value}
        listType="picture-card"
        beforeUpload={() => false}
        onChange={onChange}>
        <PlusOutlined />
      </Upload>
    </div>
  )
}

type SingleUploadProps = {
  value?: File | IFile
  onChange?: (value: File | IFile | undefined) => void
}

const UploadSinglePicture = ({ value, ...props }: SingleUploadProps) => {
  const src = value ? ('url' in value ? value.url : URL.createObjectURL(value)) : undefined

  const onChange = (e: UploadChangeParam<UploadFile>) => {
    if ('url' in e.file || /.*\.(jpg|jpeg|png)/.test(e.file.name)) {
      props.onChange?.(e.file as unknown as File)
    } else message.error('Invalid file extension')
  }

  const onDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    props.onChange?.(undefined)
  }

  return (
    <Upload
      accept="image/png, image/jpeg"
      showUploadList={false}
      //@ts-ignore
      fileList={value ? [value] : []}
      listType="picture-card"
      beforeUpload={() => false}
      onChange={onChange}>
      {value ? (
        <div className={styles.imageContainer} onClick={(e) => e.stopPropagation()}>
          <img src={src} alt={'img'} className={styles.image} />
          <div className={styles.btnsContainer}>
            <button className={styles.deleteBtn} onClick={onDelete} type={'button'} title={'Delete'}>
              <img src={deleteIcon} alt={'Delete'} className={styles.deleteIcon} />
            </button>
          </div>
        </div>
      ) : (
        <PlusOutlined />
      )}
    </Upload>
  )
}

type ContainerProps = ({ multiple: true } & MultipleUploadProps) | ({ multiple?: false } & SingleUploadProps)

const UploadPictureContainer = (props: ContainerProps) => {
  if (props.multiple) return <UploadMultiplePictures {...props} />
  else return <UploadSinglePicture {...props} />
}

export default UploadPictureContainer
