import { PARAM_TYPE } from 'typings/dataStructures'
import { AnalyticFilterType } from '../api'

export type Error = {
  error: string
}

// this is product category (Mammoot tooth)
export type Category = {
  id: number
  name: string
}

export type Firm = {
  id: number
  name: string
  avatar_url: string | null
  background_url: string | null
  description: string
}

export type FirmPost = Omit<Firm, 'id' | 'avatar_url' | 'background_url'>

export type User = {
  id: number
  username: string
  first_name: string
  last_name: string
  issuer_firm: null | Firm
  seller_firm: null | Firm
  email: string
  token: string
  email_confirmed: boolean
  autogenerated: boolean
  qualified_purchaser: boolean
  accredited_investor: boolean
  is_superuser: boolean
  is_staff: boolean
}

export type PostRegister = {
  password: string
  first_name: string
  username: string
  last_name: string
  email: string
}

export interface IFile {
  id: number
  url: string
}

export type ProductPicture = IFile & {
  order: number
  background_color: string
}

export type ProductVideo = IFile & {
  order: number
  url_preview: string
  background_color: string
}

export type ProductAttachment = IFile & {
  order: number
  name: string
}

export enum CHART_TYPE {
  bar = 'bar',
  pie = 'pie',
  scatter = 'scatter',
}

export enum CHART_AXIS {
  date = 'date',
  float = 'float',
  string = 'string',
}

export type Chart = {
  id: number
  title: string
  x_label: string
  x_type: CHART_AXIS
  y_label: string
  y_type: CHART_AXIS
  order: number
  chart_type: CHART_TYPE
  chart: {
    x: (string | number)[]
    y: number[]
  }
}

export type Product = {
  id: number
  name: string
  creator: Omit<User, 'token'>
  issuer_firm: Firm
  short_description: string
  description: string
  params: {
    param: 1
  }
  price: number
  price_name?: string
  categories: Category[]
  images: ProductPicture[]
  videos: ProductVideo[]
  attachments: ProductAttachment[]
  charts: Chart[]
  seller_firm_product_id: number
  seller_firm: Firm
  open_date: null | number
  close_date: null | number
  capacity_available: null | number
  approved: boolean
  order: number
}

export type PostProduct = {
  name: string
  short_description: string
  description: string
  params: any
  price: number
  categories: number[]
  issuer_firm: number
  approved: boolean
}
export type PostProductOrder = {
  [key: string]: number
}
export type SellerProduct = Product & {
  issuer_firm_product_id: number
  id: number
  seller_firm: Firm
  order: number
  seller_product_create_at: number
}

export type CampaignTemplatePreset = {
  id: number
  name: string
}

export type CampaignTemplatePresetFull = {
  id: number
  name: string
  template: string
}

// importedClientTag tag!!!
export type ImportedClientTag = {
  readonly id: number
  name: string
}

export type ImportedClient = {
  id: number
  seller_firm: Firm
  tags: ImportedClientTag[]
  account_name: string
  comment: string | null
  first_name: string
  last_name: string
  email: string
  mobile_phone: string
  household_value: number
  household_cash_value: number
  billing_state: string
  zip_code: string
  last_modified_date: number
  user: User | null
  accredited_investor: boolean
  qualified_purchaser: boolean
}

export type ImportedClientSource = {
  id: number
  name: string
}

export type Campaign = {
  readonly id: number
  name: string
  dt_from: string
  dt_to: string
  template: string
  advisor: number | null
  advisor_group: number | null
  seller_firm: number | null
  client_tags: number[]
  products: number[]
  clients: number[]
  readonly create_at: number
  readonly update_at: number
  is_email_sent: boolean
  is_push_sent: boolean
  emails_send_count: number
  push_send_count: number
}

export type SubscribeParamInput = { name: string; type: PARAM_TYPE.input; value: string }
export type SubscribeParamSelect = { name: string; type: PARAM_TYPE.select; value: number | string }
export type SubscribeParamSlider = { name: string; type: PARAM_TYPE.slider; value: number }

export type SubscribeParam = SubscribeParamInput | SubscribeParamSelect | SubscribeParamSlider

export type Subscribe = {
  readonly id: number
  user: User
  product: SellerProduct
  params: SubscribeParam[] | null
  campaign: Campaign
  status: number
  update_at: number
  create_at: number
  is_subscribed?: number
  is_archived?: number
}

export type SubscribeStatus = {
  id: number
  name: string
  name_for_user: string
  color: string
  seller_firm: number
  order: number
}

export type Statistic = {
  id: number
  user: number
  issuer_product: SellerProduct | null
  issuer_firm: Firm | null
  category: Category | null
  video: (ProductVideo & { issuer_product: { id: number; name?: string } }) | null
  duration: number //seconds float
  create_at: number //timestamp seconds float
}

export type Role = {
  name: string
  id: number
}
export type AdvisorRole = {
  advisor: number
  id: number
  role: number
}

export type CountryCode = {
  country: string
  states: string[]
}

export type AnalyticFilterPresetValueType = {
  seller_firm: number
  date?: [string, string]
  activity_level?: [number, number]
  favourite_category?: number
  email?: string
  country?: string
  state?: string
  accredited_investor?: boolean
  qualified_purchaser?: boolean
  household_value?: [number, number]
  household_cash_value?: [number, number]
  billing_state?: string
  zip_code?: string
}
export type AnalyticFilterPresetType = {
  id: number
  name: string
  seller_firm: number
  filters: AnalyticFilterPresetValueType[]
  create_at: number
  update_at: number
}

// analytic/analytic_user_watch
export type AnalyticUserWatch = {
  id: number
  user: number
  issuer_product: Product | null
  issuer_firm: Firm | null
  category: Category | null
  video: ProductVideo | null
  duration: number
  create_at: number
}

export type UsersFilter = {
  account_name: string
  accredited_investor: boolean
  billing_state: string
  comment: null | string
  email: string
  email_unsubscribed_till: null | any
  first_name: string
  household_cash_value: number
  household_value: number
  id: number
  is_email_unsubscribed: boolean
  last_modified_date: string
  last_name: string
  mobile_phone: string
  qualified_purchaser: boolean
  seller_firm: number
  tags: any[]
  user: User
  zip_code: string
}
