import { User } from 'typings/entities'
import { BuilderType } from 'api/index'

export const getIssuerFirmCreators = (builder: BuilderType) =>
  builder.query<User[], void>({
    query: () => ({ url: '/issuer_firm/creators' }),
    providesTags: [{ id: 'all', type: 'IssuerFirmCreators' }],
  })
export const createIssuerFirmCreator = (builder: BuilderType) =>
  builder.mutation<{ status: string }, { email: string }>({
    query: (data) => ({ url: '/issuer_firm/creator', method: 'POST', body: data }),
    invalidatesTags: (resp) => (resp ? [{ id: 'all', type: 'IssuerFirmCreators' }] : []),
  })
export const deleteIssuerFirmCreator = (builder: BuilderType) =>
  builder.mutation<{ status: string }, number>({
    query: (id) => ({ url: '/issuer_firm/creator/' + id, method: 'DELETE' }),
    invalidatesTags: (resp) => (resp ? [{ id: 'all', type: 'IssuerFirmCreators' }] : []),
  })
