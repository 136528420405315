import { Form, message, Modal } from 'antd'
import classNames from 'classnames'
import React from 'react'
import AnalyticFilterForm from './AnalyticFilterForm'
import styles from './AnalyticFilter.module.scss'
import Input from 'antd/lib/input/Input'
import { api } from 'api/api'
type PropsType = {
  visible: boolean
  className?: string
  onCancel?: () => void
  initialValues?: any
  onSuccess?: (id: number) => void
}
const AnalyticFilterModal: React.FC<PropsType> = ({ visible, onCancel, className, initialValues, onSuccess }) => {
  const [req, res] = api.useCreateFilterPresetMutation()
  const [analyticForm] = Form.useForm()
  const [form] = Form.useForm()
  const onFinish = ({ name }: { name: string }) => {
    req({
      name,
      filters: analyticForm.getFieldsValue(),
    }).then((r) => {
      if ('data' in r) {
        message.success('Added new filter configuration')
        onSuccess?.(r.data.id)
        onCancel?.()
      }
      if ('error' in r) {
        //@ts-ignore
        message.error(r.error.data.error)
      }
    })
  }
  return (
    <Modal
      title="Add new filter configuration"
      visible={visible}
      onOk={() => form.submit()}
      onCancel={onCancel}
      okButtonProps={{ loading: res.isLoading }}>
      <Form onFinish={onFinish} form={form} layout="vertical">
        <Form.Item
          name="name"
          label="Configuration name"
          rules={[{ required: true, message: 'Please input configuration name!' }]}>
          <Input />
        </Form.Item>
      </Form>
      <AnalyticFilterForm
        form={analyticForm}
        className={className}
        initialValues={initialValues}
        classNameItem={styles.formItem}
      />
    </Modal>
  )
}
export default AnalyticFilterModal
