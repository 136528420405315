import { message, Upload } from 'antd'
import React, { useState } from 'react'
import * as api from 'api/index'
import { InboxOutlined } from '@ant-design/icons'
import ClientSourceSelect from 'ui/customFormInputs/ClientSourceSelect'

type Props = {
  onUploadEnd?: (arg: { [key: string]: string | number }[]) => void
}

const ImportContactsButton: React.FC<Props> = (props) => {
  const [l, sl] = useState(false)
  const [selectedSource, setSelectedSource] = useState<number>()
  const upload = async (f: File) => {
    if (!selectedSource) return
    sl(true)
    const formData = new FormData()
    formData.set('file', f)
    const resp = await api.contactsUploadCSV(formData, selectedSource)
    console.log(resp)
    if (resp) {
      props.onUploadEnd?.(resp)
    } else {
      message.error('Bad CSV format')
    }
    sl(false)
  }
  return (
    <>
      <ClientSourceSelect
        allowClear
        value={selectedSource}
        onChange={setSelectedSource}
        style={{ width: 200, marginBottom: 8 }}
        placeholder={'Select import source'}
      />
      <br />
      <a href={'https://wealthback.cecure.io/admin/app/importsource/'} style={{ marginBottom: 10 }}>
        Edit import sources
      </a>
      {selectedSource && (
        <Upload.Dragger
          disabled={selectedSource === undefined}
          showUploadList={false}
          listType={undefined}
          // fileList={fileList}
          accept={'/upload_product_csv'}
          onDrop={async (e) => {
            e.preventDefault()
            if (e.dataTransfer.files[0]) {
              upload(e.dataTransfer.files[0])
            }
          }}
          beforeUpload={upload}>
          {/*<Button loading={l} icon={<UploadOutlined />} disabled={selectedSource === undefined}>*/}
          {/*  Import from file*/}
          {/*</Button>*/}
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
          {/*<p className="ant-upload-hint">*/}
          {/*  Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files*/}
          {/*</p>*/}
        </Upload.Dragger>
      )}
    </>
  )
}

export default ImportContactsButton
