import { Select } from 'antd'
import { CSSProperties } from 'react'
import { api } from 'api/index'
import React from 'react'

type Props = {
  value?: number
  onChange?: (arg?: number) => void
  style?: CSSProperties
  disabled?: boolean
  allowClear?: boolean
}

const RoleSelect: React.FC<Props> = (props) => {
  const roles = api.useGetRolesQuery()

  return (
    <Select
      loading={roles.isLoading}
      options={roles.data?.map((r) => ({ value: r.id, label: r.name }))}
      value={props.value}
      onChange={props.onChange}
      style={props.style}
      disabled={props.disabled}
      allowClear={props.allowClear}
    />
  )
}

export default RoleSelect
