import React, { useEffect } from 'react'
import { Button, Form, Input, message } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import { api } from 'api/index'
import UITexts from 'export/UITexts'
import styles from './Login.module.css'

type RegisterForm = {
  email: string
  first_name: string
  username: string
  last_name: string
  password: string
  confirm: string
}

const Register = () => {
  const navigate = useNavigate()
  const [registerRequest, registerResponse] = api.useRegisterMutation()
  const [form] = Form.useForm<RegisterForm>()

  const onSubmit = (values: RegisterForm) => {
    registerRequest({
      password: values.password,
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      username: values.username,
    })
  }

  useEffect(() => {
    if (registerResponse.isError) {
      message.error(UITexts.register.ERROR)
    }
    if (registerResponse.isSuccess) {
      if ('error' in registerResponse.data) {
        const error = registerResponse.data.error
        if (error.startsWith('duplicate key value violates unique constraint "app_user_username_key"')) {
          message.error(UITexts.register.EMAIL_IS_ALREADY_REGISTERED)
        } else message.error(error)
      } else navigate('/')
    }
  }, [registerResponse])

  return (
    <div className={styles.container}>
      <Form
        form={form}
        className={styles.form}
        onFinish={onSubmit}
        layout="vertical"
        name="register"
        scrollToFirstError>
        <Form.Item
          name="email"
          label="E-mail"
          rules={[
            { type: 'email', message: 'The input is not valid E-mail!' },
            { required: true, message: 'Please input your E-mail!' },
          ]}>
          <Input placeholder="E-mail" />
        </Form.Item>
        <Form.Item name="username" label="Username" rules={[{ required: true, message: 'Please input username!' }]}>
          <Input placeholder="Username" />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
          hasFeedback>
          <Input.Password placeholder="Password" />
        </Form.Item>
        <Form.Item
          name="confirm"
          label="Confirm Password"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please confirm your password!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error('The two passwords that you entered do not match!'))
              },
            }),
          ]}>
          <Input.Password placeholder="Confirm Password" />
        </Form.Item>
        <Form.Item
          name="first_name"
          label="First name"
          rules={[{ required: true, message: 'Please input your first name!' }]}>
          <Input />
        </Form.Item>
        <Form.Item
          name="last_name"
          label="Last name"
          rules={[{ required: true, message: 'Please input your last name!' }]}>
          <Input />
        </Form.Item>

        <Form.Item>
          <Button className={styles.login} type="primary" htmlType="submit">
            Register
          </Button>
          Or <Link to={'/login'}>login</Link>
        </Form.Item>
      </Form>
    </div>
  )
}

export default Register
