import React from 'react'
import { Button, Form, Input, InputNumber, message, Result, Row } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import useMySellerFirm from 'hooks/useMySellerFirm'
import UITexts from 'export/UITexts'
import FormValueString from 'ui/customFormInputs/FormValueString'
import { ImportedClientTag } from 'typings/entities'
import { api } from 'api/index'

const CreateImportedClientTag = () => {
  const [form] = Form.useForm<ImportedClientTag>()
  const sellerFirm = useMySellerFirm()
  const [mutate, resp] = api.useCreateImportedClientTagMutation()
  const navigate = useNavigate()
  const onOk = async () => {
    const values = await form.validateFields()
    console.log(values)

    if (!values) return
    const resp = await mutate(values)
    if (resp) {
      message.success(UITexts.ImportedClientTag.CREATE_SUCCESS)

      navigate('/imported_client_tag/all')
    } else {
      message.error(UITexts.ImportedClientTag.CREATE_ERROR)
    }
  }
  if (!sellerFirm)
    return (
      <Result
        title={
          <p>
            Your account is not connected to any <b>Seller firm</b>
          </p>
        }
        status={'403'}
      />
    )
  return (
    <div>
      <Row justify={'space-between'} align={'middle'}>
        <h1>Create Imported Client Tag</h1>
        <Link to={'/imported_client_tag/all'}>All tags</Link>
      </Row>
      <Form form={form} initialValues={{ seller_firm: sellerFirm.id }}>
        <Form.Item name={'name'} label={'Name'} rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name={'seller_firm'} label={'Seller firm'} style={{ display: 'none' }}>
          <FormValueString />
        </Form.Item>
        <Button htmlType={'submit'} onClick={onOk} loading={resp.isLoading}>
          Ok
        </Button>
      </Form>
    </div>
  )
}

export default CreateImportedClientTag
