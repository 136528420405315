import React, { useState } from 'react'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import { CHART_AXIS } from 'typings/entities'
import { Popover, Switch } from 'antd'
import { SettingFilled, SettingOutlined } from '@ant-design/icons'

type Props = {
  title: string
  data: [string | number, number | null][]
  x_label: string
  y_label: string
  y_type: CHART_AXIS
  x_type: CHART_AXIS
}
const ScatterChart: React.FC<Props> = (props) => {
  const [ordinal, setOrdinal] = useState(false)
  let data = props.data
  if (props.x_type === CHART_AXIS.date) {
    //@ts-ignore
    data = data.map(([ts, y]) => [(ts as number) * 1000, y]).sort((a, b) => a[0] - b[0])
  }
  // console.log('data', data)
  const ys = props.data.map(([x, y]) => y)
  const xDate = props.x_type === CHART_AXIS.date
  const yString = props.y_type === CHART_AXIS.string
  const options: Highcharts.Options = {
    legend: { enabled: false },
    chart: { type: 'scatter' },
    title: { text: props.title },
    xAxis: {
      type: xDate ? 'datetime' : undefined,
      ordinal: xDate ? ordinal : undefined,
      title: { text: props.x_label },
      startOnTick: true,
      endOnTick: true,
      // dateTimeLabelFormats: {
      //   // month: '%m/%d/%y',
      // },
      showLastLabel: true,
    },
    yAxis: {
      title: { text: props.y_label },
      categories: yString ? (ys as unknown[] as string[]) : undefined,
    },
    tooltip: { valueDecimals: 1 },
    plotOptions: {
      line: {
        tooltip: {
          xDateFormat: '%m/%d/%Y',
        },
        lineWidth: 1,
        states: {
          hover: {
            lineWidth: 1,
          },
        },
        marker: {
          radius: 0,
        },
      },
      scatter: {
        marker: {
          radius: 2,
        },
      },
    },
    series: [
      {
        name: 'Series',
        type: xDate ? 'line' : 'scatter',
        data: yString ? data.map((e) => [e[0], ys.indexOf(e[1])]) : data,
      },
    ],
  }

  return (
    <div style={{ position: 'relative' }}>
      <HighchartsReact highcharts={Highcharts} options={options} constructorType={'chart'} style={{ width: '100%' }} />
      <Popover
        // getPopupContainer={(e) => e.parentNode as HTMLElement}
        placement={'bottomRight'}
        trigger={'click'}
        content={
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: 8 }}>
            <div style={{ display: 'flex', columnGap: 8, alignItems: 'center' }}>
              <span style={{ fontSize: '12px', lineHeight: '12px' }}>Ordinal:</span>
              <Switch size={'small'} checked={ordinal} onChange={(e) => setOrdinal(e)} />
            </div>
          </div>
        }>
        <SettingFilled style={{ position: 'absolute', right: 5, top: 5, color: '#c5c5c5' }} />
      </Popover>
    </div>
  )
}

export default ScatterChart
