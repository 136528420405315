import React, { useRef, useState } from 'react'
import { Button, Col, ConfigProvider, Dropdown, Layout, Menu, Modal } from 'antd'
import { LogoutOutlined, MenuOutlined, ProfileOutlined, UserOutlined } from '@ant-design/icons'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { ChromePicker, ColorChangeHandler } from 'react-color'
import Logo from 'assets/img/logo.png'
import { store, useSelector } from 'store'
import styles from './HeaderNavBar.module.css'
import { useDispatch } from 'react-redux'
import { appActions } from 'store/reducers/appReducer'
import { User } from 'typings/entities'
import { profileActions } from 'store/reducers/profileReducer'
const { Header } = Layout

const sellerButtons = [
  { label: 'Dashboard', link: '/dashboard' },
  { label: 'Campaigns', link: '/campaigns' },
  { label: 'Clients', link: '/imported_client/all' },
  { label: 'Products', link: '/seller_product/all' },
  { label: 'Subscribes', link: '/subscribes/all' },
  { label: 'Settings', link: '/settings' },
  { label: 'Analytics', link: '/analytic' },
]

const issuerButtons = [{ label: 'Products', link: '/issuer_products' }]

const allButtons = [
  { label: 'Dashboard', link: '/dashboard' },
  { label: 'Campaigns', link: '/campaigns' },
  { label: 'Issuer products', link: '/issuer_products' },
  { label: 'Seller products', link: '/seller_product/all' },
  { label: 'Clients', link: '/imported_client/all' },
  { label: 'Subscribes', link: '/subscribes/all' },
  { label: 'Settings', link: '/settings' },
  { label: 'Analytics', link: '/analytic' },
]

const getBtns = (user: User | undefined): { label: string; link: string }[] => {
  if (user === undefined) return []
  const isIssuer = user.issuer_firm !== null
  const isSeller = user.seller_firm !== null
  if (isIssuer && isSeller) return allButtons
  if (isIssuer) return issuerButtons
  if (isSeller) return sellerButtons
  return []
}

const getSelectedPage = (pathname: string, btns: ReturnType<typeof getBtns>) => {
  const curPage = btns.find((btn) => location.pathname.includes(btn.link))?.link
  if (curPage) return [curPage]
  return []
}

export const changeTheme = (c: string) => {
  ConfigProvider.config({
    theme: {
      primaryColor: c,
      // primaryColor: '#008300',
      // errorColor: '#ff0000',
      // successColor: '#00ff00',
      // infoColor: '#ffff00',
      // processingColor: '#00ffff',
      // warningColor: '#e34242',
    },
  })
}

const ColorPicker = (props: { value?: string; onChange?: (arg: string) => void }) => {
  const [color, setColor] = useState<string>(props.value ?? '#1890ff')
  const onChange: ColorChangeHandler = (e) => {
    setColor(e.hex)
    props.onChange?.(e.hex)
  }
  return (
    // <BlockPicker
    //   colors={['#1890ff', '#ff8a65', '#0aa679', '#7546c9', '#f43e53']}
    //   color={props.value ?? color}
    //   onChangeComplete={(e) => {
    //     setColor(e.hex);
    //     props.onChange?.(e.hex);
    //   }}
    // />
    <ChromePicker
      color={color}
      onChange={onChange}
      // onChangeComplete={(e) => {
      //   setColor(e.hex);
      //   props.onChange?.(e.hex);
      // }}
    />
  )
}

const UserMenu = () => {
  const dispatch = useDispatch()
  const color = useSelector((store) => store.app.themeColor)
  const [showThemeModal, setShowThemeModal] = useState(false)
  const selectedColor = useRef('#1890ff')
  const navigate = useNavigate()
  const onResetTheme = () => {
    store.dispatch(appActions.setThemeColor('#1890ff'))
    closeModal()
  }

  const onColorChange = (color: string) => (selectedColor.current = color)
  const onSaveTheme = () => {
    dispatch(appActions.setThemeColor(selectedColor.current))
    changeTheme(selectedColor.current)
    closeModal()
  }
  const closeModal = () => {
    setShowThemeModal(false)
  }

  const clickHandler = (e: any) => {
    switch (e.key) {
      case 'theme':
        setShowThemeModal(true)
        break
      case 'logout':
        dispatch(profileActions.removeUser())
        navigate('/login')
        break
      default:
        break
    }
  }

  return (
    <>
      <Menu mode="vertical" className={styles.userMenu} onClick={clickHandler} selectedKeys={[]}>
        <Menu.Item key={'profile'} icon={<ProfileOutlined />}>
          <Link to={'/profile'}>Profile</Link>
        </Menu.Item>
        {/*<Menu.Item key={'theme'} icon={<BgColorsOutlined />}>*/}
        {/*  Theme*/}
        {/*</Menu.Item>*/}
        <Menu.Item key={'logout'} icon={<LogoutOutlined />}>
          Logout
        </Menu.Item>
      </Menu>
      <Modal
        visible={showThemeModal}
        closable={false}
        width={225}
        maskStyle={{ background: 'rgba(0, 0, 0, 0.2)' }}
        onCancel={() => setShowThemeModal(false)}
        footer={
          <>
            <Button onClick={onResetTheme}>Reset</Button>
            <Button type="primary" onClick={onSaveTheme}>
              OK
            </Button>
          </>
        }>
        <ColorPicker onChange={onColorChange} value={color} />
      </Modal>
    </>
  )
}

const HeaderNavBar = () => {
  const location = useLocation()
  const user = useSelector((store) => store.profile.user)
  const isAuth = user !== undefined
  let btns = getBtns(user)
  const selectedPage = getSelectedPage(location.pathname, btns)

  if (user && user.is_staff === false) {
    btns = []
  }

  return (
    <Header className={styles.header}>
      <div className={styles.headerContent}>
        <Link to="/" className={styles.logoLink}>
          <img className={styles.logo} src={Logo} alt={''} />
        </Link>
        {isAuth && (
          <>
            <Col xl={20} lg={20} md={18} sm={18} xs={4} style={{ marginRight: 'auto', height: '100%' }}>
              <Menu
                mode="horizontal"
                triggerSubMenuAction={'click'}
                style={{ height: '100%' }}
                selectedKeys={selectedPage}
                overflowedIndicator={<MenuOutlined style={{ fontSize: 16 }} />}>
                {btns.map((btn) => (
                  <Menu.Item key={btn.link}>
                    <Link to={btn.link}>{btn.label}</Link>
                  </Menu.Item>
                ))}
                {user?.is_superuser && (
                  <Menu.Item key={'/create_user'}>
                    <Link to={'/create_user'}>Create user</Link>
                  </Menu.Item>
                )}
              </Menu>
            </Col>
            {/*{}*/}
            <Dropdown overlay={<UserMenu />} placement="bottomRight" trigger={['click']}>
              <button className={styles.userMenuBtn}>
                <p className={styles.username}>{user.username}</p>
                <UserOutlined style={{ marginLeft: '8px', fontSize: 16 }} />
              </button>
            </Dropdown>
          </>
        )}
      </div>
    </Header>
  )
}

export default HeaderNavBar
