import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Firm, User } from 'typings/entities'

type ProfileState = {
  user?: User
}

const initialState: ProfileState = {
  user: undefined,
}

const slice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setUser: (state, { payload: user }: PayloadAction<User>) => {
      state.user = user
    },
    updateUser: (state, { payload: user }: PayloadAction<Partial<User>>) => {
      // @ts-ignore
      Object.entries(user).forEach(([key, value]) => (state.user[key] = value))
    },
    removeUser: (state) => {
      state.user = undefined
    },
    setIssuerFirm: (state, action: PayloadAction<Firm | null>) => {
      if (state.user) {
        state.user.issuer_firm = action.payload
      }
    },
    setIssuerFirmAvatar: (state, action: PayloadAction<string>) => {
      if (state.user?.issuer_firm) {
        state.user.issuer_firm.avatar_url = action.payload
      }
    },
    setIssuerFirmBackground: (state, action: PayloadAction<string>) => {
      if (state.user?.issuer_firm) {
        state.user.issuer_firm.background_url = action.payload
      }
    },
    setSellerFirm: (state, action: PayloadAction<Firm | null>) => {
      if (state.user) {
        state.user.seller_firm = action.payload
      }
    },
    setSellerFirmAvatar: (state, action: PayloadAction<string>) => {
      if (state.user?.seller_firm) {
        state.user.seller_firm.avatar_url = action.payload
      }
    },
    setSellerFirmBackground: (state, action: PayloadAction<string>) => {
      if (state.user?.seller_firm) {
        state.user.seller_firm.background_url = action.payload
      }
    },
  },
})

export const profileActions = slice.actions
export const profileReducer = slice.reducer
