import React, { useState } from 'react'
import { Spin } from 'antd'

const UserActivityMapWithJson = React.lazy(() => import('./UserActivityMapWithJson'))
type Props = {
  sellerFirm: number
}

const MapContainer: React.FC<Props> = (props) => {
  return (
    <React.Suspense fallback={<Spin />}>
      <UserActivityMapWithJson sellerFirm={props.sellerFirm} />
    </React.Suspense>
  )
}

export default MapContainer
