import React from 'react'
import styles from './UploadAttachments.module.scss'
import { message, Upload } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { UploadFile } from 'antd/es/upload/interface'
import { UploadChangeParam } from 'antd/lib/upload'
import deleteIcon from 'assets/img/delete.svg'
import classNames from 'classnames'
import { ProductAttachment } from '../../../typings/entities'
import fileIcon from 'assets/img/file.svg'

type Props = {
  value?: (File | ProductAttachment)[]
  onChange?: (value: (File | ProductAttachment)[]) => void
}

const UploadAttachments = ({ value = [], ...props }: Props) => {
  const onChange = (e: UploadChangeParam<UploadFile>) => {
    const newValue: (File | ProductAttachment)[] = []
    e.fileList.forEach((f: any) => {
      if ('url' in f) newValue.push(f as ProductAttachment)
      else if (/.*\.(doc|html|pdf|txt|xml)/.test(f.name)) {
        if ('originFileObj' in f) newValue.push(f.originFileObj as File)
        else newValue.push(f as unknown as File)
      } else message.error('Invalid file extension')
    })
    props.onChange?.(newValue)
  }

  const onDelete = (index: number) => {
    props.onChange?.(value.filter((_, i) => i !== index))
  }

  function allowDrop(ev: any) {
    ev.preventDefault()
  }

  function drag(e: React.DragEvent, index: number) {
    e.dataTransfer.setData('text', index.toString())
  }

  function drop(e: React.DragEvent, index: number) {
    const old_index = Number(e.dataTransfer.getData('text'))
    value.splice(index, 0, value.splice(old_index, 1)[0])
    props.onChange?.([...value])
  }

  return (
    <div className={styles.container}>
      <div className={styles.attachments}>
        {value.map((f, i) => {
          return (
            <div
              key={i}
              className={styles.attachmentContainer}
              style={{ marginRight: 8 }}
              onDrop={(e) => drop(e, i)}
              onDragOver={allowDrop}>
              <img src={fileIcon} alt={'img'} className={styles.attachment} />
              <p className={styles.attachmentName}>{f.name}</p>
              <div
                className={classNames([styles.btnsContainer, styles.draggable])}
                draggable={'true'}
                onDragStart={(e) => drag(e, i)}>
                <button className={styles.deleteBtn} onClick={() => onDelete(i)} type={'button'} title={'Delete'}>
                  <img src={deleteIcon} alt={'Delete'} className={styles.deleteIcon} />
                </button>
              </div>
            </div>
          )
        })}
      </div>
      <Upload
        accept=".doc,.html,.pdf,.txt,.xml"
        showUploadList={false}
        multiple={true}
        //@ts-ignore
        fileList={value}
        listType="picture-card"
        beforeUpload={() => false}
        onChange={onChange}>
        <PlusOutlined />
      </Upload>
    </div>
  )
}

export default UploadAttachments
