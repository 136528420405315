import { Firm, FirmPost } from 'typings/entities'
import { profileActions } from 'store/reducers/profileReducer'
import { BuilderType } from 'api/index'

export const getIssuerFirms = (builder: BuilderType) =>
  builder.query<Firm[], void>({
    query: () => ({ url: '/issuer_firms' }),
    providesTags: [{ id: 'all', type: 'IssuerFirm' }],
  })
export const getIssuerFirm = (builder: BuilderType) =>
  builder.query<Firm, number>({
    query: (id) => ({ url: '/issuer_firm/' + id }),
    providesTags: (resp) => (resp ? [{ id: resp.id, type: 'IssuerFirm' }] : []),
  })
export const createIssuerFirm = (builder: BuilderType) =>
  builder.mutation<Firm, FirmPost>({
    query: (data) => ({ url: '/issuer_firm', method: 'POST', body: data }),
    onQueryStarted(id, { dispatch, queryFulfilled }) {
      queryFulfilled.then((resp) => {
        dispatch(profileActions.setIssuerFirm(resp.data))
      })
    },
  })
export const updateIssuerFirm = (builder: BuilderType) =>
  builder.mutation<Firm, { id: number } & FirmPost>({
    query: ({ id, ...body }) => ({ url: '/issuer_firm/' + id, method: 'PATCH', body }),
    onQueryStarted(id, { dispatch, queryFulfilled }) {
      queryFulfilled.then((resp) => {
        dispatch(profileActions.setIssuerFirm(resp.data))
      })
    },
  })
export const deleteIssuerFirm = (builder: BuilderType) =>
  builder.mutation<{}, number>({
    query: (id) => ({ url: '/issuer_firm/' + id, method: 'DELETE' }),
    onQueryStarted(id, { dispatch, queryFulfilled }) {
      queryFulfilled.then(() => dispatch(profileActions.setIssuerFirm(null)))
    },
  })
