import { Button, Checkbox, Form, InputNumber, message, Select, Switch, Table, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { DeleteTwoTone, EditTwoTone, MenuOutlined } from '@ant-design/icons'
import { number2currency } from 'utils'
import { Category, Product, ProductPicture } from 'typings/entities'
import { useSelector } from 'store'
import { api } from 'api/index'
import { TableProductPicturePreview } from 'pages/SellerProduct/SellerProducts'
import { ColumnProps, ColumnsType } from 'antd/es/table'
import CollapseText from 'ui/CollapseText'
import UITexts from 'export/UITexts'
import IssuerFirmSelect from 'ui/customFormInputs/IssuerFirmSelect'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import { arrayMoveImmutable } from 'array-move'
import Order from './Order'

interface DraggableContainerProps extends React.HTMLAttributes<HTMLElement> {
  children: React.ReactNode
}
interface DragHandleProps extends React.HTMLAttributes<HTMLElement> {
  children: React.ReactNode
  ['data-row-key']: number
  onClick: () => void
}

export const filterReorderProductsData = (
  data: Product[],
  oldIndex: number,
  newIndex: number,
): { newData: Product[]; postData: { [key: string]: number } } => {
  const newData = arrayMoveImmutable(data, oldIndex, newIndex)
    .filter((el) => !!el)
    .map((e, i) => ({ ...e, order: i }))
  const postData = Object.fromEntries(newData.map((e, i) => [e.id, i]))
  return { newData, postData }
}

const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />)
const SortableItem = SortableElement((props: DragHandleProps) => <tr {...props} />)
const SortableBody = SortableContainer((props: DraggableContainerProps) => <tbody {...props} />)

const ApproveProduct: React.FC<{ id: number; approved: boolean }> = ({ id, approved }) => {
  const [update, resp] = api.useUpdateIssuerProductMutation()
  const onChange = async (v: boolean) => {
    const resp = await update({ id, product: { approved: v } })
    if ('data' in resp) {
    } else {
      message.error(UITexts.IssuerProduct.UPDATE_ERROR)
    }
  }
  return <Switch checked={approved} onChange={onChange} loading={resp.isLoading} />
}
const DeleteIssuerProdictButton: React.FC<{ id: number }> = ({ id }) => {
  const [del, resp] = api.useDeleteIssuerProductMutation()
  return (
    <Tooltip title="Delete product" mouseEnterDelay={1} color={'#DDD'} overlayInnerStyle={{ color: '#444' }}>
      <Button
        ghost
        loading={resp.isLoading}
        onClick={() => del(id)}
        icon={<DeleteTwoTone twoToneColor="#FF0000" style={{ fontSize: '20px' }} />}
      />
    </Tooltip>
  )
}
const EditIssuerProductButton: React.FC<{ id: number }> = ({ id }) => {
  return (
    <Tooltip title="Edit product" mouseEnterDelay={1} color={'#DDD'} overlayInnerStyle={{ color: '#444' }}>
      <Link to={'/issuer_product/' + id} style={{ marginRight: 16 }}>
        <Button icon={<EditTwoTone style={{ fontSize: '20px' }} />} ghost />
      </Link>
    </Tooltip>
  )
}

const columns: ColumnProps<Product>[] = [
  {
    title: 'Sort',
    dataIndex: 'sort',
    width: 30,
    className: 'drag-visible',
    render: () => <DragHandle />,
  },
  {
    dataIndex: 'order',
  },
  {
    title: 'Picture',
    dataIndex: 'images',
    render: (images: ProductPicture[]) => <TableProductPicturePreview images={images} />,
    width: 128,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    render: (v, row) => <Link to={'/issuer_product/' + row.id}>{v}</Link>,
  },
  {
    title: 'Category',
    dataIndex: 'categories',
    key: 'categories',
    render: (categories: Category[]) => categories.map((c) => c.name).join(', '),
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
    render: (price: number) => number2currency(price),
  },
  {
    title: 'Description',
    dataIndex: 'short_description',
    render: (v) => <CollapseText text={v} />,
  },
  {
    dataIndex: 'approved',
    title: 'Approved',
    render: (v, row) => <ApproveProduct id={row.id} approved={row.approved} />,
  },
  {
    dataIndex: 'expire_date',
    title: 'Expires',
    render: (v) => (v ? new Date(v * 1000).toLocaleDateString() : '-'),
  },

  {
    title: 'Actions',
    dataIndex: '',
    key: 'x',
    render: (product: Product) => {
      return (
        <div style={{ display: 'flex' }}>
          <EditIssuerProductButton id={product.id} />
          <DeleteIssuerProdictButton id={product.id} />
        </div>
      )
    },
  },
]

const Products = () => {
  const navigate = useNavigate()
  const profile = useSelector((store) => store.profile)
  const [deleteMany] = api.useDeleteIssuerProductsMutation()
  const [approvedOnly, setApprovedOnly] = useState(false)
  const [withExpired, setWithExpired] = useState(false)
  const [issuerFirm, setIssuerFirm] = useState(profile.user?.issuer_firm?.id)
  const {
    data: products,
    isFetching,
    refetch: refetchProducts,
  } = api.useGetIssuerProductsQuery(
    {
      issuer_firm: issuerFirm,
      approved_only: Number(approvedOnly) as 0 | 1,
      with_expired: Number(withExpired) as 0 | 1,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  )
  const [mutateReorderProducts, res] = api.useReorderIssuerProductMutation()
  const reorderProducts = async (body: { [key: string]: number }, newData: Product[]) => {
    setDataSource(newData)
    const resp = await mutateReorderProducts(body)
    if ('data' in resp) {
      message.success(UITexts.IssuerProduct.UPDATE_ORDER_SUCCESS)
    } else {
      message.error(UITexts.IssuerProduct.UPDATE_ORDER_SUCCESS)
    }
  }
  const [dataSource, setDataSource] = useState<Product[]>([])
  const [selectedProducts, setSelectedProducts] = useState<number[]>([])
  useEffect(() => {
    if (products) {
      setDataSource(products)
    }
  }, [products])
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedProducts(selectedRowKeys as number[])
    },
  }

  const onSelectionAction = (values: any) => {
    switch (values.action) {
      case 'delete':
        deleteSelected()
        break
    }
  }

  const deleteSelected = async () => {
    const resp = await deleteMany({ ids: selectedProducts })
    if ('data' in resp) {
      setSelectedProducts([])
    } else {
      message.error(UITexts.IssuerProduct.DELETE_ERROR)
    }
  }
  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    if (oldIndex !== newIndex) {
      const { newData, postData } = filterReorderProductsData(dataSource, oldIndex, newIndex)
      reorderProducts(postData, newData)
    }
  }
  const DraggableContainer = (props: DraggableContainerProps) => {
    return <SortableBody useDragHandle disableAutoscroll helperClass="row-dragging" onSortEnd={onSortEnd} {...props} />
  }
  const DraggableBodyRow = ({ className, style, ...restProps }: any) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource?.findIndex((x) => x.order === restProps['data-row-key'])
    return <SortableItem index={index} {...restProps} />
  }

  const getCols = () => {
    const newCols = columns.map((col) => {
      if (col.dataIndex === 'order') {
        return {
          ...col,
          title: 'Order',
          render: (value: number) => <Order value={value} data={{ dataSource, setDataSource }} />,
        }
      }
      return col
    })
    return newCols
  }
  return (
    <div>
      <h1 style={{ marginBottom: 30 }}>Products</h1>
      <div style={{ display: 'flex', marginBottom: 16 }}>
        <Button onClick={refetchProducts} type={'primary'}>
          Reload
        </Button>
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: 4, columnGap: 4 }}>
          <IssuerFirmSelect
            style={{ width: 200 }}
            value={issuerFirm}
            onChange={setIssuerFirm}
            disabled={profile?.user?.is_superuser === false}
          />
          <span>Approved only:</span>
          <Checkbox checked={approvedOnly} onChange={(e) => setApprovedOnly(e.target.checked)} />
          <span>Show expired:</span>
          <Checkbox checked={withExpired} onChange={(e) => setWithExpired(e.target.checked)} />
        </div>
        <Button
          onClick={() => navigate('/new_product')}
          type={'primary'}
          style={{ marginLeft: 'auto', marginRight: 16 }}>
          New product
        </Button>
        <Form layout={'inline'} onFinish={onSelectionAction} initialValues={{ action: 'delete' }}>
          <Form.Item label={`Selected ${selectedProducts.length}:`} name={'action'} style={{ display: 'flex' }}>
            <Select>
              <Select.Option value={'delete'}>Delete</Select.Option>
            </Select>
          </Form.Item>
          <Button htmlType={'submit'} disabled={selectedProducts.length < 1}>
            Go
          </Button>
        </Form>
      </div>
      <Table
        size={'small'}
        dataSource={dataSource}
        loading={isFetching}
        columns={getCols()}
        rowSelection={rowSelection}
        bordered
        rowKey="order" //накодил баг на варнинг кей ))
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
      />
    </div>
  )
}

export default Products
