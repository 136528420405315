import React, { useEffect, useState } from 'react'
import styles from './UploadVideos.module.scss'
import { Button, Upload } from 'antd'
import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import { UploadFile } from 'antd/es/upload/interface'
import deleteIcon from 'assets/img/delete.svg'
import classNames from 'classnames'
import { ProductVideo } from '../../../typings/entities'
import videoIcon from '../../../assets/img/video.svg'

type Props = {
  value?: ({ file: any; preview: any; background_color: string } | ProductVideo)[]
  onChange?: any
  backColorInput?: boolean
}

const PreviewVideos = ({ value = [], ...props }: Props) => {
  const onDelete = (index: number) => {
    props.onChange?.(value.filter((_, i) => i !== index))
  }

  const onBackColorChange = (i: number, color?: string) => {
    if (color !== undefined) value[i].background_color = color
    else value[i].background_color = '000000'
    props.onChange?.([...value])
  }

  function allowDrop(ev: any) {
    ev.preventDefault()
  }

  function drag(e: React.DragEvent, index: number) {
    e.dataTransfer.setData('text', index.toString())
  }

  function drop(e: React.DragEvent, index: number) {
    e.preventDefault()
    const old_index = Number(e.dataTransfer.getData('text'))
    value.splice(index, 0, value.splice(old_index, 1)[0])
    props.onChange?.([...value])
  }

  return (
    <div className={styles.previews}>
      {value.map((f, i) => {
        const src = 'id' in f ? f.url_preview : URL.createObjectURL(f.preview)
        return (
          <div key={i} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 8 }}>
            <div
              className={styles.previewContainer}
              onDrop={(e) => drop(e, i)}
              onDragOver={allowDrop}
              style={{
                padding: '4px 0',
                backgroundColor: f.background_color ? '#' + f.background_color : '#000000',
              }}>
              <img src={src} alt={'video'} className={styles.previewImg} />
              <img src={videoIcon} alt={'video'} className={styles.previewVideoIcon} />
              <div
                className={classNames([styles.btnsContainer, styles.draggable])}
                draggable={'true'}
                onDragStart={(e) => drag(e, i)}>
                <button className={styles.deleteBtn} onClick={() => onDelete(i)} type={'button'} title={'Delete'}>
                  <img src={deleteIcon} alt={'Delete'} className={styles.deleteIcon} />
                </button>
              </div>
            </div>
            {props.backColorInput && (
              <div style={{ display: 'flex', alignItems: 'center', marginTop: 6 }}>
                <input
                  type={'color'}
                  value={'background_color' in f ? '#' + f.background_color : '#000000'}
                  onChange={(e) => onBackColorChange(i, e.target.value.replace('#', ''))}
                  style={{ cursor: 'pointer' }}
                />
                {f.background_color && f.background_color !== '000000' && (
                  <CloseOutlined style={{ marginLeft: 4, cursor: 'pointer' }} onClick={() => onBackColorChange(i)} />
                )}
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}

const UploadVideos = ({ value = [], ...props }: Props) => {
  const [video, setVideo] = useState<UploadFile>()
  const [preview, setPreview] = useState<UploadFile>()

  const onResetSelectedVideo = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation()
    setVideo(undefined)
  }

  const onResetSelectedPreview = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation()
    setPreview(undefined)
  }

  useEffect(() => {
    if (video !== undefined && preview !== undefined) {
      setVideo(undefined)
      setPreview(undefined)
      props.onChange?.([{ file: video, preview }, ...value])
    }
  }, [video, preview])

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      <div style={{ display: 'flex', flexDirection: 'column', marginRight: 8 }}>
        <Upload accept="video/*" showUploadList={false} beforeUpload={() => false} onChange={(e) => setVideo(e.file)}>
          <div style={{ width: 100, textAlign: 'left', marginBottom: 10 }}>
            {video === undefined ? (
              <Button icon={<PlusOutlined />} style={{ width: 100, textAlign: 'left' }}>
                video
              </Button>
            ) : (
              <div className={styles.fileName} onClick={onResetSelectedVideo}>
                {video.name}
              </div>
            )}
          </div>
        </Upload>
        <Upload
          accept="image/png, image/jpeg"
          showUploadList={false}
          beforeUpload={() => false}
          onChange={(e) => setPreview(e.file)}>
          {preview === undefined ? (
            <>
              <Button icon={<PlusOutlined />} style={{ width: 100, textAlign: 'left' }}>
                preview
              </Button>
              {value?.length === 0 && video !== undefined && (
                <span style={{ marginLeft: 10, color: '#FF5555' }}>(preview required)</span>
              )}
            </>
          ) : (
            <div className={styles.fileName} onClick={onResetSelectedPreview}>
              {preview.name}
            </div>
          )}
        </Upload>
      </div>
      <PreviewVideos {...props} value={value} />
    </div>
  )
}

export default UploadVideos
