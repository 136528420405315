import React from 'react'
import { api } from 'api/index'
import WorkersTable from '../../components/WorkersTable'

const Creators = () => {
  const { data: creators, isFetching, refetch } = api.useGetIssuerFirmCreatorsQuery()
  const [addCreator] = api.useCreateIssuerFirmCreatorMutation()
  const [removeCreator] = api.useDeleteIssuerFirmCreatorMutation()

  const onAddNewCreator = (email: string) => {
    addCreator({ email })
  }

  const onRemoveCreator = (id: number) => {
    removeCreator(id)
  }

  return (
    <div>
      <h2>Creators</h2>
      <WorkersTable
        workers={creators ?? []}
        onRefetch={refetch}
        onAddNewWorker={onAddNewCreator}
        onRemoveWorker={onRemoveCreator}
        isFetching={isFetching}
      />
    </div>
  )
}

export default Creators
