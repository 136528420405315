import { Subscribe } from 'typings/entities'
import { RootState } from 'store'
import { BuilderType, api } from 'api/index'

export const getSubscribes = (builder: BuilderType) => {
  return builder.query<
    Subscribe[],
    { seller_firm?: number; campaign?: number; is_subscribed?: number; is_archived?: number; advisor?: number }
  >({
    query: (params) => ({ url: '/subscribes', params }),
    providesTags: [{ id: 'All', type: 'Subscribe' }],
  })
}

export const getSubscribe = (builder: BuilderType) => {
  return builder.query<Subscribe, number>({
    query: (id) => ({ url: '/subscribe/' + id }),
    providesTags: (result) => {
      if (result) {
        return [{ id: result.id, type: 'Subscribe' }]
      }
      return []
    },
  })
}

export const updateSubscribe = (builder: BuilderType) =>
  builder.mutation<
    Subscribe,
    { id: number; data: Partial<Pick<Subscribe, 'status' | 'is_archived' | 'is_subscribed'>>; seller_firm?: number }
  >({
    query: ({ id, data }) => ({ url: '/subscribe/' + id, method: 'PATCH', body: data }),
    invalidatesTags: (result, _, data) => {
      if (result) {
        return [{ id: 'All', type: 'Subscribe' }]
      }
      return []
    },
    async onQueryStarted({ id, data, seller_firm }, { dispatch, queryFulfilled, getState }) {
      console.log('onQueryStarted', id, data, seller_firm)
      const patchResults: any = []
      for (const { endpointName, originalArgs } of api.util.selectInvalidatedBy(getState(), [
        { id: 'All', type: 'Subscribe' },
      ])) {
        if (endpointName !== 'getSubscribes') continue
        console.log(endpointName, originalArgs)
        patchResults.push(
          dispatch(
            api.util.updateQueryData(endpointName, originalArgs, (draft) => {
              console.log(JSON.parse(JSON.stringify(draft)))
              const s = draft.find((s) => s.id === id)
              console.log(s)
              if (s) {
                Object.assign(s, data)
              }
            }),
          ),
        )

        if (originalArgs.is_archived === 1) api.util.invalidateTags
      }
      try {
        console.log('try!')
        await queryFulfilled
      } catch {
        console.log('catch!')
        for (const patchResult of patchResults) {
          patchResult.undo()
        }
      }
    },
  })
export const deleteSubscribe = (builder: BuilderType) =>
  builder.mutation<unknown, number>({
    query: (id) => ({ url: '/subscribe/' + id, method: 'DELETE' }),
    invalidatesTags: (res, _, id) => [{ id, type: 'Subscribe' }],
    async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
      const seller_firm = (getState() as RootState).profile.user?.seller_firm?.id
      const patchResult = dispatch(
        api.util.updateQueryData('getSubscribes', { seller_firm }, (draft) => {
          const index = draft.findIndex((s) => s.id === id)
          if (index !== -1) {
            draft.splice(index, 1)
          }
        }),
      )
      try {
        await queryFulfilled
      } catch {
        patchResult.undo()
      }
    },
  })
