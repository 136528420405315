import React from 'react'
import { Link } from 'react-router-dom'
import styles from './Home/Home.module.css'
import useIsSuperuser from 'hooks/useIsSuperuser'

const Home = () => {
  const isSuperuser = useIsSuperuser()
  return (
    <div className={styles.container}>
      {/*<h1 className={styles.title}>View for product sales team/advisors/product creators</h1>*/}
      <ul>
        {/*<li>*/}
        {/*  <Link to={'/'}>Home</Link>*/}
        {/*</li>*/}
        {/*<li>*/}
        {/*  <Link to={'/profile'}>Profile</Link>*/}
        {/*</li>*/}
        {/*<li>*/}
        {/*  <Link to={'/campaigns'}>Campaigns</Link>*/}
        {/*</li>*/}
        {/*<li>*/}
        {/*  <Link to={'/import_clients'}>Import clients</Link>*/}
        {/*</li>*/}
        {/*<li>*/}
        {/*  <Link to={'/imported_client/all'}>Imported clients</Link>*/}
        {/*</li>*/}
        {/*<li>*/}
        {/*  <Link to={'/seller_product/new'}>Add seller products</Link>*/}
        {/*</li>*/}
        {/*<li>*/}
        {/*  <Link to={'/seller_product/all'}>Seller products</Link>*/}
        {/*</li>*/}
        {/*<li>*/}
        {/*  <Link to={'/subscribes/all'}>Subscribes</Link>*/}
        {/*</li>*/}
        {/*<li>*/}
        {/*  <Link to={'/kanban'}>Kanban</Link>*/}
        {/*</li>*/}

        {isSuperuser && (
          <li>
            <Link to={'/subscribe_status/all'}>Subscribe status settings</Link>
          </li>
        )}
        <li>
          <Link to={'/imported_client_tag/all'}>Imported client tag settings</Link>
        </li>
      </ul>
    </div>
  )
}

export default Home
