import { put, call, takeEvery, all, takeLatest } from 'redux-saga/effects'
// import { store } from '../index'
// import * as api from 'api'
// import { goalsActions } from '../reducers/goalsReducer'

export function* getCampaigns() {
  // yield put(goalsActions.setIsFetchGoals(true))
  // yield put(goalsActions.setFetching(true))
  // const resp = yield call(api.getGoals)
  //
  // if (resp.data) {
  //   yield put(goalsActions.setGoals(resp.data))
  // } else {
  //   yield put(goalsActions.setGoals(null))
  // }
  // yield put(goalsActions.setFetching(false))
}

export default function* gatewaySaga() {
  yield all([takeLatest('campaign/getCampaigns', getCampaigns)])
}
