import React from 'react'
import { api } from 'api/index'
import WorkersTable from '../../components/WorkersTable'

const Sellers = () => {
  const { data: sellers, isFetching, refetch } = api.useGetSellerFirmSellersQuery()
  const [addSeller] = api.useCreateSellerFirmSellerMutation()
  const [removeSeller] = api.useDeleteSellerFirmSellerMutation()

  const onAddNewSeller = (email: string) => {
    addSeller({ email })
  }

  const onRemoveSeller = (id: number) => {
    removeSeller(id)
  }

  return (
    <div>
      <h2>Advisors</h2>
      <WorkersTable
        workers={sellers ?? []}
        onRefetch={refetch}
        onAddNewWorker={onAddNewSeller}
        onRemoveWorker={onRemoveSeller}
        isFetching={isFetching}
      />
    </div>
  )
}

export default Sellers
