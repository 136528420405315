import { BuilderType } from 'api/index'
import { Campaign, CampaignTemplatePreset, CampaignTemplatePresetFull } from 'typings/entities'

export const getCampaignTemplatePresets = (builder: BuilderType) =>
  builder.query<CampaignTemplatePreset[], void>({
    query: () => '/campaign_template_presets',
  })
export const getCampaignTemplatePresetFull = (builder: BuilderType) =>
  builder.query<CampaignTemplatePresetFull, number>({
    query: (id) => '/campaign_template_preset/' + id,
  })
export const getCampaigns = (builder: BuilderType) =>
  builder.query<Campaign[], void>({
    query: () => '/campaigns',
    //@ts-ignore
    providesTags: [{ id: '1', type: 'Campaign' }],
  })
export const getCampaign = (builder: BuilderType) =>
  builder.query<Campaign, number>({
    query: (id) => '/campaign/' + id,
  })
export const pushCampaignMobile = (builder: BuilderType) =>
  builder.query<{ status_code: 200; reason: 'OK'; count: number }, { id: number }>({
    query: (params) => ({ url: '/notification/push/' + params.id }),
  })
export const pushCampaignEmail = (builder: BuilderType) =>
  builder.query<{ status_code: 200; reason: 'OK'; count: number }, { id: number }>({
    query: (params) => ({ url: '/notification/email/' + params.id }),
  })
export const updateCampaign = (builder: BuilderType) =>
  builder.mutation<Campaign, { id: number; data: Partial<Campaign> }>({
    query: ({ id, data }) => ({ url: '/campaign/' + id, method: 'PATCH', body: data }),
  })
export const createCampaign = (builder: BuilderType) =>
  builder.mutation<Campaign, Partial<Campaign>>({
    query: (data) => ({ url: '/campaign', method: 'POST', body: data }),
    //@ts-ignore
    invalidatesTags: [{ id: '1', type: 'Campaign' }],
  })
export const deleteCampaign = (builder: BuilderType) =>
  builder.mutation<Campaign, number>({
    query: (id) => ({ url: '/campaign/' + id, method: 'DELETE' }),
    //@ts-ignore
    invalidatesTags: [{ id: '1', type: 'Campaign' }],
  })
export const getTemplatePreview = (builder: BuilderType) =>
  builder.mutation<{ text: string }, { id: number; text: string }>({
    query: ({ id, text }) => ({ url: '/notification/template_preview/' + id, method: 'POST', body: { text } }),
  })
export const getTemplatePrettify = (builder: BuilderType) =>
  builder.mutation<{ text: string }, { text: string }>({
    query: ({ text }) => ({ url: '/notification/template_prettify', method: 'POST', body: { text } }),
  })
export const getAnalyticCampaignEmailCount = (builder: BuilderType) =>
  builder.query<{ count: number }, number>({
    query: (id) => ({ url: `/analytic/campaign/email/${id}/count` }),
  })
export const getAnalyticCampaignPushCount = (builder: BuilderType) =>
  builder.query<{ count: number }, number>({
    query: (id) => ({ url: `/analytic/campaign/push/${id}/count` }),
  })
