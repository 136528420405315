import { PostRegister, User } from 'typings/entities'
import { profileActions } from 'store/reducers/profileReducer'
import { BuilderType } from 'api/index'
import md5 from 'md5'

export const PASSWORD_SALT = '4048b685204f7638ec07e56d0f7115e8'
import { WithId } from 'typings/dataStructures'
import { message } from 'antd'

type Response<T> = T | { error: string }

export const getTokenForCode = (builder: BuilderType) =>
  builder.mutation<User, { email: string; code: string }>({
    query: (body) => ({ url: '/reset_password_code_confirmation', method: 'POST', body }),
  })

export const resetPassword = (builder: BuilderType) =>
  builder.mutation<unknown, { email: string }>({
    query: (body) => ({ url: '/reset_password', method: 'POST', body }),
  })

export const login = (builder: BuilderType) =>
  builder.mutation<Response<User>, { username: string; password: string }>({
    query: (body) => {
      return {
        url: '/login',
        method: 'POST',
        body: {
          ...body,
          password: md5(body.password + PASSWORD_SALT),
          email: body.username,
        },
      }
    },
    async onQueryStarted(arg, { queryFulfilled, dispatch }) {
      queryFulfilled.then((r) => {
        if (!('error' in r.data)) {
          if (r.data.is_staff === false) {
            message.error('Only staff accounts allowed')
          } else {
            dispatch(profileActions.setUser(r.data))
          }
        }
      })
    },
  })

export const logout = (builder: BuilderType) =>
  builder.query<any, void>({
    query: () => '/logout',
    async onQueryStarted(arg, { queryFulfilled, dispatch }) {
      queryFulfilled.then((r) => {
        if (r.data.error === undefined) {
          dispatch(profileActions.removeUser())
        }
      })
    },
  })

export const register = (builder: BuilderType) =>
  builder.mutation<Response<User>, PostRegister>({
    query: (body) => {
      return {
        url: '/register',
        method: 'POST',
        body,
      }
    },
    async onQueryStarted(arg, { queryFulfilled, dispatch }) {
      queryFulfilled.then((r) => {
        if (!('error' in r.data)) {
          dispatch(profileActions.setUser(r.data))
        }
      })
    },
  })

export const createUser = (builder: BuilderType) =>
  builder.mutation<any, Partial<User>>({
    query: (body) => {
      return {
        url: '/user',
        method: 'POST',
        body,
      }
    },
  })

export const getUserInfo = (builder: BuilderType) =>
  builder.query<Omit<User, 'token'>, void>({
    query: () => '/user',
    async onQueryStarted(arg: void, { queryFulfilled, dispatch }) {
      queryFulfilled.then((r) => {
        dispatch(profileActions.updateUser(r.data))
      })
    },
  })

export const getUsers = (builder: BuilderType) =>
  builder.query<Omit<User, 'token'>[], { seller_firm?: number }>({
    query: () => '/users',
  })

export const getUser = (builder: BuilderType) =>
  builder.query<Omit<User, 'token'>, number>({
    query: (id) => '/user_by_id/' + id,
  })

export const updateUser = (builder: BuilderType) =>
  builder.mutation<Omit<User, 'token'>, Partial<User>>({
    query: (body) => ({ url: '/user', method: 'PATCH', body }),
    async onQueryStarted(arg, { queryFulfilled, dispatch }) {
      queryFulfilled.then((r) => {
        dispatch(profileActions.updateUser(r.data))
      })
    },
  })

export const changePassword = (builder: BuilderType) =>
  builder.mutation<Omit<User, 'token'>, { password: string; authToken?: string }>({
    query: (body) => ({
      url: '/change_password',
      method: 'PATCH',
      body,
      headers: {
        authorization: body.authToken,
      },
    }),
  })

export const updateSpecificUser = (builder: BuilderType) =>
  builder.mutation<Omit<User, 'token'>, WithId<User & { password?: string }>>({
    query: ({ id, ...body }) => ({ url: '/user/' + id, method: 'PATCH', body }),
  })
