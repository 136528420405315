import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SellerProduct, Subscribe, User } from 'typings/entities'
type CardModalData = {
  subscribe: Subscribe
}
type KanbanState = {
  cardModalVisible: boolean
  cardModalData?: CardModalData
}

const initialState: KanbanState = {
  cardModalVisible: false,
  cardModalData: undefined,
}

const slice = createSlice({
  name: 'kaban',
  initialState,
  reducers: {
    setModal: (
      state,
      action: PayloadAction<{ cardModalData: undefined | CardModalData; cardModalVisible: boolean }>,
    ) => {
      state.cardModalData = action.payload.cardModalData
      state.cardModalVisible = action.payload.cardModalVisible
    },
  },
})

export const { actions: kanbanActions, reducer: kanbanReducer } = slice
