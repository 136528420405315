import React, { useEffect, useRef } from 'react'
import { Layout, ConfigProvider } from 'antd'
import { Routes, Route, Link, Navigate } from 'react-router-dom'
import { store, useSelector } from 'store'
import HeaderNavBar, { changeTheme } from 'components/HeaderNavBar/HeaderNavBar'
import Router from './Router'
import { api } from 'api/index'
import Kanban from 'pages/Kanban/Kanban'
import './App.css'
import sslIcon from 'assets/img/ssl-secure.png'

const { Content, Footer } = Layout

const App = () => {
  api.useGetUserInfoQuery()
  const themeColor = useSelector((store) => store.app.themeColor)
  const firstRender = useRef(true)

  useEffect(() => {
    document.body.style.setProperty('--color-primary', themeColor)
  }, [themeColor])

  if (firstRender.current) {
    firstRender.current = false
    changeTheme(store.getState().app.themeColor)
  }

  return (
    <ConfigProvider>
      <Layout className="layout">
        <HeaderNavBar />

        <Routes>
          {/*TODO fix routing!*/}
          <Route key={'/dashboard'} path={'/dashboard'} element={<Kanban />} />
          <Route
            key={'/?'}
            path={'*'}
            element={
              <Content className="layout-content">
                <Router />
              </Content>
            }
          />
        </Routes>
        <Footer className="footer">
          <div className={'footerContent'}>
            <img src={sslIcon} alt={'SSL Secure Connection'} style={{ width: 110 }} />
            <p style={{ color: '#aaa', margin: 0, lineHeight: 0 }}>version: {process.env.REACT_APP_VERSION}</p>
          </div>
        </Footer>
      </Layout>
    </ConfigProvider>
  )
}

export default App
