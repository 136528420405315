import React from 'react'

import { Select } from 'antd'
import useMySellerFirm from 'hooks/useMySellerFirm'
import { api } from 'api/api'
type Props = {
  onChange?: (arg: number[]) => void
  value?: number[]
}
const TagsSelect: React.FC<Props> = (props) => {
  const sellerFirm = useMySellerFirm()
  const tags = api.useGetImportedClientTagsQuery(
    { seller_firm_id: sellerFirm?.id },
    { skip: !sellerFirm, refetchOnMountOrArgChange: true },
  )
  return (
    <Select
      loading={tags.isFetching}
      allowClear
      placeholder={'Select tags'}
      style={{ width: 200 }}
      mode={'multiple'}
      options={tags.data?.map((t) => ({ value: t.id, label: t.name }))}
      {...props}
    />
  )
}

export default TagsSelect
