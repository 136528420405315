import React from 'react'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import { CHART_AXIS } from 'typings/entities'

type Props = {
  title: string
  data: [string | number, number | null][]
  x_label: string
  y_label: string
  y_type: CHART_AXIS
  x_type: CHART_AXIS
}
const PieChart: React.FC<Props> = (props) => {
  const options: Highcharts.Options = {
    chart: { type: 'pie' },
    title: { text: props.title },
    // xAxis: {
    //   title: { text: props.x_label },
    //   startOnTick: true,
    //   endOnTick: true,
    //   showLastLabel: true,
    //   categories: props.data.map((d) => d[0]) as string[],
    //   labels: { rotation: -90 },
    // },
    // yAxis: { title: { text: props.y_label } },
    series: [
      {
        name: 'Series',
        type: 'pie',
        colorByPoint: true,
        data: props.data.map((p) => ({ name: p[0], y: p[1] })),
        // [

        //   {
        //   name: 'Chrome',
        //   y: 61.41,
        //   sliced: true,
        //   selected: true
        // }
        // ],
      },
    ],
  }

  return (
    <HighchartsReact highcharts={Highcharts} options={options} constructorType={'chart'} style={{ width: '100%' }} />
  )
}

export default PieChart
