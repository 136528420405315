import { ProductAttachment } from 'typings/entities'
import { BuilderType, api } from 'api/index'

export const createIssuerProductAttachment = (builder: BuilderType) =>
  builder.mutation<ProductAttachment, { productId: number; order: number; body: FormData }>({
    query: ({ productId, order, body }) => ({
      url: `issuer_product_attachment?product=${productId}&order=${order}`,
      method: 'POST',
      body,
    }),
    onQueryStarted(args, { dispatch, queryFulfilled }) {
      queryFulfilled.then((resp) => {
        const attachment = resp.data
        dispatch(
          api.util.updateQueryData('getIssuerProduct', args.productId, (product) => {
            product.attachments.push(attachment)
          }),
        )
      })
    },
  })
export const deleteIssuerProductAttachment = (builder: BuilderType) =>
  builder.mutation<void, { productId: number; id: number }>({
    query: (args) => ({ url: '/issuer_product_attachment/' + args.id, method: 'DELETE' }),
    onQueryStarted(args, { dispatch, queryFulfilled }) {
      queryFulfilled.then(() => {
        dispatch(
          api.util.updateQueryData('getIssuerProduct', args.productId, (product) => {
            product.attachments = product.attachments.filter((a) => a?.id !== args.id)
          }),
        )
      })
    },
  })
export const patchIssuerProductAttachmentOrder = (builder: BuilderType) =>
  builder.mutation<void, { productId: number; id: number; order: number }>({
    query: ({ id, order }) => ({ url: '/issuer_product_attachment/' + id, method: 'PATCH', body: { order } }),
    onQueryStarted(args, { dispatch, queryFulfilled }) {
      queryFulfilled.then((resp) => {
        dispatch(
          api.util.updateQueryData('getIssuerProduct', args.productId, (product) => {
            const oldAttach = product.attachments.find((c) => c.id === args.id)
            if (oldAttach !== undefined) Object.assign(oldAttach, resp.data)
          }),
        )
      })
    },
  })
