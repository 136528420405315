import React from 'react'
import { Table } from 'antd'
import { api } from 'api/index'
import { Category, Firm, SellerProduct, Statistic } from 'typings/entities'
import { TEXT_COLORS } from 'export/colors'
import { prepareStatistic, statisticColumns } from 'pages/Analytic/Analytic'
import { ReloadOutlined } from '@ant-design/icons'
type Props = {
  userId: number
}

const SubscribeAlanytics: React.FC<Props> = (props) => {
  const analytic = api.useGetAnalyticUserQuery({
    userId: props.userId,
    params: {
      limit: 16,
      order_by: '-create_at',
    },
  })
  console.log('analytic.data', analytic.data)
  const dataSource = prepareStatistic(analytic.data?.statistic)

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h3 style={{ fontSize: 14, color: TEXT_COLORS.black, marginBottom: 2 }}>Client products watch log:</h3>
        <ReloadOutlined spin={analytic.isFetching} style={{ marginLeft: 4 }} onClick={analytic.refetch} />
      </div>
      {/*<p>user: {props.userId}</p>*/}
      <Table
        rowKey={'id'}
        dataSource={dataSource}
        size={'small'}
        //@ts-ignore
        columns={statisticColumns}
        loading={analytic.isFetching}
      />
    </div>
  )
}
export default SubscribeAlanytics
