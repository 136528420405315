import React, { useState } from 'react'
import { SubscribeStatus } from 'typings/entities'
import { Button, InputNumber, message } from 'antd'
import { api } from 'api'
import UITexts from 'export/UITexts'

type Props = {
  data: SubscribeStatus
}

const UpdateSubscribeStatusOrder: React.FC<Props> = (props) => {
  const [order, setOrder] = useState(props.data.order)
  const [mutate, resp] = api.useUpdateSubscribeStatusMutation()
  const update = async () => {
    const resp = await mutate({ id: props.data.id, data: { order } })
    console.log(resp)
    if ('data' in resp) {
      console.log(resp)
      message.success(UITexts.SubscribeStatus.UPDATE_SUCCESS)
    } else {
      message.error(UITexts.SubscribeStatus.UPDATE_ERROR)
    }
  }
  return (
    <div style={{ width: 70 + 50 + 4, display: 'flex' }}>
      <InputNumber value={order} onChange={setOrder} size={'small'} style={{ width: 70 }} />
      {order !== props.data.order && (
        <Button size={'small'} onClick={update} loading={resp.isLoading} style={{ marginLeft: 4 }}>
          OK
        </Button>
      )}
    </div>
  )
}

export default UpdateSubscribeStatusOrder
