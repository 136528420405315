import {
  api as _api,
  tagTypes as _tagTypes,
  TT as _TT,
  baseQuery as _baseQuery,
  contactsUploadCSV as _contactsUploadCSV,
  API_URL,
} from './api'
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'

export const api = _api
export const tagTypes = _tagTypes
export type TT = _TT
export const baseQuery = _baseQuery
export type BuilderType = EndpointBuilder<typeof baseQuery, TT, 'api'>
export const contactsUploadCSV = _contactsUploadCSV

export type AnalyticAggregationType =
  | 'user'
  | 'issuer_product'
  | 'product_category'
  | 'country'
  | 'state'
  | 'ip'
  | 'day'
export type AnalyticMetricType = 'views' | 'duration' | 'mean_duration'

export type AnalyticFilterType = {
  seller_firm: number
  dt_start: number
  dt_end: number

  activity_level?: [number, number]
  favourite_category?: number
  email?: string
  accredited_investor?: boolean
  qualified_purchaser?: boolean
  household_value?: [number, number]
  household_cash_value?: [number, number]
  billing_state?: string
  zip_code?: string
  //-----------------------analytic filters
  country?: string[]
  state?: string[]
  ip?: string[]

  user?: number[]
  issuer_product?: number[]
  product_category?: number[]
}

export type AnalyticRequest<Metrics = AnalyticMetricType, Aggs = AnalyticAggregationType> = {
  aggregations: Aggs[]
  metrics: Metrics[]
  order_by: (Metrics | Aggs)[]
  filters: AnalyticFilterType
}
export type PaginationResp = {
  limit: number
  page: number
  pages_count: number
}
export type AnalyticResponse<T extends string = AnalyticAggregationType | AnalyticMetricType> = {
  pagination: PaginationResp
  payload: {
    rows: {
      [key in T]: number | string
    }[]
    totals: {
      [key in T]: number | string
    }
  }
}

export type AnalyticPaginationType = { page: number; limit: number }

// const r: AnalyticResponse<'country' | 'state' | 'duration'> = [{ country: 'RU', state: 'RU.SVE', duration: 1 }]

export const getMapData = async (
  data: AnalyticRequest,
  pagination: AnalyticPaginationType,
): Promise<AnalyticResponse | null> => {
  const url = API_URL + `/analytic/analytic?page=${pagination.page}&limit=${pagination.limit}`
  console.log(data)
  console.log(JSON.stringify(data))
  console.log(url)
  const resp = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  })
    .then((r) => r.json())
    .catch((e) => {
      console.log(e)
      return null
    })
  console.log('Analytics resp:', resp)
  return resp
}
