import { DeleteOutlined, FolderOpenOutlined, RollbackOutlined } from '@ant-design/icons'
import { Button, Drawer, Input, InputRef, message, Popconfirm, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { api } from 'api'
import UITexts from 'export/UITexts'
import React, { Ref, useEffect, useRef, useState } from 'react'
import { useStore } from 'react-redux'
import { Link } from 'react-router-dom'
import { useSelector } from 'store'
import { SellerProduct, Subscribe, User } from 'typings/entities'
import { prettyDate } from 'utils'
import styles from './Archived.module.scss'

const Archived = ({ isAllClients }: { isAllClients?: boolean }) => {
  const user = useSelector((state) => state.profile.user)
  const [isVisiblePopup, setIsVisiblePopup] = useState(false)
  const subscribes = api.useGetSubscribesQuery({ is_archived: 1, advisor: isAllClients ? undefined : user?.id })
  const [mutate, resp] = api.useUpdateSubscribeMutation()
  const [data, setData] = useState<Subscribe[]>([])

  const archivedColumns: ColumnsType<Subscribe> = [
    {
      dataIndex: 'id',
      title: '#',
    },
    {
      dataIndex: 'product',
      title: 'Product',
      render: (v: SellerProduct) => (
        <Link to={'/seller_product/' + v.id}>
          {v.id} {v.name}
        </Link>
      ),
    },
    { dataIndex: 'user', title: 'User', render: (v: User) => v.username },
    { dataIndex: 'user_email', title: 'User mail', render: (v, row) => row.user.email },
    {
      dataIndex: 'unarchive',
      title: 'Unarchive',
      render: (v, row) => {
        return (
          <Popconfirm
            title={'Are you sure to unarchive this subscription?'}
            onConfirm={async () => {
              const values = {
                is_archived: !row.is_archived,
                is_subscribed: row.is_subscribed,
              }
              //@ts-ignore
              const resp = await mutate({ id: row.id!, data: values })
              console.log('resp', resp)
              subscribes.refetch()
              //@ts-ignore
              if ('error' in resp.data) {
                message.error(UITexts.Subscribe.UNARCHIVE_ERROR)
              } else {
                message.success(UITexts.Subscribe.UNARCHIVE_SUCCESS)
              }
            }}>
            <Button size={'small'} type={'primary'} icon={<RollbackOutlined />} />
          </Popconfirm>
        )
      },
    },
  ]

  useEffect(() => {
    if (subscribes.data && !subscribes.isUninitialized) setData(subscribes.data)
  }, [subscribes])

  const search = (toSearch: string, data?: Array<{ [key: string]: number | string }>) => {
    if (!data) return null
    const terms = toSearch.split(' ')
    return data
      .filter((object) =>
        terms.every((term) =>
          Object.entries(object).some(([key, value]) => {
            console.log('object', object)
            if (value) return value.toString().toLowerCase().includes(term.toLowerCase())
          }),
        ),
      )
      .map((el) => el.id)
  }

  const inputRef = useRef<InputRef>(null)

  return (
    <>
      <div className={styles.archivedContainer} onClick={() => setIsVisiblePopup(true)}>
        <p style={{ marginBottom: 0, marginRight: 20 }}>Archived</p>
        <FolderOpenOutlined className={styles.openFolder} />
      </div>
      <Drawer
        afterVisibleChange={(visible) => {
          if (visible) inputRef?.current?.focus()
        }}
        contentWrapperStyle={{ width: 800 }}
        title="Archive Subscriptions"
        placement="right"
        onClose={() => setIsVisiblePopup(false)}
        visible={isVisiblePopup}>
        <Input
          ref={inputRef}
          onChange={(e) => {
            const arrSerchId = search(
              e.target.value,
              subscribes.data?.map((e) => {
                return { id: e.id, product: e.product.id + e.product.name, email: e.user.email }
              }),
            )
            if (subscribes.data) setData(subscribes.data.filter((f) => arrSerchId?.includes(f.id)))
          }}
        />
        <Table dataSource={data} columns={archivedColumns} pagination={false} rowKey={'id'} />
      </Drawer>
    </>
  )
}

export default Archived
